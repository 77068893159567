import { type FC, ReactNode } from 'react'
import styles from './icon.module.scss'
import { LxIcon, LxIconProps } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

type Props = LxIconProps & {
  onClick: (a?: any) => void
  className?: string
  dropdown?: ReactNode
  ariaLabel?: string
  role?: string
}

export const LxClickAbleIcon: FC<Props> = ({ onClick, className, dropdown, ariaLabel = '', role = '', ...rest }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.clickable, className)}
      onKeyDown={(event) => LxHandleKeyDown(event, onClick)}
      tabIndex={0}
      aria-label={ariaLabel}
      role={role}
    >
      <LxIcon {...rest}></LxIcon>
      {dropdown}
    </div>
  )
}
