import { Controller, useForm } from 'react-hook-form'
import React, { FC, useEffect } from 'react'
import styles from './UserForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelect } from '@components/select/select.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import * as yup from 'yup'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'
import { useFormPersistence } from '@src/hooks/UseFormPersistence'


const getRoleChoices = (userRole: string) => {
  const roleChoices = [
    { value: 'account_admin', label: 'Account Admin' },
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'assistant', label: 'Assistant' },
  ]

  // Check currentUser's role and filter choices accordingly
  if (userRole == 'account_admin') {
    return roleChoices
  } else if (userRole == 'admin') {
    return roleChoices.filter(
      (choice) =>
        choice.value === 'admin' ||
        choice.value === 'user' ||
        choice.value === 'assistant'
    )
  } else if (userRole == 'user') {
    return roleChoices.filter((choice) => choice.value === 'assistant')
  } else {
    return []
  }
}


export type UserFormType = {
  id?: string
  firstName: string
  lastName: string
  jobTitle?: string
  userRole?: string
  primaryEmail: string
}
export const defaultUserFormValues: UserFormType = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  userRole: '',
  primaryEmail: '',
}

export const userFormValidation = yupResolver(yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  jobTitle: yup.string() ,
  userRole: yup.string().required('role is required'),
  primaryEmail: yup.string().email('Must be valid email').required('Email is required'),
}).required())

export const parseUserFormToBackend = (formData: UserFormType) => ( {
  first_name: formData.firstName,
  last_name: formData.lastName,
  job_title: formData.jobTitle,
  user_role: formData.userRole,
  primary_email: formData.primaryEmail
} )

type Props = {
  onSubmit: (formData: UserFormType) => void
  user?: UserF | null
  isEdit?: boolean
  onCancel: () => void
  isSubmitting: boolean
}

export const UserForm: FC<Props> = ({ onSubmit, user, onCancel, isEdit, isSubmitting=false }) => {
  const { currentUser } = useUserContext() as any
  const { control,  handleSubmit,watch, reset, formState: { errors, submitCount, isValid } } = useForm({
    defaultValues: user ? { ...user } : defaultUserFormValues,
    resolver: userFormValidation,
  })

  useFormNotify(errors, submitCount, isValid)

  useEffect(() => {
    // Reset the form when defaultValues change (for Edit scenario)
    if (user) {
      reset({ ...user })
    }
   }, [ user, reset ])

  const formValues = watch()

  useFormPersistence({
    formId: 'user',
    entityId: user?.id,
    defaultValues: user ? user : defaultUserFormValues,
    formValues,
    reset,
  })

  return (
    <form className={styles.userForm} onSubmit={handleSubmit(onSubmit)}>
      {isSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', 'inputHighLight',{ ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="First Name"
            />
          </div> )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton','inputHighLight', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Last Name"
            />
          </div> )}
      />
      <Controller
        name="jobTitle"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton','inputHighLight', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Job Title"
            />
          </div>
        )}
      />
      { isEdit
        ? null
        :
        <Controller
          name="primaryEmail"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className={cn(styles.inputField, 'lxActionButton','inputHighLight', { ['formFieldRequired']: !!error })}>
              <input
                className={'pristineInput'}
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Email"
              />
            </div>
          )}
        />
      }
      <Controller
        name="userRole"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LxSelect
            className={styles.inputSelect}
            value={value}
            hasError={!!error}
            placeholder={'Role'}
            onChange={onChange}
            options={getRoleChoices(currentUser.userRole)}
          />
        )}
      />

      <div className={styles.formActions}>
        <LxCancelButton onCancel={onCancel} classNames="lxActionButton, lxActionButtonDefaultSize" />
        <LxSaveButton onSubmit={handleSubmit(onSubmit)} label={isEdit ? 'Save Changes' : 'Create Team Member'} classNames="lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize" />
      </div>
    </form>
  )
}
