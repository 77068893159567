import { Controller, useForm } from 'react-hook-form'
import React, { FC } from 'react'
import styles from './CombinedForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelect } from '@components/select/select.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import * as yup from 'yup'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import { LxSelectInput } from '@src/components/select/selectInput'
import { timeZoneOptions } from '@src/utils/timeZoneOptions'
import { countriesList } from '@src/utils/countryList'

// Updated AccountFormType with specified field names
export type AccountFormType = {
  type: string
  accountName: string
  contactEmail: string
  resourceGroup: string
  timeZone: any
  contactName: string
  contactFamilyName: string
  address1: string
  address2: string
  address3: string
  postCode: string
  state: string
  city: string
  country: any
  contactPhone: string
}

// Default values for the form
export const defaultAccountFormValues = (): AccountFormType => ({
  type: '',
  accountName: '',
  contactEmail: '',
  resourceGroup: '',
  timeZone: '',
  contactName: '',
  contactFamilyName: '',
  address1: '',
  address2: '',
  address3: '',
  postCode: '',
  state: '',
  city: '',
  country: '',
  contactPhone: '',
})

// Validation schema for the form
export const AccountFormValidation = yupResolver(
  yup
    .object()
    .shape({
      type: yup.string().trim().required('Account Type is required'),
      accountName: yup.string().trim().required('Account Name is required'),
      contactEmail: yup.string().trim().email('Invalid email').required('Email is required'),
      timeZone: yup
        .object()
        .shape({
          name: yup.string().trim().required('Time zone name is required'), // Validate the 'name' inside the timeZone object
          label: yup.string(),
          code: yup.string(),
        })
        .required('Time zone is required'),
      contactName: yup.string().trim().required('Contact name is required'),
      contactFamilyName: yup.string().trim().required('Contact family name is required'),
      address1: yup.string().trim().required('Address is required'),
      address2: yup.string().trim(),
      address3: yup.string().trim(),
      postCode: yup.string().trim().required('Post code is required'),
      state: yup.string().trim().required('State is required'),
      city: yup.string().trim().required('City is required'),
      country: yup
        .object()
        .shape({
          name: yup.string().trim().required('Country name is required'), // Validate the 'name' field inside the country object
          label: yup.string(),
          code: yup.string(),
        })
        .required('Country is required'),
      contactPhone: yup.string().trim().required('Contact phone is required'),
    })
    .required()
)

// Function to parse form data to backend format
export const parseAccountFormToBackend = (formData: AccountFormType) => ({
  type: formData.type,
  name: formData.accountName,
  contact_email: formData.contactEmail,
  time_zone: formData.timeZone?.name,
  contact_name: formData.contactName,
  contact_family_name: formData.contactFamilyName,
  address_1: formData.address1,
  address_2: formData.address2,
  address_3: formData.address3,
  post_code: formData.postCode,
  state: formData.state,
  city: formData.city,
  country: formData.country?.name,
  contact_phone: formData.contactPhone,
})

// Component props
type Props = {
  onSubmit: (formData: AccountFormType) => void
  onCancel: () => void
  account?: AccountF | null
}

export const AccountForm: FC<Props> = ({ onSubmit, onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: defaultAccountFormValues(),
    resolver: AccountFormValidation,
  })

  useFormNotify(errors, submitCount, isValid)

  return (
    <form className={styles.accountForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='type'
        control={control}
        render={({ field, fieldState }) => (
          <LxSelect
            value={field.value}
            hasError={!!fieldState.error}
            shrinked
            placeholder={'Select Account Type'}
            onChange={field.onChange}
            options={[
              { value: 'home', label: 'Home' },
              { value: 'school', label: 'School' },
            ]}
          />
        )}
      />

      <Controller
        name='address1'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='Address Line 1' />
          </div>
        )}
      />

      <Controller
        name='accountName'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} value={value} onChange={(e) => onChange(e.target.value)} placeholder='Account Name' />
          </div>
        )}
      />

      <Controller
        name='address2'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='Address Line 2' />
          </div>
        )}
      />

      <Controller
        name='timeZone'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LxSelectInput
            dropdownClassName={styles.customDropDown}
            className={styles.timezoneSelect}
            value={value}
            hasError={!!error}
            onChange={onChange}
            options={timeZoneOptions}
            placeholder={'Select Time zone'}
            onReset={null}
          />
        )}
      />

      <Controller
        name='address3'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='Address Line 3' />
          </div>
        )}
      />

      <Controller
        name='contactEmail'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type='email'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Primary Contact Email'
            />
          </div>
        )}
      />

      <Controller
        name='postCode'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='Post Code' />
          </div>
        )}
      />

      <Controller
        name='contactName'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type='text'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Primary Contact Name'
            />
          </div>
        )}
      />

      <Controller
        name='city'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='City' />
          </div>
        )}
      />

      <Controller
        name='contactFamilyName'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type='text'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Primary Contact Family Name'
            />
          </div>
        )}
      />

      <Controller
        name='state'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input className={'pristineInput'} type='text' value={value} onChange={(e) => onChange(e.target.value)} placeholder='State' />
          </div>
        )}
      />

      <Controller
        name='contactPhone'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type='text'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Primary Contact Phone Number'
            />
          </div>
        )}
      />

      <Controller
        name='country'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LxSelectInput
            dropdownClassName={styles.customDropDown}
            className={styles.timezoneSelect}
            value={value}
            hasError={!!error}
            onChange={onChange}
            options={countriesList}
            placeholder={'Select Country'}
            onReset={null}
          />
        )}
      />

      <div className={styles.formActions}>
        <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onCancel}>
          <span>Cancel</span>
        </div>
        <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')} onClick={handleSubmit(onSubmit)}>
          <span>Create Account</span>
        </div>
      </div>
    </form>
  )
}
