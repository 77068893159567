import React, {useState, useEffect, useRef, useCallback} from "react"
import {SearchIcon, PlusIcon, MinusIcon,SettingsIcon} from "@icons/utils"

import styles from "../UserLearners.module.scss"
import sharedStyles from "../../../../MultiPage.module.scss"
import {LxIcon} from "@components/icon/Icon.tsx"
import {cn} from "@src/utils/cn.ts"
import {LxLoadingSpinner} from "@components/loader/loadingSpinner.tsx"
import {LxClickAbleIcon} from "@components/icon/clickAbleIcon.tsx"
import {AddNewUserLearner} from "@src/pages/User/Show/Learners/AssistantViews/AssistantAddNewLearner.tsx"
import {ConfirmationModal} from "@src/utils/confirmationModel"
import {isNilOrEmpty} from "@src/utils/isNilOrEmpty.ts"
import {mapRoleToReadableRole} from "@src/utils/mapRoleToReadableRole.ts"
import {EditAccess} from "../EditAccess"

import {useDataProvider, useNotify, useRefresh} from "react-admin"
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { LxHandleKeyDown } from "@src/utils/Accessbility"

export const AssistantView = ({
  userAssignedLearners,
  userFullName,
  userId,
  userRole,
  userAssigneeUsers,
}) => {
  
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const readableUserRole = mapRoleToReadableRole(userRole)

  const [learnerData, setLearnerData] = useState<Learner[]>([])
  const [isLearnerDataLoading, setIsLearnerDataLoading] = useState(true)
  const [assignedLearnerData, setAssignedLearnerData] = useState([])
  const [filteredAssignedLearnerData, setFilteredAssignedLearnerData] =
    useState()
  
  const [selectedLearner, setSelectedLearner] = useState("")
  const [selectedSupervisorId, setSelectedSupervisorId] = useState("")
  const [openAccessModal, setOpenAccessModal] = useState(false)
  const { showModal, hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const [unassignParams, setUnassignParams] = useState({
    learnerId: null,
    superVisorId: null,
  })
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const [userData, setUserData] = useState<User[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)

  const fullName = (learner) => {
    return learner.first_name && learner.last_name
      ? `${learner.first_name} ${learner.last_name}`
      : learner.first_name
      ? learner.first_name
      : learner.last_name
      ? learner.last_name
      : learner.nick_name
      ? learner.nick_name
      : learner.id_code
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("User", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {"state": "active"},
        })

        const userDataSubset: User[] = []

        response.data.forEach((user: any) => {
          const userDataItem: User = {
            id: user.id,
            fullName: fullName(user),
            userRole: mapRoleToReadableRole(user.user_role),
          }

          userDataSubset.push(userDataItem)
        })

        setUserData(userDataSubset)
        setIsUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsUserDataLoading(false)
      }
    }
    fetchData()
  }, [dataProvider])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("Learner", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {"state": "active"},
        })

        const learnerDataSubSet: Learner[] = []

        response.data.forEach((learner: any) => {
          const learnerDataItem: Learner = {
            id: learner.id,
            fullName: fullName(learner),
          }
          learnerDataSubSet.push(learnerDataItem)
        })

        setLearnerData(learnerDataSubSet)
        setIsLearnerDataLoading(false)

        const assignedLearnerData = []

        Object.keys(userAssigneeUsers).forEach((superVisorId) => {
          const superVisorName = userData.find(
            (user) => user.id === superVisorId
          )?.fullName
          const learners = userAssigneeUsers[superVisorId].learners
          // Loop through learners of the current supervisor
          Object.keys(learners).forEach((learnerId) => {
            const learnerName = learnerDataSubSet.find(
              (learner) => learner.id === learnerId
            )?.fullName

            const learnerAccess = {}
            
            learners[learnerId].forEach(access => {
              learnerAccess[access] = true;
            });
          
            const access = learnerAccess

            // Construct the learner object
            const learnerObj = {
              id: learnerId,
              fullName: learnerName || "",
              access,
              superVisorId,
              superVisorName: superVisorName,
            }

            assignedLearnerData.push(learnerObj)
          })
        })

        setAssignedLearnerData(assignedLearnerData)
        setFilteredAssignedLearnerData(assignedLearnerData)
      } catch (error) {
        // Handle error
        setIsLearnerDataLoading(false)
      }
    }

    if (userData) {
      fetchData()
    }
  }, [userData, userAssigneeUsers])

  const handleSearchInputChange = (inputValue) => {
    const filteredLearners = assignedLearnerData.filter((learner) =>
      learner.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredAssignedLearnerData(filteredLearners)
  }

  const handleUnAssign = async (learnerId: string, superVisorId: string) => {
    setIsConfirmationOpen(true)
    setUnassignParams({learnerId, superVisorId})
  }

  const handleConfirmUnassign = async () => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      const { learnerId, superVisorId } = unassignParams
      await dataProvider
        .update("User", {
          id: userId,
          data: {
            assign_learner: {
              action: "remove_learner",
              learner_id:  learnerId,
              supervisor_id: superVisorId
            },
          },
          previousData: null,
        })
        notify('Learner is removed successfully', { type: 'success' })
    } catch (error) {
      const errorMessage =   error.message ||  "Something went wrong, Please try again after sometime"
        notify(errorMessage, {type: "error"})
    } finally {
      refresh()
      setIsFormSubmitting(false)
      setIsConfirmationOpen(false)
    }
  }

  const handleAccessEdit = async (learner, superVisorId) => {
    setSelectedLearner(learner)
    setSelectedSupervisorId(superVisorId)
    setOpenAccessModal(true)
  }

   // getting unassigned learnes
   const assignedLearnerIds = new Set(assignedLearnerData.map(learner => learner.id));
   const unAssignedLearnerData  = learnerData.filter(learner => !assignedLearnerIds.has(learner.id));


   const modalRef = useRef<HTMLDivElement>(null)

   const renderModal = useCallback(() => {
    showModal(<AddNewUserLearner
      onClose={hideModal}
      learners={unAssignedLearnerData}
      userId={userId}
      userFullName={userFullName}
      userRole={readableUserRole}
      allUsers={userData}
    ></AddNewUserLearner>)
 
     setTimeout(() => {
       modalRef.current?.focus()
     }, 0) // Ensures model is focused after it is rendered
   }, [showModal, unAssignedLearnerData, userId,userData  ])


  return (
    <>
      <div className={styles.userLearnersNavigation}>
        {isLearnerDataLoading || isUserDataLoading ? (
          <LxLoadingSpinner />
        ) : (
          <>
            <div className={cn(styles.userLearnerInfo)}>
              <h3>Learners Assigned to {userFullName} </h3>
            </div>
            <div className={cn(sharedStyles.tableActions)}>
              <div
                className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize')}
                onClick={renderModal}
                tabIndex={0}
                onKeyDown={(event) => LxHandleKeyDown(event, renderModal)}
                role='button'
                aria-label='Assign New Learner'
              >
                <LxIcon icon={PlusIcon}/>
                Assign New Learner
              </div>
              <div className={'lxActionButton lxActionButtonDefaultSize'}>
                <LxIcon icon={SearchIcon}/>
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={(e) => {
                    handleSearchInputChange(e.target.value)
                  }}
                  placeholder="Search"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.userLearnersList}>
        <div className={cn(sharedStyles.tableListHeader)}> Learner Name</div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}
        >
          Supervisor
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}
        >
          Access Control
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}
        >
          Unassign
        </div>
        <React.Fragment>
          {isLearnerDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader}/>
          ) : isNilOrEmpty(filteredAssignedLearnerData) ? (
            <div className={sharedStyles.notFound}>
              No learners match your current search or filters.
            </div>
          ) : (
            filteredAssignedLearnerData.map((learner: LearnerF) => (
              <React.Fragment
                key={`User-row-${learner.id}-${learner.superVisorId}`}
              >
                <div>{learner.fullName}</div>
                <div className={sharedStyles.hide2column}>
                  {learner.superVisorName}
                </div>
                <div>
                  <LxClickAbleIcon
                    className={sharedStyles.hide4column}
                    onClick={() => {
                      handleAccessEdit(learner,learner.superVisorId)
                    }}
                    icon={SettingsIcon}
                    role="button"
                    ariaLabel={`Edit Access Control for ${learner.fullName}`}
                  />
                </div>
                <LxClickAbleIcon
                  className={sharedStyles.hide4column}
                  onClick={() => {
                    handleUnAssign(learner.id,learner.superVisorId)
                  }}
                  icon={MinusIcon}
                  role="button"
                  ariaLabel="Un-assign Learner"
                />
              </React.Fragment>
            ))
          )}
        </React.Fragment>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmUnassign}
        message="Are you sure you want to unassign this learner?"
        isFormSubmitting={isFormSubmitting}
      />

      <EditAccess
        isModalOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        learner={selectedLearner}
        userRole={readableUserRole}
        learnerFullName={selectedLearner?.fullName}
        userFullName={userFullName}
        userId={userId}
        superVisorId={selectedSupervisorId}
        isFormSubmitting={isFormSubmitting}
        setIsFormSubmitting={setIsFormSubmitting}
      />


    </>
  )
}
