import React from 'react'
import { Dialog, List, ListItem, ListItemText } from '@mui/material'
import styles from './RobotList.module.scss'
import modalStyles from '@components/modal/modal.module.scss'
import { getVoiceLabel } from '@src/utils/voiceMapper'
import { cn } from '@src/utils/cn'

interface VoiceInfoProps {
  open: boolean
  onClose: () => void
  voiceInfo: {
    default: string
    installed: string[]
    id: string
    serial: string
  } | null
}

const RobotVoiceInfo: React.FC<VoiceInfoProps> = ({ open, onClose, voiceInfo }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth role='dialog'>
      <div className={modalStyles.dialogHeader}>
        <h3>Voice Information - {voiceInfo?.serial} </h3>
      </div>

      <div className={modalStyles.dialogContent}>
        {voiceInfo ? (
          <>
            <div className={styles.infoRow}>
              <strong>Default Voice:</strong>
              <span>{getVoiceLabel(voiceInfo.default)}</span>
            </div>

            <div className={styles.infoRow}>
              <strong>Installed Voices:</strong>
              <List>
                {voiceInfo.installed.map((voice, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={getVoiceLabel(voice)} />
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        ) : (
          <p>No voice information available.</p>
        )}
      </div>

      <div className={cn(modalStyles.dialogActions, modalStyles.actionsFullWidth, styles.btnCentre)}>
        <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={() => onClose()}>
          <span>Close</span>
        </div>
      </div>
    </Dialog>
  )
}

export default RobotVoiceInfo
