import React, { useEffect, useRef } from 'react'
import styles from '../MultiPage.module.scss'
import { Link } from 'react-router-dom'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { UserF } from '@src/logic/contexts/Users/UsersFrontend.type'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LxTooltip } from '@src/components/tooltip/tooltip'
import { USER_STATE } from '@src/utils/constants'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'

interface UserInfoProps {
  user: UserF
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const { hideModal } = useModal()

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const canEditUser = canPerformAction(currentUser, { type: 'User' }, 'edit')

  const modalRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <>
      <div className={modalStyles.dialogHeader} ref={modalRef} tabIndex={-1} aria-label="User Info" role='dialog'> 
        <h3>{user.fullName}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <div className={styles.infoRow}>
          <strong>First Name:</strong>
          <span>{user.firstName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Last Name:</strong>
          <span>{user.lastName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Email:</strong>
          <span>{user.primaryEmail}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Title:</strong>
          <span>{user.jobTitle}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Role:</strong>
          <span>{mapRoleToReadableRole(user.userRole)}</span>
        </div>
       
      </div>
      <div className={cn(modalStyles.dialogActions, modalStyles.actionsFullWidth)}>
        <LxCancelButton onCancel={hideModal}  label='Close' classNames='lxActionButton,lxActionButtonDefaultSize'/>

      
        {canEditUser &&
          (user.state !== USER_STATE.ACTIVE ? (
            <LxTooltip tooltipText={'User is not active and cannot be edited'}>
              <span><LxSaveButton onSubmit={hideModal} label='Edit User Info' classNames="lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize" /></span>
            </LxTooltip>
          ) : (
            <Link to={`/User/${user.id}`} onClick={() => hideModal()} className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
               <span><LxSaveButton onSubmit={hideModal} label='Edit User Info' classNames="lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize" /></span>
            </Link>
          ))}
      </div>
    </>
  )
}

export default UserInfo
