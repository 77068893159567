// GoalAssignmentDialog.js
import React, { FC, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import modalStyles from '@components/modal/modal.module.scss'
import {UserData} from '@src/pages/Learner/Show/TeamMembers/LearnerMembers.type.ts'
import {FilterObject} from '@logic/useFiltering.hook.ts'
import styles from './LearnerAddNewTeamMember.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'


type Props = {
  open: boolean
  handleClose: () => void
  userChoice: string
  handleUserChoiceChange: (event: any) => void
  selectedUser: string
  handleSelectedUserChange: (event: any) => void
  userData: any
  isUserDataLoading: boolean
  handleUserChoiceSubmit: () => void
  isFormSubmitting: boolean
}

export const GoalReAssign: FC<Props> = ({
  open,
  handleClose,
  userChoice,
  handleUserChoiceChange,
  handleSelectedUserChange,
  userData,
  isUserDataLoading,
  handleUserChoiceSubmit,
  isFormSubmitting
}) => {
  const handleBackdropClick = (
    _: React.MouseEvent<HTMLElement>,
    reason: string
  ) => {
    if (reason !== 'backdropClick') {
      handleCloseDialog()
    }
  }

  const allUsersWithoutAssistants: FilterObject<UserData>[] = userData.filter(
    (user) => user.userRole !== 'Assistant'
  )

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <Dialog open={open} onClose={handleBackdropClick} tabIndex={-1} ref={modalRef} role='dialog'>
      <div className={modalStyles.dialogHeader}>
        <h3>Action Required!</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div>
        )}

        <p>
          Before you unassign this user, 
          please review the educational goals they are currently responsible for. 
          You can either reassign these goals to a different user now or choose to leave them unassigned and assign them later. 
        </p>
        <br></br>
        <p>Select an option: </p>
        <FormControl component='fieldset' fullWidth>
          <RadioGroup
            aria-label='userChoice'
            name='userChoice'
            value={userChoice}
            onChange={handleUserChoiceChange}
          >
            <FormControlLabel
              value='assign'
              control={<Radio />}
              label='Reassign to Another User'
            />
            <FormControlLabel
              value='unassign'
              control={<Radio />}
              label='Leave Unassigned (I will reassign goals later)'
            />
          </RadioGroup>
        </FormControl>
        {userChoice === 'assign' ? (
          <FormControl fullWidth required>
            <InputLabel id='selectedUserLabel'>Assign to user</InputLabel>
            <Select
              labelId='selectedUserLabel'
              id='selectedUser'
              onChange={handleSelectedUserChange}
            >
              {isUserDataLoading ? (
                <MenuItem value='' disabled>
                  Loading users...
                </MenuItem>
              ) : (
                allUsersWithoutAssistants.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.fullName}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        ) : null}
      </div>
      <DialogActions>
        <div className={styles.modalFooter}>
          <LxCancelButton label="Cancel" classNames="lxActionButton,lxActionButtonDefaultSize" onCancel={handleClose} />
          <LxSaveButton label="Confirm" classNames="lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize"onSubmit={handleUserChoiceSubmit} />
        </div>
      </DialogActions>
    </Dialog>
  )
}
