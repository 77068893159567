// This modal used to show only text

import { Dialog } from '@mui/material'
import { useState } from 'react'

import styles from '@components/modal/modal.module.scss'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import LxCancelButton from '../cancelButton/LxCancelButton'

// TODO upgrade inline styles
export const InfoModal = ({ icon, header, message, ariaLabel='' }) => {
  const [showDialog, setShowDialog] = useState(false)

  const clear_all = () => {
    setShowDialog(false)
  }

  return (
    <>
      <LxClickAbleIcon
        icon={icon}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e?.stopPropagation()
          setShowDialog(true)
        }}
        role='button'
        ariaLabel={ariaLabel}
      />

      <Dialog open={showDialog} onClose={clear_all} role='dialog'>
        <div className={styles.dialogHeader}>
          <h3>{header}</h3>
        </div>

        <div className={styles.dialogContent}>
          <span style={{ fontSize: '16px'  }}>{message}</span>
        </div>

        <div className={styles.singleAction}>
          <LxCancelButton label='Close' onCancel={clear_all} classNames='lxActionButton,lxActionButtonDefaultSize,singleAction' />
        </div>
      </Dialog>
    </>
  )
}
