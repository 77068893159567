import React, { FC, useState } from 'react'
import styles from '@src/pages/Learner/Show/LearnerShow.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { LearnerGoals } from '@src/pages/Learner/Show/Goals/Goals.tsx'
import LearnerActivities from '@src/pages/Learner/Show/Activities/Activities.tsx'
import ProgressReport from '@src/pages/Learner/Show/Report/ProgressReport.tsx'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { cn } from '@src/utils/cn.ts'

import { GoalIcon, ActivitiesIcon, AnalyticsIcon } from '@icons/index.ts'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

interface TabProps {
  label: React.ReactNode
  content: React.ReactNode
  trigger: 'goal' | 'activity' | 'reports'
}

const tabs: TabProps[] = [
  { label: <div className={styles.tabLabel}> <LxIcon icon={GoalIcon} /> <span>Goals</span></div>, content: <LearnerGoals />, trigger: 'goal' },
  { label: <div className={styles.tabLabel}> <LxIcon icon={ActivitiesIcon} /> <span>Activities</span></div>, content: <LearnerActivities />, trigger: 'activity' },
  // { label: 'Schedule', content: <Schedule />},
  { label: <div className={styles.tabLabel}> <LxIcon icon={AnalyticsIcon} /><span>Progress Report</span></div>, content: <ProgressReport />, trigger: 'reports' }
]

export const LearnerShowTabs: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // Split the path into segments
  const segments = location.pathname.split('/');

  // Determine the initial active tab based on the URL
  let initialActiveTab = 0;

  if (segments.includes('activity')) {
    initialActiveTab = 1;
  } else if (segments.includes('reports')) {
    initialActiveTab = 2;
  }

  const [activeTab, setActiveTab] = useState(initialActiveTab);


  const handleTabClick = (index: number, trigger: string) => {
    setActiveTab(index)
    // Determine the base path up to and including "/show"
    const segments = location.pathname.split('/')
    const showIndex = segments.indexOf('show')
    const basePath = segments.slice(0, showIndex + 1).join('/') // Take all segments up to and including 'show'

    // Append the trigger if it's not empty
    const newPath = trigger ? `${basePath}/${trigger}` : basePath
    navigate(newPath)
  }

  return (
    <div className={styles.tabMenu}>
      <div className={cn(styles.tabsStickyHook, 'ignoreOnPrint')}>
        <div className={styles.tabs}  role='menubar'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={cn(styles.tab, activeTab === index ? styles.active : '')}
              onClick={() => { handleTabClick(index, tab.trigger) }}
              tabIndex={0}
              onKeyDown={(event) => LxHandleKeyDown(event, () => { handleTabClick(index, tab.trigger) })}
             
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tabContent}>
        <Routes>
          {tabs.map((tab, index) => (
            <React.Fragment key={`route-learner-show-${index}`}>
              <Route path={tab.trigger} element={tab.content} />
            </React.Fragment>
          ))}
        </Routes>
      </div>
    </div>
  )
}