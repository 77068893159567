import React, { useState } from 'react'

import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import styles from './ActivitiesList.module.scss'
import { ActivitiesSkillArea } from '@src/pages/Activities/components/ActivitiesSkillArea.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { ActivitiesFilterBar } from '@src/pages/Activities/ActivitiesFilterBar.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

export const ActivitiesList = () => {
  useDocumentTitle('Activities')
  const { displayData, isLoading } = useActivitiesList()
  const { isLoading: isLearnerListLoading } = useLearnersList()
  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const canAddGoal = canPerformAction(currentUser, { type: 'Learner' }, 'add_goal')

  const [expandAll, setExpandAll] = useState(false)

  const filteredSkillAreas = !isNilOrEmpty(displayData) ? displayData?.skillAreas.filter((skillArea) => skillArea.type !== 'curriculum') : []

  const hasDisplayDataAtLeastOneGoal = () => {
    if (isNilOrEmpty(filteredSkillAreas)) {
      return false
    }
    return filteredSkillAreas?.some((category) => category.goals.length > 0)
  }

  return (
    <div className={styles.activitiesContainer}>
      <ActivitiesFilterBar handleExpandAll={() => setExpandAll((prevState) => !prevState)} expandAll={expandAll}></ActivitiesFilterBar>
      {isLoading || (canAddGoal && isLearnerListLoading) ? (
        <div className={styles.placeholderContainer}>
          <LxLoadingSpinner />
        </div>
      ) : (
        <>
          {hasDisplayDataAtLeastOneGoal() ? (
            filteredSkillAreas?.map((skillArea, index) => (
              <React.Fragment key={`${displayData?.name}-${index}`}>
                <ActivitiesSkillArea skillArea={skillArea} expandAll={expandAll}></ActivitiesSkillArea>
              </React.Fragment>
            ))
          ) : (
            <div className={styles.placeholderContainer}>
              {' '}
              <h5>No data found please try change filters</h5>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ActivitiesList
