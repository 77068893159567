import { TeamMembersIcon, StudentsOutlineIcon, UserInfoIcon } from '@icons/index.ts'

import { SearchIcon, PlusIcon, EditIcon, InfoOutlineIcon } from '@icons/utils'

import React, { useState, useEffect, useRef } from 'react'
import { useUsersList } from '@logic/contexts/Users/UsersListsContext.tsx'
import styles from './UserList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { Link } from 'react-router-dom'
import { UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import UserInfo from './UserInfo'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import UserEditStatus from './UserEditStatus'
import { LxAutocompleteTmp } from '@components/autocompleteTmp/autocompleteTmp.tsx'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { USER_STATE } from '@src/utils/constants'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

const getTooltipText = (state: string) => {
  switch (state) {
    case USER_STATE.INVITED:
      return 'The user is invited. Awaiting email delivery status.'
    case USER_STATE.INVITE_DELIVERED:
      return 'The invitation has been sent. The user status will change to Active once the user signs in.'
    case USER_STATE.INVITE_FAILED:
      return 'The invitation email failed to deliver. You can retry sending the email or delete the user.'
    default:
      return ''
  }
}

export const UserList = () => {
  useDocumentTitle('Team Members')
  const { showModal } = useModal()
  const { userList, isLoading, filterValues, dispatch } = useUsersList()
  const [filteredData, setFilteredData] = useState(userList)

  const { Q: inputValue, state: stateFilter } = filterValues
  // const setInputValue = newValue => dispatch({ type: 'UPDATE_FILTER', field: 'Q', value: newValue })
  const setStateFilter = (newValue) => dispatch({ type: 'UPDATE_FILTER', field: 'state', value: newValue })

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const handleSearchInputChange = (inputValue: string) => {
    const filteredLearners = userList?.filter((learner) => learner.fullName.toLowerCase().includes(inputValue.toLowerCase()))
    setFilteredData(filteredLearners)
  }

  useEffect(() => {
    // Ensure learnerList is loaded before applying search
    if (!isLoading) {
      setFilteredData(userList)
    }
  }, [isLoading, userList])

  const canCreateUser = canPerformAction(currentUser, { type: 'User' }, 'create')
  const canEditUser = canPerformAction(currentUser, { type: 'User' }, 'create')

  const is_not_assistant = currentUser.userRole == 'admin' || currentUser.userRole == 'account_admin' || currentUser.userRole == 'user'

  const TeamAssignIcon = (
    <LxIcon
      sxStyles={{
        height: '32px',
        width: '32px',
        marginBottom: '-12px',
      }}
      icon={StudentsOutlineIcon}
    />
  )

  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the page when navigated
    pageRef.current?.focus()
  }, [])

  return (
    <>
      <div className={styles.userTableNavigation} aria-label='Team Members List' tabIndex={-1} ref={pageRef} style={{ outline: 'none' }}>
        <div className={sharedStyles.tableInfo}>
          <div>
            <LxIcon sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }} icon={TeamMembersIcon}></LxIcon>
          </div>
          <div>
            <LxAutocompleteTmp
              value={stateFilter}
              onChange={setStateFilter}
              options={[
                {
                  value: USER_STATE.ACTIVE,
                  label: 'Active Team Members',
                },
                {
                  value: 'archived',
                  label: 'Archived Team Members',
                },
              ]}
              ariaLabel='Drop Down to select Active, Archived team members'
            />
            {/* {stateFilter == 'deleted' && <span>Deleted learner profiles can be restored within 90 days</span>} */}
          </div>
        </div>
        <div className={sharedStyles.tableActions}>
          {canCreateUser ? (
            <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
              <Link to={'create'}>
                <LxIcon icon={PlusIcon} />
                Add New Team Member
              </Link>
            </div>
          ) : null}
         <div className={cn('lxActionButton','lxActionButtonDefaultSize','inputHighLight')}  >
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder='Search'
              aria-label='Search Team members'
            />
          </div>
        </div>
      </div>
      <div className={styles.teamMembersListContent}>
        <div className={cn(sharedStyles.tableListHeader)}> User</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}> Title</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}> Role</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}> Info</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}> Learners</div>
        <div className={cn(sharedStyles.tableListHeader)}>{canEditUser ? 'Manage Status' : ''}</div>

        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader}></LxLoadingSpinner>
        ) : (
          <React.Fragment>
            {filteredData?.length ? (
              filteredData.map((user: UserF) => (
                <React.Fragment key={`User-row-${user.id}`}>
                  <div>
                    <span className={sharedStyles.textOverflow}>{user.fullName}</span>
                    {user.state !== USER_STATE.ACTIVE && (
                      <LxTooltip tooltipText={getTooltipText(user.state)}>
                        {user.state === USER_STATE.INVITE_FAILED ? (
                          <WarningAmberIcon className={styles.failedInviteRow} sx={{ height: '24px', width: '24px', marginLeft: 1 }} />
                        ) : [USER_STATE.INVITED, USER_STATE.INVITE_DELIVERED].includes(user.state) ? (
                          <LxIcon icon={InfoOutlineIcon} sxStyles={{ height: '24px', width: '24px', marginLeft: 1 }} />
                        ) : null}
                      </LxTooltip>
                    )}
                  </div>
                  <div className={sharedStyles.hide2column}>{user.jobTitle}</div>
                  <div className={sharedStyles.hide3column}>{mapRoleToReadableRole(user.userRole)}</div>
                  <div className={sharedStyles.hide4column}>
                    <LxClickAbleIcon
                      onClick={() => showModal(<UserInfo user={user} />)}
                      icon={UserInfoIcon}
                      ariaLabel={`Learner Info for ${user.fullName}`}
                      role='button'
                    ></LxClickAbleIcon>
                  </div>
                  <div className={sharedStyles.hide5column}>
                    {is_not_assistant ? (
                      <>
                        {user.state !== USER_STATE.ACTIVE ? (
                          <LxTooltip tooltipText={'User is not active and cannot add learners'}>{TeamAssignIcon}</LxTooltip>
                        ) : (
                          <Link to={`${user.id}/show/learners`} aria-label={`Learners of ${user.fullName}`} role='button'  tabIndex={0}>
                            {TeamAssignIcon}
                          </Link>
                        )}
                      </>
                    ) : (
                      <LxTooltip tooltipText={'Sorry, You dont have access'}>{TeamAssignIcon}</LxTooltip>
                    )}
                  </div>
                  <div className={sharedStyles.hide6column}>
                    {currentUser.id != user.id &&  canEditUser ? (
                      <LxClickAbleIcon
                        onClick={() =>
                          showModal(
                            <UserEditStatus
                              userId={user.id}
                              state={user.state}
                              userRole={user.userRole}
                              userName={user.fullName}
                              userList={userList!}
                            />
                          )
                        }
                        icon={EditIcon}
                        ariaLabel={`Manage status of ${user.fullName}`}
                        role='button'
                      />
                    ) : 
                      <LxTooltip tooltipText="User cannot edit their own status">
                        <LxIcon icon={EditIcon} />
                      </LxTooltip>
                    }
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className={sharedStyles.notFound}>No users match your current search or filters.</div>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  )
}

export default UserList
