import React, { FC, useEffect, useRef, useState } from 'react'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  usePrevious,
} from 'react-admin'
import modalStyles from '@components/modal/modal.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LxSelect } from '@components/select/select.tsx'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { ConfirmationModal } from '@src/utils/confirmationModel'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './Learner.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'

export const stateFormValidation = yupResolver(
  yup
    .object()
    .shape({
      state: yup.string().required('State is required'),
    })
    .required()
)

const LearnerEditStatus: FC<{ learner: LearnerF }> = ({ learner }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()
 
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: { state: learner.state },
    resolver: stateFormValidation,
  })

  const onClose = () => {
    setConfirmationDialogOpen(false)
    hideModal()
    reset()
  }

  const prevState = usePrevious(learner.state)

  useFormNotify(errors, submitCount, isValid)

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const onSubmit = async (formData: {
    state: 'active' | 'archived' | 'deleted'
  }) => {

    if (isFormSubmitting) {
      return
    }

    if (prevState === formData.state) {
      setError('state', {
        message: 'Please choose a state different from the current status.',
      })
      return
    }

    if (formData.state === 'deleted') {
      // Open confirmation dialog when "Delete" option is selected
      setConfirmationDialogOpen(true)
      return
    }

    try {
      setIsFormSubmitting(true)
      await dataProvider.update('Learner', {
        id: learner.id,
        data: formData,
        previousData: null,
      })
      notify('Learner status changed successfully', { type: 'success' })
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
    } finally {
      refresh()
      setIsFormSubmitting(false)
      onClose()
    }
   
  }

  // Function to handle learner deletion after confirmation
  const handleDeleteConfirmation = async () => {
    if (isFormSubmitting) {
      return
    }

    try {
      setIsFormSubmitting(true)
      await dataProvider.update('Learner', {
        id: learner.id,
        data: { state: 'deleted' },
        previousData: null,
      })
      notify('Learner deleted successfully', { type: 'success' })
    
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
    } finally {
      refresh()
      setIsFormSubmitting(false)
      onClose()
    }
  }

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])


  return (
    < >
      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <div className={modalStyles.dialogHeader} tabIndex={-1} ref={modalRef} aria-label={`Change status of ${learner.fullName}`} role='dialog'>
        <h3 role={`Change status of ${learner.fullName}`}>Change status of {learner.fullName}</h3>
      </div>
      <div className={modalStyles.dialogContent}  >
        <form onSubmit={handleSubmit(onSubmit)}  >
          <Controller
            name='state'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const filteredOptions = [
                ...(learner.state !== 'active'
                  ? [
                      {
                        value: 'active',
                        label:
                          learner.state !== 'deleted' ? 'Active' : 'Restore',
                      },
                    ]
                  : []),
                ...(learner.state !== 'archived'
                  ? [{ value: 'archived', label: 'Archive' }]
                  : []),
                ...(learner.state !== 'deleted'
                  ? [{ value: 'deleted', label: 'Delete' }]
                  : []),
              ]

              return (
                <LxSelect
                  value={value}
                  hasError={!!error}
                  placeholder={'Select Learner State'}
                  onChange={onChange}
                  options={filteredOptions}
                />
              )
            }}
          />
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <LxCancelButton label='Close' onCancel={onClose} classNames='lxActionButton,lxActionButtonDefaultSize' role='button' />
        <LxSaveButton label='Confirm' classNames='lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize' onSubmit={handleSubmit(onSubmit)} role='button'  />
      </div>

      <ConfirmationModal
        isOpen={confirmationDialogOpen}
        onClose={onClose}
        onConfirm={handleDeleteConfirmation}
        confirm='Delete Profile'
        message={`You are about to delete the learner profile of ${learner.fullName} along with all associated data. This profile can be restored within the next 30 days, after which it will be permanently deleted. \n \n Are you sure you wish to proceed? `}
        title="Confirm Deletion?"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  )
}

export default LearnerEditStatus
