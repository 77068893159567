import {
  LearnerForm,
  LearnerFormType,
  parseLearnerFormToBackend,
} from './LearnerForm.tsx'

import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { LxIcon } from '@components/icon/Icon.tsx'
import styles from './LearnerForm.module.scss'
import { StudentOutlineIcon } from '@icons/index.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle.ts'

export const LearnerCreate = () => {
  useDocumentTitle('Add New Learner')
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();

  const onSubmit = async (formData: LearnerFormType) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      await dataProvider.create('Learner', {
        data: parseLearnerFormToBackend(formData),
      })

      // // Handle success case
      notify('Learner created successfully', { type: 'success' })
      localStorage.removeItem('learnerCreateForm')
      backToLearners()
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false);
    }
  }

  const backToLearners = () => {
    localStorage.removeItem('learnerCreateForm')
    navigate('/Learner') // this will navigate to the previous page
  }


  return (
    <div>
      <div>
        <div class={styles.ProfileHeader}>
          <LxIcon
            icon={StudentOutlineIcon}
            sxStyles={{ height: '80px', width: '80px' }}
          ></LxIcon>
          <h3>Add New Learner</h3>
        </div>
      </div>
      <LearnerForm onSubmit={onSubmit} onCancel={backToLearners}  isSubmitting={isFormSubmitting}></LearnerForm>
    </div>
  )
}

export default LearnerCreate
