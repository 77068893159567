import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'

import styles from './LogList.module.scss'
import { ActivitiesOutlineIcon } from '@icons/index.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { LxTooltip } from '@src/components/tooltip/tooltip'
import { formatDate } from '@src/utils/dateFormatter'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'
import { LxIcon } from '@src/components/icon/Icon'
import { cn } from '@src/utils/cn'
import sharedStyles from '../../MultiPage.module.scss'
import { LxHandleKeyDown } from '@src/utils/Accessbility'
import { LxAutocomplete } from '@src/components/autocomplete/autocomplete'
import { FilterObject } from '@src/logic/useFiltering.hook'
import LogDetailsPopover from './LogDetailsPopOver'
import { ENTITY_ACTION, ENTITY_TYPE } from '@src/utils/constants'

export type LogPropsF = {
  id: string
  accountId: string
  rowKey: string
  userId: string
  userName: string
  CreatedAt: string
  action: string
  category: string
  categoryId: string
  details: string
  clientIp: string
  userAgent: string
}

export type LogPropsB = {
  id: string
  PartitionKey: string
  RowKey: string
  UserId: string
  UserName: string
  CreatedAt: string
  Action: string
  EntityType: string
  EntityId: string
  Details: string
  ClientIp: string
  UserAgent: string
  pagination?: {
    nextToken: string | null
    hasMore: boolean
  }
}

type UserFilter = {
  value: string
  label: string
  isEnabled: boolean
}

const mapServerLogListToClient = (data: LogPropsB[] | undefined): LogPropsF[] | null => {
  if (isNilOrEmpty(data)) {
    return null
  }

  return data.map((log: LogPropsB) => ({
    id: log.id,
    accountId: log.PartitionKey,
    rowKey: log.RowKey,
    userId: log.UserId,
    userName: log.UserName,
    CreatedAt: log.CreatedAt,
    action: log.Action,
    category: log.EntityType,
    categoryId: log.EntityId,
    details: log.Details,
    clientIp: log.ClientIp,
    userAgent: log.UserAgent,
  }))
}

export const AuditLog = () => {
  useDocumentTitle('Audit Logs')
  const [logData, setLogData] = useState<LogPropsB[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const navigate = useNavigate()
  const { accountId } = useParams()
  const [entityFilter, setEntityFilter] = useState<FilterObject<string>[]>([])
  const [actionFilter, setActionFilter] = useState<FilterObject<string>[]>([])
  const [userFilter, setUserFilter] = useState<FilterObject<string>[]>([])
  const [searchParams] = useSearchParams()
  const accountName = searchParams.get('name') ?? ''
  // export
  const [isExporting, setIsExporting] = useState(false)
  // Pagination state
  const [currentPage, setCurrentPage] = useState(0)
  const [pageTokens, setPageTokens] = useState<{ [key: number]: string }>({})
  const [hasNextPage, setHasNextPage] = useState(false)
  const perPage = 50

  // user drop down filter data preparation
  const [userData, setUserData] = useState<UserFilter[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)

  const fullName = (user) => {
    return `${user.first_name} ${user.last_name}`
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await dataProvider.getList('User', {
          pagination: { page: 0, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { state: 'active' },
        })

        const userDataSubset: UserFilter[] = []

        response.data.forEach((user: any) => {
          const userDataItem: UserFilter = {
            value: user.id,
            label: fullName(user),
            isEnabled: true,
          }

          userDataSubset.push(userDataItem)
        })

        setUserData(userDataSubset)
        setIsUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsUserDataLoading(false)
      }
    }
    fetchUserData()
  }, [dataProvider, accountId])

  // ahdnling filters
  const filter = useMemo(() => {
    const baseFilter = {
      account_id: accountId,
      entity_type: entityFilter.map((item) => item.value),
      action: actionFilter.map((item) => item.value),
      user_id: userFilter.map((item) => item.value),
    }

    // Add continuation token for the current page if it exists
    if (pageTokens[currentPage]) {
      return {
        ...baseFilter,
        continuation_token: pageTokens[currentPage],
      }
    }

    return baseFilter
  }, [accountId, currentPage, pageTokens, entityFilter, actionFilter, userFilter])

  const fetchLogs = async () => {
    setIsLoading(true)
    try {
      const { data } = await dataProvider.getList('AuditLog', {
        pagination: { page: 1, perPage },
        sort: { field: 'Timestamp', order: 'DESC' },
        filter: filter,
      })

      setLogData(data)

      if (data && data.length > 0 && data[0].pagination) {
        const paginationToken = data[0].pagination

        const hasMore = paginationToken !== null

        if (hasMore) {
          setPageTokens((prev) => ({
            ...prev,
            [currentPage + 1]: paginationToken,
          }))
          setHasNextPage(true)
        } else {
          setHasNextPage(false)
        }
      } else {
        setHasNextPage(false)
      }
    } catch (error) {
      notify(error.message || 'Something went wrong', { type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLogs()
  }, [accountId, currentPage, entityFilter, actionFilter, userFilter])

  const sanitizeLogList = useMemo(() => mapServerLogListToClient(logData), [logData])

  const UserAgentBlock = ({ userAgent }) => {
    return (
      <div className={styles.userAgentCell}>
        <LxTooltip tooltipText={userAgent}>{userAgent}</LxTooltip>
      </div>
    )
  }

  const handleGoBack = () => {
    navigate(-1) // Navigate to the previous page in history
  }

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prev) => prev + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  const resetFilters = () => {
    setActionFilter([])
    setEntityFilter([])
    setUserFilter([])
  }

  const extractFilenameFromUrl = (url) => {
    try {
      const urlObj = new URL(url)
      const pathname = urlObj.pathname
      return pathname.split('/').pop()
    } catch {
      return null
    }
  }

  // Export function
  const exportLogs = async () => {
    if (!accountId) return

    setIsExporting(true)
    try {
      const response = await dataProvider.update('AuditLog', {
        id: accountId,
        data: {
          method: 'exportLogs',
          filter: {},
        },
        previousData: null,
      })

      if (response && response.data?.url) {
        const fileResponse = await fetch(response.data.url)
        const blob = await fileResponse.blob()
        
        // Create a temporary link element
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        
        // Determine filename from response headers or generate one
        const filename = extractFilenameFromUrl(response.data.url) || 'exported-logs.csv'
        link.download = filename
        
        // Programmatically click the link to trigger download
        document.body.appendChild(link)
        link.click()
        
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      }  else {
        notify('Failed to export logs, Try again after sometime', { type: 'error' })
      }
    } catch (error) {
      const errorMessage = error.message || 'Failed to export logs'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsExporting(false)
    }
  }

  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the page when navigated
    pageRef.current?.focus()
  }, [accountId])

  return (
    <>
      <div className={styles.logListHeadingRow} ref={pageRef} tabIndex={-1} aria-label='log List' style={{ outline: 'none' }}>
        <div className={styles.logListHeading}>
          <LxIcon sxStyles={{ height: '80px', width: '80px' }} icon={ActivitiesOutlineIcon} />
          <h3>Audit Logs - {accountName}</h3>
        </div>
        <div className={styles.logActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={resetFilters}
            onKeyDown={(event) => LxHandleKeyDown(event, resetFilters)}
            tabIndex={0}
            role='button'
            aria-label='Reset Filters'
          >
            <span>Reset Filters</span>
          </div>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize', { disabledButton: isExporting })}
            onClick={exportLogs}
            onKeyDown={(event) => LxHandleKeyDown(event, exportLogs)}
            tabIndex={0}
            role='button'
            aria-label='Export All Logs'
          >
            <span>{isExporting ? 'Exporting...' : 'Export All Logs'}</span>
          </div>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize lxActionButtonFilled')}
            onClick={handleGoBack}
            onKeyDown={(event) => LxHandleKeyDown(event, handleGoBack)}
            tabIndex={0}
            role='button'
            aria-label='Back To Analytics'
          >
            <span>Back to Analytics</span>
          </div>
        </div>
      </div>

      <div className={styles.activitiesFilterBar}>
        <LxAutocomplete
          multiple
          placeholder={'Filter by Category'}
          options={[
            { value: ENTITY_TYPE.USER, label: ENTITY_TYPE.USER, isEnabled: true },
            { value: ENTITY_TYPE.LEARNER, label: ENTITY_TYPE.LEARNER, isEnabled: true },
            { value: ENTITY_TYPE.GOAL, label: ENTITY_TYPE.GOAL, isEnabled: true },
            { value: ENTITY_TYPE.ACCOUNT, label: ENTITY_TYPE.ACCOUNT, isEnabled: true },
          ]}
          value={entityFilter}
          onChange={setEntityFilter}
        ></LxAutocomplete>
        <LxAutocomplete
          multiple
          placeholder={'Filter by Action'}
          value={actionFilter}
          options={[
            { value: ENTITY_ACTION.LOGIN, label: ENTITY_ACTION.LOGIN, isEnabled: true },
            { value: ENTITY_ACTION.CREATE, label: ENTITY_ACTION.CREATE, isEnabled: true },
            { value: ENTITY_ACTION.UPDATE, label: ENTITY_ACTION.UPDATE, isEnabled: true },
            { value: ENTITY_ACTION.DELETE, label: ENTITY_ACTION.DELETE, isEnabled: true },
            { value: ENTITY_ACTION.ASSIGN, label: ENTITY_ACTION.ASSIGN, isEnabled: true },
            { value: ENTITY_ACTION.UNASSIGN, label: ENTITY_ACTION.UNASSIGN, isEnabled: true },
            { value: ENTITY_ACTION.INVITE, label: ENTITY_ACTION.INVITE, isEnabled: true },
            { value: ENTITY_ACTION.EXPORT, label: ENTITY_ACTION.EXPORT, isEnabled: true },
          ]}
          onChange={setActionFilter}
        ></LxAutocomplete>

        <LxAutocomplete multiple placeholder={'Filter by User'} value={userFilter} options={userData} onChange={setUserFilter}></LxAutocomplete>
      </div>

      <div className={styles.logsListContent}>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, styles.logName)}>Category</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable)}>Action</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, sharedStyles.hide2column)}>User</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, sharedStyles.hide3column)}>Date of Change</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, sharedStyles.hide4column)}>User IP</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, sharedStyles.hide5column)}>Details</div>
        <div className={cn(styles.logsTableListHeader, styles.notSortable, sharedStyles.hide6column)}>User Agent</div>

        {isLoading || isUserDataLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(sanitizeLogList) ? (
          <div className={sharedStyles.notFound}>No log match your current search.</div>
        ) : (
          sanitizeLogList &&
          sanitizeLogList.map((log: LogPropsF) => (
            <React.Fragment key={log.id}>
              <div className={styles.noCursor}>{log.category}</div>
              <div className={cn(styles.noCursor)}>{log.action}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide2column)}>{log.userName}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide3column)}>{formatDate(log.CreatedAt)}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide4column)}>{log.clientIp}</div>

              <div className={cn(sharedStyles.hide5column, styles.userAgentColumn)}>
                <LogDetailsPopover details={log.details} />
              </div>
              <div className={cn(sharedStyles.hide6column, styles.userAgentColumn)}>
                <UserAgentBlock userAgent={log.userAgent} />
              </div>
            </React.Fragment>
          ))
        )}
      </div>

      {!isLoading && sanitizeLogList && sanitizeLogList.length > 0 && (
        <div className={styles.paginationControls}>
          <button className={cn('lxActionButton  lxActionButtonFilled', { disabledButton: currentPage === 0 })} onClick={handlePreviousPage}>
            Previous
          </button>
          <span className={styles.pageIndicator}>Page {currentPage + 1}</span>
          <button className={cn('lxActionButton  lxActionButtonFilled', { disabledButton: !hasNextPage })} onClick={handleNextPage}>
            Next
          </button>
        </div>
      )}
    </>
  )
}

export default AuditLog
