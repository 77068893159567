import React, { useEffect, useRef } from 'react'
import styles from '../MultiPage.module.scss'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { Link } from 'react-router-dom'

import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'

import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { formatDate } from '@src/utils/dateFormatter'
 import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'
 
interface LearnerInfoProps {
  learner: LearnerF
}

const LearnerInfo: React.FC<LearnerInfoProps> = ({ learner }) => {
  const { hideModal } = useModal()

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const canEditLearner = canPerformAction(currentUser, { type: 'Learner' }, 'edit')

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <div role='dialog' aria-label="Learner Info"  tabIndex={-1} ref={modalRef}>
      <div className={modalStyles.dialogHeader}>
        <h3>{learner.fullName}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <div className={styles.infoRow}>
          <strong>First Name:</strong>
          <span>{learner.firstName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Last Name:</strong>
          <span>{learner.lastName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Date of Birth:</strong>
          <span>{formatDate(learner.birthday, false, false)}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Gender:</strong>
          <span>{learner.gender}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Grade:</strong>
          <span>{learner.grade}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Identification Code/Number:</strong>
          <span>{learner.idCode}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>IEP/EHCP Code:</strong>
          <span>{learner.fileCode}</span>
        </div>
      </div>
      <div className={modalStyles.dialogActions}>
        <LxCancelButton onCancel={hideModal}  label='Close' classNames='lxActionButton,lxActionButtonDefaultSize'/>

        {canEditLearner && (
          <Link
            to={`/Learner/${learner.id}/show/edit`}
            onClick={() => hideModal()}
            className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
            tabIndex={0}
          >
            <span><LxSaveButton onSubmit={hideModal} label='Edit Learner Info' /></span>
          </Link>
        )}
      </div>
    </div>
  )
}

export default LearnerInfo
