import { CircularProgress } from '@mui/material'

import styles from './LearnerActivityReport.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import { QuestionChart } from '@src/pages/Learner/Show/Activities/activityReport/QuestionChart.tsx'
import { TimeChart } from '@src/pages/Learner/Show/Activities/activityReport/TimeChart.tsx'
import { ScoreChart } from '@src/pages/Learner/Show/Activities/activityReport/ScoreChart.tsx'
import { StudentOutlineIcon } from '@icons/index.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { LxHandleKeyDown } from '@src/utils/Accessbility'
import ActivitiesShowModal from '@src/pages/GroupCurriculums/ActivitiesShowModal'
import { useState } from 'react'

export const LearnerActivityReport = () => {
  const navigate = useNavigate()
  const { allUnits } = useActivitiesList()
  const { id: learnerId, activityId } = useParams()
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)

  const unitName = allUnits ? allUnits.find((unit) => unit.unitId === activityId)?.unitName : activityId

  const handleGoBack = () => {
    const targetURL = `/Learner/${learnerId}/show/activity`
    navigate(targetURL) // this will navigate to the previous page
  }

 
  return (
    <>
      <div className={styles.ProfileHeader}>
        <div className={styles.Title}>
          <LxIcon icon={StudentOutlineIcon} sxStyles={{ height: '80px', width: '80px' }}></LxIcon>
          <span className={styles.boldStudentName}> Activity Report </span>
        </div>
        <div className={styles.navigationRow}>
          <div className={styles.navButtons}>
            <div
              className={'lxActionButton'}
              onClick={() => setIsActivityModalOpen(true)}
              tabIndex={0}
              onKeyDown={(event) => LxHandleKeyDown(event, () => setIsActivityModalOpen(true))}
            >
              Read more about this activity
            </div>
            <div
              className={'lxActionButton lxActionButtonFilled'}
              onClick={handleGoBack}
              tabIndex={0}
              onKeyDown={(event) => LxHandleKeyDown(event, handleGoBack)}
            >
              {' '}
              Back to learner
            </div>
          </div>
        </div>
      </div>

      <h4> {unitName ? unitName : <CircularProgress />} </h4>
      <div className={styles.activityReportContainer}>
        <ScoreChart></ScoreChart>
        <QuestionChart unitName={unitName}></QuestionChart>
        <TimeChart unitName={unitName}></TimeChart>
      </div>

      <ActivitiesShowModal isOpen={isActivityModalOpen} onClose={() =>   setIsActivityModalOpen(false)} unitId={activityId ?? ''} />
    </>
  )
}
