import React, { useState } from 'react'
import { Popover, Typography, Box } from '@mui/material'
import { InfoOutlineIcon } from '@src/assets/icons/utils'
import { LxIcon } from '@src/components/icon/Icon'

const LogDetailsPopover = ({ details }: { details: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  // Parse and filter out null & empty values
  let parsedDetails: Record<string, any> = {}
  try {
    const data = JSON.parse(details)
    if (typeof data === 'object' && data !== null) {
      parsedDetails = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== null && value !== '') // Remove null & empty values
      )
    }
  } catch (error) {
    parsedDetails = { error: 'Invalid JSON format' }
  }

  const renderArray = (array: any[]) => {
    return array.map((item, index) => {
      if (typeof item === 'object') {
        return (
          <Box key={index} sx={{ pl: 2, mt: 2 }}>
            {Object.entries(item).map(([key, value]) => (
              <Typography key={key} sx={{ fontSize: '12px', fontFamily: 'monospace' }}>
                <strong>{key}:</strong> {value}
              </Typography>
            ))}
          </Box>
        )
      }
      return (
        <Typography key={index} sx={{ fontSize: '12px', fontFamily: 'monospace' }}>
          {item}
        </Typography>
      )
    })
  }

  return (
    <>
      {/* Icon that triggers popover on click */}
      <div onClick={handleClick} style={{ display: 'inline-block', cursor: 'pointer' }}>
        <LxIcon icon={InfoOutlineIcon} />
      </div>

      {/* Popover for displaying formatted key-value details */}
      <Popover
        id="log-details-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ p: 2, minWidth: 200 }}>
          {Object.keys(parsedDetails).length > 0 ? (
            Object.entries(parsedDetails).map(([key, value]) => {
              if (Array.isArray(value)) {
                // Handle arrays with dynamic structures
                return (
                  <div key={key}>
                    <Typography sx={{ fontSize: '12px', fontFamily: 'monospace' }}>
                      <strong>{key}:</strong>
                    </Typography>
                    {renderArray(value)}
                  </div>
                )
              }

              // For regular key-value pairs
              return (
                <Typography key={key} sx={{ fontSize: '12px', fontFamily: 'monospace' }}>
                  <strong>{key}:</strong> {value}
                </Typography>
              )
            })
          ) : (
            <Typography sx={{ fontSize: '12px', fontFamily: 'monospace' }}>No Details to show</Typography>
          )}
        </Box>
      </Popover>
    </>
  )
}

export default LogDetailsPopover
