import { Dialog } from '@mui/material'

import React from 'react'

import styles from '../pages/Learner/Show/TeamMembers/LearnerAddNewTeamMember.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

// TODO upgrade inline styles
export const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  confirm = 'Confirm',
  title = 'Confirmation',
  isFormSubmitting = false,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} role='dialog'>
      <div className={styles.materialUiContainerWrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h3>{title}</h3>
          </div>
          <div className={styles.modalContent}>
            
            {isFormSubmitting && (
              <div className={styles.LoadingOverlay}>
                <LxLoadingSpinner />
              </div>
            )}

            <span style={{ fontSize: '16px', display: 'block', lineHeight: 1.5, textAlign: 'justify' }}>
              {' '}
              {message.split('\n').map((line: string, index: number) => (
                <React.Fragment key={index}>
                   {line.split(/(\*\*.+?\*\*)/g).map((part, i) =>
                    part.startsWith('**') && part.endsWith('**') ? (
                      <strong key={i}>{part.slice(2, -2)}</strong>
                    ) : (
                      part
                    )
                  )}
                  <br />
                </React.Fragment>
              ))}
            </span>
          </div>
          <div className={styles.modalFooter}>
            <div
              className={'lxActionButton lxActionButtonDefaultSize'}
              onClick={onClose}
              style={{ marginRight: '32px' }}
              tabIndex={0} 
              onKeyDown={(event) => LxHandleKeyDown(event, onClose)}
            >
              <span>Cancel</span>
            </div>
            <div
              className={
                'lxActionButton lxActionButtonFilled lxActionButtonDefaultSize'
              }
              onClick={onConfirm}
              tabIndex={0} 
              onKeyDown={(event) => LxHandleKeyDown(event, onConfirm)}
            >
              <span>{confirm}</span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
