import React, { useEffect, useMemo, useState } from 'react'
import styles from './ProgressReportFilterBar.module.scss';
import { useProgressReport } from '@logic/contexts/Learners/ProgressReport/ProgressReportContext.tsx'
import {
  ACTIVITY_UNIT_FILTER_KEY,
  FilterObject,
  GOALS_FILTER_KEY,
  SKILL_AREAS_FILTER_KEY, STATUS_FILTER_KEY
} from '@logic/useFiltering.hook.ts'
import { useSearchParams } from 'react-router-dom'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { deserializeQuery, serializeQuery } from '@src/utils/querySerialization.ts'
import {
  ProgressGoals,
  ProgressReport,
  ProgressReportArea,
  ReportUnitF
} from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { ReportFilters } from '@logic/contexts/Learners/ProgressReport/progressReport.factory.tsx'
import { pushUnique } from '@src/utils/pushUnique.ts'
import { cn } from '@src/utils/cn.ts'
import { LxAutocomplete } from '@components/autocomplete/autocomplete.tsx'
import { LxHandleKeyDown } from '@src/utils/Accessbility';

type ProgressReportFilters = {
  skillAreas?: string;
  goals?: string;
  units?: string;
  status?: string;
}



export const ReportTopicSelector: React.FC = () => {
  const { allData, reportFilterOptions, filters, isReportLoading, resetFilters, isPristine } = useProgressReport()
  const [localReportFilterOptions, setLocalReportFilterOptions] = useState<ReportFilters>(reportFilterOptions)
  const [searchParams, setSearchParams] = useSearchParams()
  const [firstLoad, setFirstLoad] = useState(false)

  const resetComponentFilters = () => {
    resetFilters();
    setLocalReportFilterOptions(reportFilterOptions);
  }


  useEffect(() => {
    setLocalReportFilterOptions(prev => ({
        goalOptions: [...reportFilterOptions.goalOptions],
        skillAreaOptions: [...reportFilterOptions.skillAreaOptions],
        unitOptions: [...reportFilterOptions.unitOptions],
        statusOptions: [...reportFilterOptions.statusOptions]
      })
    );
    // reset url state filters on unmount
  }, [reportFilterOptions]);


  useEffect(() => {
    if(isReportLoading || firstLoad) {
      return;
    }

    const skillAreasParam = searchParams.get('skillAreas');
    const goalsParam = searchParams.get('goals');
    const unitsParam = searchParams.get('units');
    const statusParam = searchParams.get('status');

    const urlSkillAreas =
      deserializeQuery(skillAreasParam)
        .map(name => reportFilterOptions.skillAreaOptions.find(skillAreaFilter => skillAreaFilter.value === name))
        .filter(Boolean);

    const urlGoals = deserializeQuery(goalsParam)
      .map(name => reportFilterOptions.goalOptions.find(goalFilter => goalFilter.value === name))
      .filter(Boolean);

    const urlUnits = deserializeQuery(unitsParam)
      .map(name => reportFilterOptions.unitOptions.find(unitFilter => unitFilter.value === name))
      .filter(Boolean);

    const urlStatus = deserializeQuery(statusParam)
      .map(name => reportFilterOptions.statusOptions.find(statusFilter => statusFilter.value === name))
      .filter(Boolean);

    filters[SKILL_AREAS_FILTER_KEY].set(urlSkillAreas)
    filters[GOALS_FILTER_KEY].set(urlGoals)
    filters[ACTIVITY_UNIT_FILTER_KEY].set(urlUnits)
    filters[STATUS_FILTER_KEY].set(urlStatus)

    setFirstLoad(true)

  }, [isReportLoading]);



  useEffect(() => {
    if (!firstLoad) {
      return;
    }

    let skillAreasParam = serializeQuery(filters[SKILL_AREAS_FILTER_KEY].data);
    let goalsParam = serializeQuery(filters[GOALS_FILTER_KEY].data);
    let unitsParam = serializeQuery(filters[ACTIVITY_UNIT_FILTER_KEY].data);
    let statusParam = serializeQuery(filters[STATUS_FILTER_KEY].data);

    const newSearchParams: ProgressReportFilters = {};

    if (skillAreasParam) {
      newSearchParams.skillAreas = skillAreasParam;
    }

    if (goalsParam) {
      newSearchParams.goals = goalsParam;
    }

    if (unitsParam) {
      newSearchParams.units = unitsParam;
    }

    if (statusParam) {
      newSearchParams.status = statusParam;
    }

    setSearchParams(newSearchParams);

  }, [filters[SKILL_AREAS_FILTER_KEY].data, filters[GOALS_FILTER_KEY].data, filters[ACTIVITY_UNIT_FILTER_KEY].data, filters[STATUS_FILTER_KEY].data, firstLoad]);

  const isAllFiltersDefault = useMemo(() => {
    return isPristine ? false : (filters[SKILL_AREAS_FILTER_KEY].data.length === 0
      && filters[GOALS_FILTER_KEY].data.length === 0
      && filters[ACTIVITY_UNIT_FILTER_KEY].data.length === 0
      && filters[STATUS_FILTER_KEY].data.length === 0)
  }, [filters])

  const getAllMatchingSkillAreas = (report: ProgressReport | null, filtersOption: ReportFilters): ProgressReportArea[] => {
    if (isNilOrEmpty(report?.report)) {
      return [];
    }

    return report!.report!.filter(skillArea =>
        (filtersOption.skillAreaOptions.length > 0 ? filtersOption.skillAreaOptions.some(selectedSkillArea => selectedSkillArea.value === skillArea.name) : true)
        && skillArea.goals.some(goal =>
          (filtersOption.unitOptions.length > 0 ? goal.units.some(unit => filtersOption.unitOptions.some(selectedUnit => selectedUnit.value === unit.name)) : true)
          || (filtersOption.statusOptions.length > 0 ? goal.units.some(unit => filtersOption.statusOptions.some(selectedStatus => selectedStatus.value === unit.status)) : true)
          || (filtersOption.goalOptions.length > 0 ? filtersOption.goalOptions.some(selectedGoal => selectedGoal.value === goal.name) : true)

        )
    ).map(skillArea => ({
      ...skillArea,
      goals: skillArea.goals.filter(goal =>
        (filtersOption.unitOptions.length > 0 ? goal.units.some(unit => filtersOption.unitOptions.some(selectedUnit => selectedUnit.value === unit.name)) : true)
        || (filtersOption.statusOptions.length > 0 ? goal.units.some(unit => filtersOption.statusOptions.some(selectedStatus => selectedStatus.value === unit.status)) : true)
        || (filtersOption.goalOptions.length > 0 ? filtersOption.goalOptions.some(selectedGoal => selectedGoal.value === goal.name) : true)
      )
    }));
  };
  const getAllMatchingGoals = (report: ProgressReport | null, filtersOption: ReportFilters): ProgressGoals[] => {
    if (isNilOrEmpty(report?.report)) {
      return [];
    }
    return report!.report!.flatMap(skillArea =>
      skillArea.goals.filter(goal =>
        goal.units.some(unit =>
          filtersOption.unitOptions.some(selectedUnit => selectedUnit.value === unit.name)
          || filtersOption.statusOptions.some(selectedStatus => selectedStatus.value === unit.status)
        )
        && filtersOption.skillAreaOptions.length > 0
          ? filtersOption.skillAreaOptions.some(selectedSkillArea => selectedSkillArea.value === skillArea.name)
          : true
      )
    );
  };

  const getAllMatchingUnits = (report: ProgressReport | null, filtersOption: ReportFilters): ReportUnitF[] => {
    if (isNilOrEmpty(report?.report)) {
      return [];
    }

    return report!.report!.flatMap(skillArea =>
      skillArea.goals.flatMap(goal =>
        goal.units.filter(unit =>
          (filtersOption.goalOptions.length > 0 ? filtersOption.goalOptions.some(selectedGoal => selectedGoal.value === goal.name) : true)
          && (filtersOption.skillAreaOptions.length > 0 ? filtersOption.skillAreaOptions.some(selectedSkillArea => selectedSkillArea.value === skillArea.name) : true)
          && (filtersOption.statusOptions.length > 0 ? filtersOption.statusOptions.some(selectedStatus => selectedStatus.value === unit.status) : true)
        )
      )
    );
  };

  const getAllMatchingStatuses = (report: ProgressReport | null, filtersOption: ReportFilters): { name: string }[] => {
    if (isNilOrEmpty(report?.report)) {
      return [];
    }

    const statuses = [];
    report!.report!.forEach(skillArea =>
      skillArea.goals.forEach(goal =>
        goal.units.forEach(unit => {
          if ((filtersOption.goalOptions.length > 0 ? filtersOption.goalOptions.some(selectedGoal => selectedGoal.value === goal.name) : true)
            && (filtersOption.skillAreaOptions.length > 0 ? filtersOption.skillAreaOptions.some(selectedSkillArea => selectedSkillArea.value === skillArea.name) : true)
            && (filtersOption.unitOptions.length > 0 ? filtersOption.unitOptions.some(selectedUnit => selectedUnit.value === unit.name) : true)) {
            pushUnique(statuses, { name: unit.status }, 'name');
          }
        })
      )
    );
    return statuses;
  };


  const enableFilterOption
    = (prevOptions: FilterObject<string>[]) => (matching: any) =>
    prevOptions.map(prefObject => ({
      ...prefObject,
      isEnabled: matching.some(matchingCategory => matchingCategory.name === prefObject.value),
    }))
      .sort((a, b) => (b.isEnabled ? 1 : 0) - (a.isEnabled ? 1 : 0))


  const handleSkillAreaChange = (filtersOption: ReportFilters) => {
    // const matchingSkillAreas = getAllMatchingSkillAreas(report, filtersOption)
    const matchingGoals = getAllMatchingGoals(allData, filtersOption)
    const matchingUnits = getAllMatchingUnits(allData, filtersOption)
    const matchingStatuses = getAllMatchingStatuses(allData, filtersOption)

    // console.log(matchingSkillAreas, enableFilterOption(localReportFilterOptions.skillAreaOptions)(matchingSkillAreas), localReportFilterOptions.skillAreaOptions)

    setLocalReportFilterOptions(prevReportFilterOptions => ({
        skillAreaOptions: prevReportFilterOptions.skillAreaOptions,
        goalOptions: enableFilterOption(prevReportFilterOptions.goalOptions)(matchingGoals),
        unitOptions: enableFilterOption(prevReportFilterOptions.unitOptions)(matchingUnits),
        statusOptions: enableFilterOption(prevReportFilterOptions.statusOptions)(matchingStatuses)
    }))
  }

  const handleGoalChange = (filtersOption: ReportFilters) => {
    const matchingSkillAreas = getAllMatchingSkillAreas(allData, filtersOption)
    const matchingUnits = getAllMatchingUnits(allData, filtersOption)
    const matchingStatuses = getAllMatchingStatuses(allData, filtersOption)


    setLocalReportFilterOptions(prevReportFilterOptions => ({
      skillAreaOptions: enableFilterOption(prevReportFilterOptions.skillAreaOptions)(matchingSkillAreas),
      goalOptions: prevReportFilterOptions.goalOptions,
      unitOptions: enableFilterOption(prevReportFilterOptions.unitOptions)(matchingUnits),
      statusOptions: enableFilterOption(prevReportFilterOptions.statusOptions)(matchingStatuses)
    }))
  }

  const handleUnitChange = (filtersOption: ReportFilters) => {
    const matchingSkillAreas = getAllMatchingSkillAreas(allData, filtersOption)
    const matchingStatuses = getAllMatchingStatuses(allData, filtersOption)
    const matchingGoals = getAllMatchingGoals(allData, filtersOption)

    setLocalReportFilterOptions(prevReportFilterOptions => ({
      goalOptions: enableFilterOption(prevReportFilterOptions.goalOptions)(matchingGoals),
      skillAreaOptions: enableFilterOption(prevReportFilterOptions.skillAreaOptions)(matchingSkillAreas),
      unitOptions: prevReportFilterOptions.unitOptions,
      statusOptions: enableFilterOption(prevReportFilterOptions.statusOptions)(matchingStatuses)
    }))
  }

  const handleStatusChange = (filtersOption: ReportFilters) => {
    const matchingSkillAreas = getAllMatchingSkillAreas(allData, filtersOption)
    const matchingGoals = getAllMatchingGoals(allData, filtersOption)
    const matchingUnits = getAllMatchingUnits(allData, filtersOption)

    setLocalReportFilterOptions(prevReportFilterOptions => ({
      goalOptions: enableFilterOption(prevReportFilterOptions.goalOptions)(matchingGoals),
      skillAreaOptions: enableFilterOption(prevReportFilterOptions.skillAreaOptions)(matchingSkillAreas),
      unitOptions: enableFilterOption(prevReportFilterOptions.unitOptions)(matchingUnits),
      statusOptions: prevReportFilterOptions.statusOptions
    }))
  }


  return (
    <div className={cn(styles.filterBarContainer, 'ignoreOnPrint')}>
      <div className={styles.filterBarHeader}>
        <div></div>
        <div className={cn('lxActionButton lxActionButtonDefaultSize', {'disabled': isAllFiltersDefault})} onClick={resetComponentFilters} tabIndex={0} onKeyDown={(event) => {LxHandleKeyDown(event, resetComponentFilters)} }><span>Reset filters</span></div>
      </div>
      <div className={styles.filters}>
        <LxAutocomplete
          multiple
          placeholder={'Filter by Skill Area'}
          value={filters[SKILL_AREAS_FILTER_KEY].data}
          options={localReportFilterOptions.skillAreaOptions}
          onChange={(values: FilterObject<string>[]) => {
            filters[SKILL_AREAS_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleSkillAreaChange({...localReportFilterOptions, skillAreaOptions: values});
            } else {
              handleSkillAreaChange({...localReportFilterOptions, skillAreaOptions: reportFilterOptions.skillAreaOptions})
            }
          }}
        ></LxAutocomplete>
        <LxAutocomplete
          multiple
          placeholder={'Filter by Goal'}
          value={filters[GOALS_FILTER_KEY].data}
          options={localReportFilterOptions.goalOptions}
          onChange={(values: FilterObject<string>[]) => {
            filters[GOALS_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleGoalChange({...localReportFilterOptions, goalOptions: values});
            } else {
              handleGoalChange({...localReportFilterOptions, goalOptions: reportFilterOptions.goalOptions})
            }
          }}
        />
        <LxAutocomplete
          multiple
          placeholder={'Filter by Activity'}
          value={filters[ACTIVITY_UNIT_FILTER_KEY].data}
          options={localReportFilterOptions.unitOptions}
          onChange={(values: FilterObject<string>[]) => {
            filters[ACTIVITY_UNIT_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleUnitChange({...localReportFilterOptions, unitOptions: values});
            } else {
              handleUnitChange({...localReportFilterOptions, unitOptions: reportFilterOptions.unitOptions})
            }
          }}
        />
        <LxAutocomplete
          multiple
          placeholder={'Filter by Status'}
          value={filters[STATUS_FILTER_KEY].data}
          options={localReportFilterOptions.statusOptions}
          onChange={(values: FilterObject<string>[]) => {
            filters[STATUS_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleStatusChange({...localReportFilterOptions, statusOptions: values})
            }
            else {
              handleStatusChange({...localReportFilterOptions, statusOptions: reportFilterOptions.statusOptions})
            }
          }}
        />
      </div>
    </div>
  );
};

export default ReportTopicSelector;
