import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useRobotsList } from '@src/logic/contexts/Robots/RobotsListsContext'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { LxClickAbleIcon } from '@src/components/icon/clickAbleIcon'
import { LxSelect } from '@components/select/select'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import { isNilOrEmpty } from '@utils/isNilOrEmpty'
import { formatDate } from '@utils/dateFormatter'
import styles from './RobotList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import RobotVersionInfo from './RobotVersionInfo'
import { AssignRobot } from './AssignRobot'
import RobotVoiceInfo from './RobotVoiceInfo'
import { cn } from '@utils/cn'
import { QtIcon } from '@icons/index'
import { LxIcon } from '@components/icon/Icon.tsx'
import { SearchIcon, FilterIcon, PlusOutlineIcon } from '@icons/utils'
import { getVoiceLabel } from '@utils/voiceMapper'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { FilterObject, generateSimpleOptions } from '@src/logic/useFiltering.hook'
import { useAccountsList } from '@src/logic/contexts/Accounts/AccountsListsContext'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useDataProvider, useNotify } from 'react-admin'
import { AssignLicenseRobot } from './AssignLicenseRobot'
import { Tooltip } from '@mui/material'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

type DataSet = {
  accounts?: FilterObject<AccountF>[]
}

export const RobotList = () => {
  useDocumentTitle('Admin Robots')
  const { robotList, isLoading } = useRobotsList()
  const [searchInput, setSearchInput] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [sortConfig, setSortConfig] = useState(null)
  const { showModal, hideModal } = useModal()
  const { accountList, isLoading: isAccountsLoading } = useAccountsList()
  const [onlineRobots, setOnlineRobots] = useState([])

  const notify = useNotify()
  const dataProvider = useDataProvider()

  const options: DataSet = useMemo(
    () => ({
      accounts: generateSimpleOptions(accountList, 'accountName'),
    }),
    [accountList, isAccountsLoading]
  )

  const [modalState, setModalState] = useState({
    open: false,
    robot: null,
    info: null,
    type: '',
  })

  // Utility function to get nested property
  const getNestedValue = useCallback((obj, path) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj)
  }, [])

  const handleSearchAndFilter = useCallback((inputValue, filterValue) => {
    setSearchInput(inputValue)
    setFilterType(filterValue)
  }, [])

  const handleSort = useCallback(
    (key) => {
      if (isLoading) return
      setSortConfig((prev) => {
        const direction = prev && prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc'
        return { key, direction }
      })
    },
    [isLoading]
  )

  const filteredAndSortedData = useMemo(() => {
    if (!robotList?.length) return []

    const searchLower = searchInput?.toLowerCase() || ''
    const hasSearchInput = !!searchInput

    const filteredData = robotList.filter((robot) => {
      const resultSearch =
        !hasSearchInput ||
        robot.serial.toLowerCase().includes(searchLower) ||
        (robot.accountName && robot.accountName.toLowerCase().includes(searchLower)) ||
        (robot.ownerAccountName && robot.ownerAccountName.toLowerCase().includes(searchLower))

      if (filterType === 'Unassigned' && robot.accountId) return false
      if (filterType === 'Online' && !onlineRobots.includes(robot.id)) return false

      return resultSearch
    })

    if (!sortConfig || filteredData.length <= 1) return filteredData

    const { key, direction } = sortConfig
    const multiplier = direction === 'asc' ? 1 : -1

    // Special case for date sorting
    if (key === 'usageInfo.lastActivityDate') {
      return filteredData.sort((a, b) => {
        const dateA = new Date(getNestedValue(a, key) || 0)
        const dateB = new Date(getNestedValue(b, key) || 0)
        return (dateA - dateB) * multiplier
      })
    }

    // Special case for version sorting
    if (key === 'versionInfo.softwareVersion') {
      return filteredData.sort((a, b) => {
        const versionNumA = parseInt(a.versionInfo?.versionNumber, 10) || 0
        const versionNumB = parseInt(b.versionInfo?.versionNumber, 10) || 0

        if (versionNumA !== versionNumB) {
          return (versionNumA - versionNumB) * multiplier
        }

        const versionA = a.versionInfo?.softwareVersion || ''
        const versionB = b.versionInfo?.softwareVersion || ''
        return versionA.localeCompare(versionB) * multiplier
      })
    }

    return filteredData.sort((a, b) => {
      const valA = getNestedValue(a, key)
      const valB = getNestedValue(b, key)

      // Handle null/undefined values
      if (valA == null && valB == null) return 0
      if (valA == null) return 1 * multiplier
      if (valB == null) return -1 * multiplier

      // Compare values
      if (valA < valB) return -1 * multiplier
      if (valA > valB) return 1 * multiplier
      return 0
    })
  }, [robotList, searchInput, filterType, sortConfig, onlineRobots])

  const openModal = useCallback((type, info, id, serial) => {
    setModalState({ open: true, type, info: { ...info, id, serial } })
  }, [])

  const closeModal = useCallback(() => {
    setModalState({ open: false, type: '', info: null })
  }, [])

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify('Copied ID clipboard - ' + text)
      })
      .catch((err) => {
        notify('Failed to Copy ID clipboard - ' + err)
      })
  }

  useEffect(() => {
    dataProvider
      .getList('Robot', {
        meta: { method: 'getOnlineRobots' },
      })
      .then((response) => {
        setOnlineRobots(response.data.map((robot) => robot.id))
      })
      .catch((err) => {
        notify('Failed to fetch online status', { type: 'warning' })
        setOnlineRobots([])
      })
  }, [dataProvider])

  return (
    <>
      <div className={styles.robotTableNavigation}>
        <div className={styles.robotsListHeading}>
          <LxIcon icon={QtIcon} sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }} />
          <h3>Robots {filteredAndSortedData?.length && ` (${filteredAndSortedData?.length})`} </h3>
        </div>

        <div className={sharedStyles.tableActions}>
          <div className={cn('lxActionButton', 'lxActionButtonDefaultSize', 'inputHighLight')}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => handleSearchAndFilter(e.target.value, filterType)}
              placeholder='Search by Serial, Account Name'
            />
          </div>

          <LxSelect
            shrinked
            icon={<LxIcon icon={FilterIcon} customViewBox={'0 0 256 256'} sxStyles={{ height: '26px', width: '32px' }} />}
            placeholder={filterType}
            onChange={(value) => handleSearchAndFilter(searchInput, value)}
            options={[
              { value: 'All Robots', label: 'All Robots' },
              { value: 'Unassigned', label: 'Unassigned' },
              { value: 'Online', label: 'Online' },
            ]}
          />
        </div>
      </div>

      <div className={styles.robotsListContent}>
        <div className={cn(styles.robotsTableListHeader, styles.robotName)} onClick={() => handleSort('serial')}>
          Serial No.
        </div>

        <div
          className={cn(styles.robotsTableListHeader, sharedStyles.hide2column, styles.overflowText)}
          onClick={() => handleSort('ownerAccountName')}
        >
          Deligator
        </div>
        <div className={cn(styles.robotsTableListHeader, styles.overflowText)} onClick={() => handleSort('accountName')}>
          Assigned
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide3column)} onClick={() => handleSort('usageInfo.lastActivityDate')}>
          Last Activity
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide4column)} onClick={() => handleSort('creationInfo.userName')}>
          Created By
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide5column)} onClick={() => handleSort('versionInfo.softwareVersion')}>
          Version Info
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide6column)} onClick={() => handleSort('configuration.voice.default')}>
          Voices
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide6column)}>License</div>

        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(filteredAndSortedData) ? (
          <div className={sharedStyles.notFound}>No robot matches your current search.</div>
        ) : (
          filteredAndSortedData.map((robot) => (
            <React.Fragment key={robot.id}>
              <div>
                <Tooltip
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', maxWidth: '300px' }}>
                      <span style={{ wordWrap: 'break-word', flex: 1 }}>{robot.id}</span>
                      <ContentCopyIcon
                        onClick={() => handleCopy(robot.id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '18px',
                          color: '#4a90e2',
                        }}
                      />
                    </div>
                  }
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div
                    className={cn({ [styles.blinkingText]: onlineRobots.includes(robot.id) })}
                    style={{ color: onlineRobots.includes(robot.id) ? 'green' : 'inherit' }}
                  >
                    {robot.serial}
                  </div>
                </Tooltip>
              </div>

              <div className={cn(styles.noCursor, sharedStyles.hide2column)}>{robot.ownerAccountName ? robot.ownerAccountName : '-'}</div>
              <div className={cn({ [styles.noCursor]: robot.accountName && robot.accountName.trim() })}>
                {robot.accountName && robot.accountName.trim() ? (
                  robot.accountName
                ) : (
                  <LxTooltip tooltipText={'Robot is not assigned, Click to assign'}>
                    <LxClickAbleIcon
                      className={cn(styles.activityAction, styles.createNewGoalAction)}
                      icon={PlusOutlineIcon}
                      onClick={() => showModal(<AssignRobot robot={robot} onClose={hideModal} accounts={options.accounts} />)}
                    />
                  </LxTooltip>
                )}
              </div>
              <div className={cn(styles.noCursor, sharedStyles.hide3column)}>
                {robot.usageInfo?.lastActivityDate ? formatDate(robot.usageInfo?.lastActivityDate) : 'N/A'}
              </div>
              <div className={cn(sharedStyles.hide4column)}>
                <LxTooltip tooltipText={formatDate(robot.creationInfo.date)}>{robot.creationInfo?.userName}</LxTooltip>
              </div>
              <div onClick={() => openModal('version', robot.versionInfo, robot.id, robot.serial)} className={cn(sharedStyles.hide5column)}>
                {robot.versionInfo?.softwareVersion + ' - ' + robot.versionInfo?.versionNumber}
              </div>
              <div onClick={() => openModal('voice', robot.configuration?.voice, robot.id, robot.serial)} className={cn(sharedStyles.hide6column)}>
                {getVoiceLabel(robot.configuration?.voice?.default)}
              </div>

              <div className={styles.noCursor}>
                {robot.accountName?.trim() ? (
                  Object.keys(robot.assignedLicenses).length > 0 ? (
                    'Assigned'
                  ) : (
                    <LxTooltip tooltipText={'Robot is not assigned or license missing, Click to assign'}>
                      <LxClickAbleIcon
                        className={cn(styles.activityAction, styles.createNewGoalAction)}
                        icon={PlusOutlineIcon}
                        onClick={() => showModal(<AssignLicenseRobot robot={robot} onClose={hideModal} />)}
                      />
                    </LxTooltip>
                  )
                ) : (
                  <LxTooltip tooltipText={'Please assign robot to account, Before assigning license'}>
                    <LxIcon icon={PlusOutlineIcon} />
                  </LxTooltip>
                )}
              </div>
            </React.Fragment>
          ))
        )}
      </div>

      <RobotVersionInfo
        versionInfo={modalState.type === 'version' && modalState.info}
        open={modalState.open && modalState.type === 'version'}
        onClose={closeModal}
      />
      <RobotVoiceInfo
        voiceInfo={modalState.type === 'voice' && modalState.info}
        open={modalState.open && modalState.type === 'voice'}
        onClose={closeModal}
      />
    </>
  )
}

export default RobotList
