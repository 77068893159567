import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './autocomplete.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from '@icons/utils'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { uniqueArrayByKey } from '@src/utils/uniqueArrayByKey.ts'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

interface Props {
  value: FilterObject<any>[] | FilterObject<any> | null;
  onChange: (val: any) => void;
  options: FilterObject<string>[];
  className?: string;
  multiple?: boolean;
  simple?: boolean;
  selectVariant?: boolean;
  placeholder?: string;
}

export const LxAutocomplete: React.FC<Props> = ({
  onChange,
  options,
  simple,
  value,
  className,
  multiple = true,
  placeholder,
  selectVariant
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(value ?? [])
  const [filteredOptions, setFilteredOptions] = useState<FilterObject<any>[]>(options)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [activeElement, setActiveElement] = useState<'search' | 'close' | 'clear' | 'options' | null>(null)
  
  const selectRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const closeButtonRef = useRef<HTMLDivElement>(null)
  const clearButtonRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<(HTMLDivElement | null)[]>([])
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      setSelectedIndex(-1)
      setActiveElement(null)
    }
  }

  const resetFilter = () => {
    setSelectedOptions([])
    onChange([])
    setSearchQuery('')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setSelectedIndex(-1)
  }

  const handleOptionClick = (optionValue: FilterObject<any>) => {
    if (!optionValue.isEnabled) return

    const isOptionSelected = selectedOptions.some((selectedOption) => selectedOption.label === optionValue.label)
    
    const newOptions = isOptionSelected
      ? selectedOptions.filter((selectedOption) => selectedOption.label !== optionValue.label)
      : multiple
        ? uniqueArrayByKey([...selectedOptions, optionValue], 'value')
        : optionValue

    setSelectedOptions(newOptions)
    onChange(newOptions)

    if (!multiple) {
      setIsOpen(false)
      setSelectedIndex(-1)
    }
  }

  // Handle focus movement between interactive elements
  const getAvailableElements = () => {
    const elements: ('search' | 'close' | 'clear' | 'options')[] = [];
    
    if (!simple) {
      elements.push('search');
    }
    
    if (selectVariant) {
      elements.push('close');
    }
    
    elements.push('clear');
    elements.push('options');
    
    return elements;
  };

  // Modified moveFocus function
  const moveFocus = (direction: 'next' | 'prev') => {
    const elements = getAvailableElements();
    const currentIndex = elements.indexOf(activeElement || elements[0]);
    let nextIndex;

    if (direction === 'next') {
      nextIndex = currentIndex === elements.length - 1 ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex <= 0 ? elements.length - 1 : currentIndex - 1;
    }

    setActiveElement(elements[nextIndex]);
    
    // Focus the appropriate element
    switch (elements[nextIndex]) {
      case 'search':
        searchInputRef.current?.focus();
        break;
      case 'close':
        closeButtonRef.current?.focus();
        break;
      case 'clear':
        clearButtonRef.current?.focus();
        break;
      case 'options':
        if (selectedIndex === -1) {
          setSelectedIndex(0);
        }
        optionsRef.current[selectedIndex]?.focus();
        break;
    }
  };

  // Focus management when dropdown opens - modified to use available elements
  useEffect(() => {
    if (isOpen) {
      const elements = getAvailableElements();
      const firstElement = elements[0];
      
      switch (firstElement) {
        case 'search':
          searchInputRef.current?.focus();
          break;
        case 'close':
          closeButtonRef.current?.focus();
          break;
        case 'clear':
          clearButtonRef.current?.focus();
          break;
        case 'options':
          if (selectedIndex === -1) {
            setSelectedIndex(0);
          }
          optionsRef.current[selectedIndex]?.focus();
          break;
      }
      setActiveElement(firstElement);
    }
  }, [isOpen, simple, selectVariant]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault()
      setIsOpen(true)
      return
    }

    if (activeElement === 'options') {
      switch (event.key) {
        case 'Enter':
          event.preventDefault()
          if (selectedIndex >= 0 && selectedIndex < filteredOptions.length) {
            handleOptionClick(filteredOptions[selectedIndex])
          }
          break
        case 'ArrowDown':
          event.preventDefault()
          setSelectedIndex((prev) => 
            prev === filteredOptions.length - 1 ? 0 : prev + 1
          )
          break
        case 'ArrowUp':
          event.preventDefault()
          setSelectedIndex((prev) => 
            prev <= 0 ? filteredOptions.length - 1 : prev - 1
          )
          break
      }
    }

    switch (event.key) {
      case 'Tab':
        if (isOpen) {
          event.preventDefault()
          moveFocus(event.shiftKey ? 'prev' : 'next')
        }
        break
      case 'Escape':
        setIsOpen(false)
        setSelectedIndex(-1)
        setActiveElement(null)
        break
    }
  }

  // Scroll selected option into view
  useEffect(() => {
    if (selectedIndex >= 0 && optionsRef.current[selectedIndex]) {
      optionsRef.current[selectedIndex]?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      })
    }
  }, [selectedIndex])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        setSelectedIndex(-1)
        setActiveElement(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [selectRef])

  useEffect(() => {
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredOptions(filteredOptions)
  }, [searchQuery, options])

  useEffect(() => {
    setSelectedOptions(value ?? [])
  }, [value])

  // Focus management when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current && !simple) {
      searchInputRef.current.focus()
      setActiveElement('search')
    }
  }, [isOpen, simple])

  const autoCompletePlaceholder = () => {
    if ((selectedOptions as []).length > 0) {
      return (selectedOptions as FilterObject[]).map((option, index) => (
        <div key={`selected-option-${index}`}>{option.label}</div>
      ))
    }
    return placeholder || 'Select an option'
  }

  return (
    <div
      className={cn(
        styles.autocompleteContainer,
        { [styles.selected]: selectedOptions.some(selectedOption => selectedOption.value) },
        { [styles.autocompleteContainerSelectVariant]: selectVariant },
        className
      )}
      ref={selectRef}
      role="combobox"
      aria-expanded={isOpen}
      aria-haspopup="listbox"
      aria-controls="select-dropdown"
      aria-label={placeholder || "Select options"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div 
        onClick={toggleDropdown}
        role="button"
        aria-label={isOpen ? "Close dropdown" : "Open dropdown"}
      >
        <div className={styles.placeholder}>{autoCompletePlaceholder()}</div>
        <LxIcon 
          icon={isOpen ? ArrowUpIcon : ArrowDownIcon} 
          customViewBox={'0 0 960 560'}
        />
      </div>
      {isOpen && (
        <div 
          className={styles.selectItems}
          id="select-dropdown"
          role="listbox"
          aria-multiselectable={multiple}
        >
          <div className={styles.filters}>
            <div>
              <div className={cn('lxActionButton',  'inputHighLight',{ [styles.hide]: simple })}>
                <LxIcon 
                  icon={SearchIcon} 
                  sxStyles={{ height: '24px', width: '24px' }}
                />
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={handleInputChange}
                  value={searchQuery}
                  placeholder="Search"
                  ref={searchInputRef}
                  aria-label="Search options"
                  role="searchbox"
                  tabIndex={isOpen ? 0 : -1}
                  onFocus={() => setActiveElement('search')}
                />
              </div>
              {selectVariant && (
                <div 
                  className={'lxActionButton'} 
                  onClick={() => setIsOpen(false)}
                  role="button"
                  aria-label="Close dropdown"
                  ref={closeButtonRef}
                  tabIndex={isOpen ? 0 : -1}
                  onFocus={() => setActiveElement('close')}
                  onKeyDown={(event) => LxHandleKeyDown(event, () => setIsOpen(false))}
                >
                  Close
                </div>
              )}
              <div 
                className={cn('lxActionButton', { 'disabled': !selectedOptions.length }, styles.clearButton)}
                onClick={resetFilter}
                role="button"
                aria-label="Clear selection"
                ref={clearButtonRef}
                tabIndex={isOpen ? 0 : -1}
                onFocus={() => setActiveElement('clear')}
                onKeyDown={(event) => LxHandleKeyDown(event, resetFilter)}
              >
                Clear
              </div>
            </div>
          </div>
          {filteredOptions.map((option, index) => (
            <div
              key={`${index}-${option.value}`}
              className={cn(
                styles.selectItem,
                {
                  [styles.selected]: selectedOptions.some(
                    selectedOption => selectedOption.label === option.label
                  ),
                  [styles.KeyboardSelected]: selectedIndex === index,
                  'disabled': !option.isEnabled
                }
              )}
              onClick={() => handleOptionClick(option)}
              role="option"
              aria-selected={selectedOptions.some(
                selectedOption => selectedOption.label === option.label
              )}
              aria-disabled={!option.isEnabled}
              ref={el => optionsRef.current[index] = el}
              tabIndex={isOpen && activeElement === 'options' ? 0 : -1}
              onFocus={() => {
                setActiveElement('options')
                setSelectedIndex(index)
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}