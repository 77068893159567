import React, { useState, useEffect, useRef } from 'react'

import { StudentsOutlineIcon, GoalOutlineIcon, ActivitiesOutlineIcon, TeamMemberIcon, AnalyticsOutlineIcon, UserInfoIcon } from '@icons/index.ts'
import { SearchIcon, PlusIcon, FilterIcon } from '@icons/utils'

import styles from './Learner.module.scss'
import sharedStyles from '../MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { cn } from '@src/utils/cn.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'

import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'

import LearnerInfo from './LearnerInfo'
import { LxSelect } from '@components/select/select.tsx'
import { LxAutocompleteTmp } from '@components/autocompleteTmp/autocompleteTmp.tsx'
import { isAdmin } from '@logic/contexts/utils/rolesCheck.ts'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LearnerListActions, LearnerListActionsHeader } from '@src/pages/Learner/LearnerListActions.tsx'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

export const LearnerList = () => {
  useDocumentTitle('Learners')
  const { learnerList, isLoading, filterValues, dispatch } = useLearnersList()
  const { showModal } = useModal()
  const [filteredData, setFilteredData] = useState(learnerList)

  const { currentUser, isUserLoading } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const { Q: inputValue, state: stateFilter, selection: selectionFilter } = filterValues
  // const setInputValue = newValue => dispatch({ type: 'UPDATE_FILTER', field: 'Q', value: newValue })
  const setStateFilter = (newValue) => dispatch({ type: 'UPDATE_FILTER', field: 'state', value: newValue })
  const setSelectionFilter = (newValue) => dispatch({ type: 'UPDATE_FILTER', field: 'selection', value: newValue })

  const handleSearchInputChange = (inputValue: string) => {
    const filteredLearners = learnerList?.filter((learner) => learner.fullName.toLowerCase().includes(inputValue.toLowerCase()))
    setFilteredData(filteredLearners)
  }

  useEffect(() => {
    // Ensure learnerList is loaded before applying search
    if (!isLoading) {
      setFilteredData(learnerList)
    }
  }, [isLoading, learnerList])

  const can_create_learner = canPerformAction(currentUser, { type: 'Learner' }, 'create')

  const canEditLearner = canPerformAction(currentUser, { type: 'Learner' }, 'edit')

  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the page when navigated
    pageRef.current?.focus()
  }, [])

  return (
    <div aria-label='Learners List' tabIndex={-1} ref={pageRef}  style={{ outline: 'none' }}>
      <div className={styles.studentTableNavigation}>
        <div className={cn(sharedStyles.tableInfo)}>
          <div>
            <LxIcon sxStyles={{ height: '80px', width: '80px' }} icon={StudentsOutlineIcon}></LxIcon>
          </div>
          <div>
            <LxAutocompleteTmp
              value={stateFilter}
              onChange={(newValue) => setStateFilter(newValue)}
              options={[
                { value: 'active', label: 'Active Learner Profiles' },
                { value: 'archived', label: 'Archived Learner Profiles' },
                { value: 'deleted', label: 'Deleted Learner Profiles' },
              ]}
              ariaLabel='Drop Down to select active, archived, deleted Learners'
            />
            {stateFilter == 'deleted' && <span> Deleted learner profiles can be restored within 30 days</span>}
          </div>
        </div>
        <div className={cn(sharedStyles.tableActions)}>
          {can_create_learner && (
            <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
              <Link to={'create'}>
                <LxIcon icon={PlusIcon} />
                Add New Learner
              </Link>
            </div>
          )}
          <div className={cn('lxActionButton', 'lxActionButtonDefaultSize', 'inputHighLight')}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder='Search'
              aria-label='Search Learners'
            />
          </div>
          {isAdmin(currentUser.userRole) && (
            <LxSelect
              shrinked
              value={selectionFilter}
              icon={<LxIcon icon={FilterIcon} customViewBox={'0 0 256 256'} sxStyles={{ height: '26px', width: '32px' }} />}
              onChange={(newValue) => setSelectionFilter(newValue)}
              options={[
                { value: 'all', label: 'All Learners' },
                { value: 'assigned', label: 'Learners assigned to me' },
              ]}
            />
          )}
        </div>
      </div>
      <div className={styles.studentListContent}>
        <div className={cn(sharedStyles.tableListHeader)}> Learner</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}> Info</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}> Goals</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}> Activities</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}> Reports</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide6column)}> Team Members</div>
        <div className={cn(sharedStyles.tableListHeader)}>
          <LearnerListActionsHeader canEditLearner={canEditLearner} />
        </div>
        {isLoading ? (
          <div className={sharedStyles.loader}>
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            {filteredData?.length ? (
              filteredData.map((learner: LearnerF) => (
                <React.Fragment key={`Student-row-${learner.id}`}>
                  <div>
                    <span className={sharedStyles.textOverflow}>{learner.fullName}</span>
                  </div>
                  <div className={sharedStyles.hide2column}>
                    <LxClickAbleIcon
                      onClick={() => showModal(<LearnerInfo learner={learner} />)}
                      icon={UserInfoIcon}
                      ariaLabel={`Learner Info for ${learner.fullName}`}
                      role='button'
                    ></LxClickAbleIcon>
                  </div>
                  <div className={sharedStyles.hide3column}>
                    <Link to={`${learner.id}/show/goal`} aria-label={`Goals of ${learner.fullName}`}>
                      <LxIcon icon={GoalOutlineIcon} />
                    </Link>
                  </div>
                  <div className={sharedStyles.hide4column}>
                    <Link to={`${learner.id}/show/activity`} aria-label={`Activities of ${learner.fullName}`}>
                      <LxIcon icon={ActivitiesOutlineIcon} />
                    </Link>
                  </div>
                  <div className={sharedStyles.hide5column}>
                    <Link to={`${learner.id}/show/reports`} aria-label={`Reports of ${learner.fullName}`}>
                      <LxIcon icon={AnalyticsOutlineIcon} />
                    </Link>
                  </div>
                  <div className={sharedStyles.hide6column}>
                    {learner.state !== 'active' ? (
                      <LxTooltip
                        tooltipText={'Learner is not active'}
                        aria-label={`Learner ${learner.fullName} is not active, Not able to view team members`}
                      >
                        <LxIcon icon={TeamMemberIcon} />
                      </LxTooltip>
                    ) : (
                      <Link to={`${learner.id}/show/members`} aria-label={`Team members of ${learner.fullName}`}>
                        <LxIcon icon={TeamMemberIcon} />
                      </Link>
                    )}
                  </div>
                  <div>
                    <LearnerListActions learner={learner} canEditLearner={canEditLearner} />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className={sharedStyles.notFound}>No learners match your current search or filters.</div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default LearnerList
