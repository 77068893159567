import { useEffect, useRef, useState } from 'react'
import { useNotify, useUpdate } from 'react-admin'

import styles from './AnalyticsList.module.scss'
import { SchoolIcon } from '@icons/index.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import sharedStyles from '../MultiPage.module.scss'

import { Dialog } from '@mui/material'

import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { cn } from '@src/utils/cn.ts'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'

export type AccountPropsF = {
  accountId: string
  accountName: string
  customerId: string
  country: string
  primaryContactEmail: string
  primaryContactName: string
}

export type AccountPropsB = {
  account_id: string
  account_name: string
  customer_id: string
  account_country: string
  primary_contact_email: string
  primary_contact_name: string
}


const AccountInfo = ({ accountId }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [accountData, setAccountData] = useState<AccountPropsF | null>(null)

  const notify = useNotify()
  const [update] = useUpdate('Report')

  const sanitizeData = (data: any) => {
    if (!data || !data.report) {
      return null
    }

    const account = data.report
    return {
      accountId: account.account_id,
      accountName: account.account_name,
      customerId: account.customer_id,
      country: account.account_country,
      primaryContactEmail: account.primary_contact_email,
      primaryContactName: account.primary_contact_name,
      address: account.address,

    }
  }

  const fetchData = async () => {
    setIsDataLoading(true)
    try {
      await update(
        'Report',
        {
          id: accountId,
          data: {
            filter: {},
            type: 'account_info',
          },
          previousData: null,
        },
        {
          onSuccess: (data) => {
            const sanitizedData = sanitizeData(data)
            setAccountData(sanitizedData)
            setIsDataLoading(false)
          },
          onFailure: (error) => {
            setAccountData(null)
            const errorMessage = error.message || 'Something went wrong'
            notify(errorMessage, { type: 'warning' })
            setIsDataLoading(false)
          },
        }
      )
    } catch (error) {
      setIsDataLoading(false)
    }
  }

   
  useEffect(() => {
    if (showDialog) {
      fetchData()
      
    }
  }, [showDialog])

  
  return (
    <>
      <LxClickAbleIcon
        onClick={() => {
          setShowDialog(true)
        }}
        icon={SchoolIcon}
        ariaLabel={`Info for Account`}
        role="button"
      ></LxClickAbleIcon>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
        maxWidth='sm'
        fullWidth 
        role='dialog'
      >
        {isDataLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : accountData ? (
          <>
            <div className={styles.dialogHeader}>
              <h3>{accountData.accountName}</h3>
            </div>
            <div className={styles.dialogContent}>
              <div className={styles.infoRow}>
                <strong>Customer Id:</strong>
                <span>{accountData.customerId}</span>
              </div>
              <div className={styles.infoRow}>
                <strong>Country:</strong>
                <span>{accountData.country}</span>
              </div>
              <div className={styles.infoRow}>
                <strong>Primary Contact:</strong>
                <span>{accountData.primaryContactName}</span>
              </div>
              <div className={styles.infoRow}>
                <strong>Primary Contact Email:</strong>
                <span>{accountData.primaryContactEmail}</span>
              </div>
              {accountData.address && (
              <div className={styles.infoRow}>
                <strong>Address:</strong>
                <div>
                  {accountData.address.address_line_1 && (
                    <span>{accountData.address.address_line_1},</span>
                  )}
                  {accountData.address.address_line_2 && (
                    <>
                      <br />
                      <span>{accountData.address.address_line_2},</span>
                    </>
                  )}
                  {accountData.address.address_line_3 && (
                    <>
                      <br />
                      <span>{accountData.address.address_line_3},</span>
                    </>
                  )}
                  {accountData.address.city && (
                    <>
                      <br />
                      <span>{accountData.address.city},</span>
                    </>
                  )}
                  {accountData.address.postcode && (
                    <>
                      <br />
                      <span>{accountData.address.postcode},</span>
                    </>
                  )}
                  {accountData.address.state && (
                    <>
                      <br />
                      <span>{accountData.address.state},</span>
                    </>
                  )}
                  {accountData.address.country && (
                    <>
                      <br />
                      <span>{accountData.address.country}.</span>
                    </>
                  )}
                </div>
              </div>
            )}

            </div>
            <div className={styles.dialogActions}>
              <LxCancelButton label='Close' classNames='lxActionButton,lxActionButtonDefaultSize' onCancel={() => {
                  setShowDialog(false)
                }} />
            </div>
          </>
        ) : null}
      </Dialog>
    </>
  )
}

export default AccountInfo
