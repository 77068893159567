import { useEffect, useRef } from 'react'

type UseFormPersistenceOptions = {
  formId: string // Unique identifier for the form (e.g., 'user', 'account', 'learner')
  entityId?: string // Optional ID for editing existing entities (e.g., user.id, learner.id)
  defaultValues: any // Default values for the form
  formValues: any // Current form values from useForm's watch()
  reset: (data: any) => void // Reset function from useForm
}

/**
 * Custom hook for persisting form data in localStorage
 *
 * @param options Configuration options for form persistence
 * @returns Cleanup function
 */
export const useFormPersistence = (options: UseFormPersistenceOptions) => {
  const { formId, entityId, defaultValues, formValues, reset } = options
  const isFirstRender = useRef(true)
  const storageKey = entityId ? `${entityId}-${formId}EditForm` : `${formId}CreateForm`

  // Get saved form data from localStorage
  const getFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem(storageKey)
    if (savedData) {
      try {
        return JSON.parse(savedData)
      } catch (error) {
        console.error(`Error parsing ${formId} form data from localStorage:`, error)
        return null
      }
    }
    return null
  }

  // Load saved data on initial render
  useEffect(() => {
    let formData = getFormDataFromLocalStorage()

    // If no saved data, use provided entity data or default values
    if (!formData) {
      formData = entityId ? { ...defaultValues } : defaultValues
    }

    // Reset form with the appropriate data
    reset(formData)

    // Cleanup function to clear form data when component is unmounted
    return () => {
      // Optional: Clear localStorage when form is unmounted
      // localStorage.removeItem(storageKey);
    }
  }, [formId, entityId, reset])

  // Save form data when values change (excluding initial render)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    localStorage.setItem(storageKey, JSON.stringify(formValues))
  }, [formValues, storageKey])
}
