import {Admin, CustomRoutes, Resource} from 'react-admin'
import {Route} from 'react-router-dom'
import styles from './MultiPage.module.scss'

import theme from '../styles/Theme.tsx'
// import Profile from './Profile'
import Dashboard from './Dashboard/Dashboard.tsx'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

// component imports
import learners from '@src/pages/Learner/index'
import users from '@src/pages/User/index'
import activities from '@src/pages/Activities/index'
import groupCurriculums from '@src/pages/GroupCurriculums/index'
import analytics from '@src/pages/Analytics/index'
import accounts from '@src/pages/Admin/Accounts/index'
import robots from '@src/pages/Admin/Robots/index'
import subscriptions from '@src/pages/Admin/Subscriptions/index'


import LxLayout from './layout/Layout.tsx'
import {useTokenManagement} from '@logic/contexts/AppStore/useTokenManagement.ts'
import {AppProviders} from '@logic/contexts/AppProviders.tsx'

import {ActivityReportProvider} from '@logic/contexts/Learners/ActivityReport/LearnerActivityReportContext.tsx'
import {LearnerActivityReport} from '@src/pages/Learner/Show/Activities/activityReport/LearnerActivityReport.tsx'

import {AnalyticsReport} from '@src/pages/Analytics/Reports/AnalyticsReport.tsx'
import {AuditLog} from '@src/pages/Analytics/AuditLogs/LogList.tsx'


import {UserShowProvider} from '@logic/contexts/Users/UserShowContext.tsx'

import {AccountSelectionDialog} from './AccountSelection'

import {CircularProgress} from '@mui/material'
import {isNilOrEmpty} from '@src/utils/isNilOrEmpty.ts'
import {UserLearners} from '@src/pages/User/Show/Learners/UserLearners.tsx'


//unused for now
// import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
// import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined'
// import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
// import curriculums from '../components/curriculums/'
// import ContentList from '../components/collections/ContentList'

const Home = () => {
  const {
    dataProvider,
    selectAccountDialogOpen,
    accounts: userAccounts,
    userId,
    handleAccountSelection,
  } = useTokenManagement()

  if (selectAccountDialogOpen) {
    return (
      <AccountSelectionDialog
        selectAccountDialogOpen={selectAccountDialogOpen}
        userAccounts={userAccounts}
        handleAccountSelection={handleAccountSelection}
      />
    )
  }

  if (isNilOrEmpty(dataProvider) || isNilOrEmpty(userId)) {
    return (
      <div className={styles.homePageLoader}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <AppProviders dataProvider={dataProvider} userId={userId}>
      <Admin
        theme={theme}
        // i18nProvider={I18nProvider}
        layout={LxLayout}
        dashboard={Dashboard}
        dataProvider={dataProvider}
      >
        <Resource
          name='Learner'
          list={learners.list}
          show={learners.show}
          create={learners.create}
        />
        <Resource
          name='CurriculumView'
          list={activities.list}
          show={activities.show}
        />
        <Resource
          name='GroupCurriculums'
          list={groupCurriculums.list}
        />
        <Resource
          name='User'
          list={users.list}
          create={users.create}
          edit={users.edit}
          // show={users.show} // TODO that can be probably can be easly removed
          icon={PersonOutlineIcon}
        >
          <Route
            path=':id/show/learners'
            element={
              <UserShowProvider>
                <UserLearners />
              </UserShowProvider>
            }
          />
        </Resource>
        <Resource
          name='Analytics'
          list={analytics.list}
        >
          <Route path=":accountId/report" element={<AnalyticsReport />} />
          <Route path=":accountId/auditLogs" element={<AuditLog />} />
        </Resource>
        <Resource
          name='Accounts'
          list={accounts.list}
          create={accounts.create}
        />
        <Resource
          name='Robots'
          list={robots.list}
        />
        <Resource
          name='Subscriptions'
          list={subscriptions.list}
          create={subscriptions.create}
        />
        <CustomRoutes>
          <Route
            path={'/Learner/:id/show/activity/:activityId/report'}
            element={
              <ActivityReportProvider>
                <LearnerActivityReport />
              </ActivityReportProvider>
            }
          />
          <Route
            path={'/Accounts/combined'}
            element={
                <accounts.combined />
            }
          />
        </CustomRoutes>

        {/* RESOURCES that are no longer needed or temprorary disabled*/}

        {/*<Resource*/}
        {/*  name="Curriculum"*/}
        {/*  {...curriculums}*/}
        {/*  icon={LibraryBooksIcon}*/}
        {/*/>*/}
        {/*<Resource name="Collection" list={ContentList} />*/}
        {/*<CustomRoutes>*/}
        {/*  /!*<Route path="/profile" element={<Profile />} />*!/*/}
        {/*</CustomRoutes>*/}
      </Admin>
    </AppProviders>
  )
}

export default Home
