import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import styles from './GroupCurriculum.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { LxIcon } from '@src/components/icon/Icon'
import { cn } from '@src/utils/cn'
import { PlusOutlineIcon } from '@icons/utils'
import CurriculumEnrollModal from './GroupCurriculumEnroll'
import { useModal } from '@src/logic/contexts/Modal/ModalContext'
import { generateSimpleOptions } from '@src/logic/useFiltering.hook'
import { ActivitiesOutlineIcon, CurriculumIcon, CurriculumIndIcon } from '@src/assets/icons'
import { getSvgComponent } from '@src/assets/icons/skillAreas/svgMapper.util'
import { LxTooltip } from '@src/components/tooltip/tooltip'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'
import { LxHandleKeyDown } from '@src/utils/Accessbility'
import { useEffect, useRef } from 'react'

export const GroupCurriculum = () => {
  useDocumentTitle('Curriculums')
  const { displayData, isLoading } = useActivitiesList()
  const { learnerList, isLoading: isLearnerListLoading } = useLearnersList()
  const { showModal } = useModal()

  // Group curriculums by linkedCategoryName
  const groupedCurriculums = !isNilOrEmpty(displayData)
    ? displayData?.skillAreas
        .filter((skillArea) => skillArea.type === 'curriculum')
        .reduce((acc, curriculum) => {
          const category = curriculum.linkedCategoryName || 'Uncategorized'
          if (!acc[category]) acc[category] = []
          acc[category].push(curriculum)
          return acc
        }, {})
    : {}

  const learnerOptions = generateSimpleOptions(learnerList, 'fullName')


  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the page when navigated
    pageRef.current?.focus()
  }, [])

  return (
    <div className={styles.activitiesContainer} >
      {isLoading || isLearnerListLoading ? (
        <div className={styles.placeholderContainer}>
          <LxLoadingSpinner />
        </div>
      ) : (
        <>
          <div className={styles.curriculumsListHeadingRow} ref={pageRef} tabIndex={-1} aria-label='Curriculums List' style={{ outline: 'none' }}>
            <div className={styles.curriculumsListHeading}>
              <LxIcon sxStyles={{ height: '80px', width: '80px' }} icon={CurriculumIcon} />
              <h3>Curriculums</h3>
            </div>
          </div>

          {Object.entries(groupedCurriculums).map(([category, curriculums]) => (
            <div key={category} className={styles.categoryContainer} aria-labelledby={`category-${category}`}>
              <div className={cn(styles.skillAreaRow)}>
                <LxIcon
                  sxStyles={{ height: '32px', width: '32px' }}
                  customViewBox={'0 0 1320 1320'}
                  icon={getSvgComponent(category.replace('Curriculums - ', ''))}
                />

                <div id={`category-${category}`} className={styles.categoryTitle}>
                  {category} Curriculums
                </div>
              </div>
              {curriculums.map((curriculum) => (
                <div
                  key={curriculum.id}
                  className={styles.curriculumContainer}
                  onClick={() => showModal(<CurriculumEnrollModal curriculum={curriculum} allLearners={learnerOptions} />)}
                  onKeyDown={(event) =>
                    LxHandleKeyDown(event, () => showModal(<CurriculumEnrollModal curriculum={curriculum} allLearners={learnerOptions} />))
                  }
                  role='tab'
                  aria-label={`Enroll to ${curriculum.name}`}
                >
                  <div className={styles.curriculumHeader}>
                    <div className={styles.curriculumContent} aria-label={`Enroll to ${curriculum.name}`} tabIndex={0}>
                      <LxIcon sxStyles={{ height: '24px', width: '24px', marginRight: '8px' }} icon={CurriculumIndIcon} />
                      {curriculum.name.replace('Curriculum - ', '')}
                    </div>
                    <LxTooltip tooltipText={'Enroll'}>
                      <LxIcon sxStyles={{ height: '40px', width: '40px', cursor: 'pointer' }} icon={PlusOutlineIcon} />
                    </LxTooltip>
                  </div>
                </div>
              ))}
            </div>
          ))}
          {isNilOrEmpty(groupedCurriculums) && (
            <div className={styles.placeholderContainer}>
              <h5>No data found, please try changing filters</h5>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default GroupCurriculum
