import React from 'react'
import {
  Dialog
} from '@mui/material'
import { AccountF } from '@logic/contexts/Accounts/AccountsFrontend.type.ts'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'

interface LinkedAccountInfoProps {
  open: boolean
  onClose: () => void
  account: AccountF | null
}

const LinkedAccountInfo: React.FC<LinkedAccountInfoProps> = ({
  open,
  onClose,
  account,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' role='dialog'>
      <div className={styles.dialogHeader}>
        <h3>Linked Accounts - {account?.accountName}</h3>
      </div>

      <div className={styles.dialogContent}>
        <div className={cn(styles.headerRow, styles.linkedAccountsHeader)}>
          <strong>Account Name:</strong>
          <strong>Type:</strong>
        </div>

        {account &&
          account.linkedAccounts?.map((account, idx) => (
            <div className={cn(styles.infoRow, styles.linkedAccountsRow)} key={idx}>
              <span>{account.accountName}</span>
              <span>
                {account.linkedType?.charAt(0).toUpperCase() +
                  account.linkedType?.slice(1)}
              </span>
            </div>
          ))}
      </div>

      <div className={styles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Close</span>
        </div>
      </div>
    </Dialog>
  )
}

export default LinkedAccountInfo
