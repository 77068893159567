import React, { useEffect, useState } from 'react'
import { Dialog, Tooltip } from '@mui/material'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'
import { UserB } from '@src/logic/contexts/Users/UsersBackend.type'
import { useDataProvider } from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { UserF } from '@src/logic/contexts/Users/UsersFrontend.type'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole'
import { mapStateToReadableStatus } from '@src/utils/mapStateToReadableState'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNotify } from 'react-admin'

interface UserInfoProps {
  open: boolean
  onClose: () => void
  account: { id: string; accountName: string } | null
}

const UserInfo: React.FC<UserInfoProps> = ({ open, onClose, account }) => {
  const [userData, setUserData] = useState<UserF[] | null>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const notify = useNotify()

  const dataProvider = useDataProvider()

  useEffect(() => {
    if (open) {
      setIsDataLoading(true)

      dataProvider
        .update('Report', {
          id: account?.id,
          data: {
            filter: {},
            type: 'accounts_users_list',
          },
          previousData: null,
        })
        .then((response) => {
          const reportData = response.data?.report ?? []

          const parsedUserData = reportData.map((user: UserB) => ({
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            primaryEmail: user.primary_email,
            userRole: user.user_role,
            jobTitle: user.job_title || 'N/A',
            state: user.state,
            roles: user.roles,
          }))
          setUserData(parsedUserData)
        })
        .catch(() => setUserData(null))
        .finally(() => setIsDataLoading(false))
    }
  }, [open, account, dataProvider])

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify('Copied ID clipboard - ' + text)
      })
      .catch((err) => {
        notify('Failed to Copy ID clipboard - ' + err)
      })
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth role='dialog'>
        <div className={styles.dialogHeader}>
          <h3>Users of {account?.accountName} {userData?.length && ` (${userData?.length})`}</h3>
        </div>

        <div className={styles.dialogContent}>
          <div className={cn(styles.headerRow, styles.headeUser)}>
            <strong>First Name</strong>
            <strong>Last Name</strong>
            <strong>Email</strong>
            <strong>Role</strong>
            <strong>Job Title</strong>
            <strong>State</strong>  
            <strong>Roles</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(userData) ? (
            <div className={sharedStyles.notFound}>No users assigned to the given account.</div>
          ) : (
            userData?.map((user, idx) => (
              <div className={cn(styles.infoRow, styles.infoUser)} key={idx}>
                <div className={cn(styles.cursorPointer )} >
                  <Tooltip
                    title={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', maxWidth: '300px' }}>
                        <span style={{ wordWrap: 'break-word', flex: 1 }}>{user.id}</span>
                        <ContentCopyIcon
                          onClick={() => handleCopy(user.id)}
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            color: '#4a90e2',
                          }}
                        />
                      </div>
                    }
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    {user.firstName}
                  </Tooltip>
                </div>

                <span className={styles.wrapText}>{user.lastName}</span>
                <span className={styles.wrapText}>{user.primaryEmail}</span>
                <span>{mapRoleToReadableRole(user.userRole)}</span>
                <span>{user.jobTitle}</span>
                <span>{mapStateToReadableStatus(user.state)}</span>
                <span>{user.roles.join(', ')}</span>
              </div>
            ))
          )}
        </div>

        <div className={styles.dialogActions}>
          <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onClose}>
            <span>Close</span>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default UserInfo
 
