import React, { useEffect, useRef, useState } from 'react'
import { useDataProvider, useNotify, useRefresh, usePrevious } from 'react-admin'
import { Dialog } from '@mui/material'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { LxSelect } from '@components/select/select.tsx'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import { stateFormValidation } from '@src/pages/Learner/LearnerEditStatus.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './UserList.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { USER_STATE } from '@src/utils/constants'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'

const UserEditStatus = ({
  userId,
  state,
  userRole,
  userName,
  userList,
}: {
  userId: string
  state: string
  userRole: string
  userName: string
  userList: UserF[]
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal } = useModal()

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
  const [userChoice, setUserChoice] = useState('')
  const [selectedUser, setSelectedUser] = useState('')
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const filteredUserList = userList.filter((user) => user.userRole !== 'assistant')
  const finalUserList = filteredUserList.filter((user) => user.id !== userId)

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: { state },
    resolver: stateFormValidation,
  })

  const prevState = usePrevious(state)

  useFormNotify(errors, submitCount, isValid)

  const clear_all = () => {
    setShowArchiveConfirmation(false)
    reset()
    refresh()
    setIsFormSubmitting(false)
    hideModal()
  }

  const onSubmit = async (formData: { state: 'active' | 'archived' | 'deleted' | 'resend_email' }) => {
    if (isFormSubmitting) {
      return
    }

    if (prevState === formData.state) {
      setError('state', {
        message: 'Please choose a state different from the current status.',
      })
      return
    }

    try {
      if (formData.state === USER_STATE.ARCHIVED && userRole != 'assistant') {
        // hideModal()
        setShowArchiveConfirmation(true)
      } else if (formData.state === 'resend_email') {
        handleResendEmail(userId)
      } else {
        setIsFormSubmitting(true)
        await dataProvider.update('User', {
          id: userId,
          data: {
            update_state: {
              action: 'updateUserState',
              state: formData.state,
              assign_to: null,
            },
          },
          previousData: null,
        })
        hideModal()
        notify('User is updated successfully', { type: 'success' })
        clear_all()
      }
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
      clear_all()
    }
  }

  const handleUserChoiceChange = (event) => {
    setUserChoice(event.target.value)
  }

  const handleSelectedUserChange = (event) => {
    setSelectedUser(event.target.value)
  }

  const handleUserChoiceSubmit = async () => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      if (userChoice === 'assign') {
        await dataProvider.update('User', {
          id: userId,
          data: {
            update_state: {
              action: 'updateUserState',
              state: USER_STATE.ARCHIVED,
              assign_to: selectedUser,
            },
          },
          previousData: null,
        })
        notify('User archived successfully', { type: 'success' })
        // Close the dialog
        clear_all()
      } else if (userChoice === 'unassign') {
        await dataProvider.update('User', {
          id: userId,
          data: {
            update_state: {
              action: 'updateUserState',
              state: USER_STATE.ARCHIVED,
              assign_to: null,
            },
          },
          previousData: null,
        })
        notify('User is updated successfully', { type: 'success' })
      }
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      clear_all()
    }
  }

  // disable backdrop click
  const handleBackdropClick = (_: React.MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick') {
      clear_all()
    }
  }

  const handleResendEmail = async (userId) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('User', {
        id: userId,
        data: { method: 'resendEmail' },
        previousData: null,
      })

      notify('Email invitation sent successfully', { type: 'success' })
    } catch (error: any) {
      notify(error.message || 'Something went wrong, Please try again after sometime', { type: 'warning' })
      setIsFormSubmitting(false)
    } finally {
      clear_all()
    }
  }

  const modalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <>
      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}
      <div className={modalStyles.dialogHeader} tabIndex={-1} ref={modalRef}>
        <h3>Manage Status of {userName}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='state'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LxSelect
                value={value}
                hasError={!!error}
                placeholder={'Select Action'}
                onChange={onChange}
                role='listbox'
                ariaLabel='Select Action'
                options={[
                  ...(state === USER_STATE.INVITED || state === USER_STATE.INVITE_DELIVERED || state === USER_STATE.INVITE_FAILED
                    ? [
                        { value: 'resend_email', label: 'Resend Email' },
                        { value: USER_STATE.ARCHIVE_DELETED, label: 'Delete' },
                        { value: USER_STATE.ARCHIVED, label: 'Archive' },
                      ]
                    : []),
                  ...(state === USER_STATE.ACTIVE ? [{ value: USER_STATE.ARCHIVED, label: 'Archive' }] : []),
                  ...(state === USER_STATE.ARCHIVED ? [{ value: USER_STATE.ACTIVE, label: 'Restore' }] : []),
                ]}
              />
            )}
          />
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <LxCancelButton label='Close' onCancel={clear_all} classNames='lxActionButton,lxActionButtonDefaultSize' />
        <LxSaveButton label='Confirm' classNames='lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize' onSubmit={handleSubmit(onSubmit)} />
      </div>

      {/* Archive confirmation dialog */}

      <Dialog open={showArchiveConfirmation} onClose={handleBackdropClick} role='dialog' >
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div>
        )}

        <div className={modalStyles.dialogHeader}  >
          <h3>Action Required!</h3>
        </div>
        <div className={modalStyles.dialogContent}>
          <p>
            Before you archive this user, please review the educational goals they are currently responsible for. You can either reassign these goals
            to a different user now or choose to leave them unassigned and assign them later.
          </p>
          <br></br>
          <p>Select an option: </p>
          <FormControl component='fieldset' fullWidth>
            <RadioGroup aria-label='userChoice' name='userChoice' value={userChoice} onChange={handleUserChoiceChange}>
              <FormControlLabel value='assign' control={<Radio />} label='Reassign to Another User' />
              <FormControlLabel value='unassign' control={<Radio />} label='Leave Unassigned (I will reassign goals later)' />
            </RadioGroup>
          </FormControl>
          {userChoice === 'assign' ? (
            <FormControl fullWidth required>
              <InputLabel id='selectedUserLabel'>Assign to user</InputLabel>
              <Select labelId='selectedUserLabel' id='selectedUser' value={selectedUser} onChange={handleSelectedUserChange}>
                {finalUserList.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>
        <div className={modalStyles.dialogActions}>
            <LxCancelButton label='Close' onCancel={clear_all} classNames='lxActionButton,lxActionButtonDefaultSize' />
            <LxSaveButton label='Confirm' classNames='lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize' onSubmit={handleUserChoiceSubmit} />
        </div>
      </Dialog>
    </>
  )
}

export default UserEditStatus
