import modalStyles from '@components/modal/modal.module.scss'
import { useDataProvider, useNotify, useRefresh, usePrevious } from 'react-admin'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { LxSelect } from '@components/select/select.tsx'
import { cn } from '@src/utils/cn.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './Activities.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { useUserContext } from '@src/logic/contexts/AppStore/UserContext'
import { useEffect, useRef } from 'react'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'
import { useFormNotify } from '@src/components/formNotify/useFormNotify'

export const stateFormValidation = yupResolver(
  yup
    .object()
    .shape({
      state: yup.string().required('State is required'),
    })
    .required()
)

export const LearnerEditActivityStatus = ({ unit, goalId }: { unit: UnitF; goalId: string }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()
  const { currentUser } = useUserContext() as any
  const prevState = usePrevious(unit.state)

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: { state: unit.state },
    resolver: stateFormValidation,
  })

  const onClose = () => {
    hideModal()
  }

  useFormNotify(errors, submitCount, isValid)

  const onSubmit = async (formState: { state: string }) => {
    if (isFormSubmitting) {
      return
    }

    if (prevState === formState.state) {
      setError('state', {
        message: 'Please choose a state different from the current state.',
      })
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('Goal', {
        id: goalId,
        data: {
          units: [
            {
              state: formState.state,
              id: unit.id,
              title: '',
              sort_order: '',
            },
          ],
          action: 'update_unit_status',
        },
        previousData: null,
      })

      notify('Activity status updated', { type: 'success' })
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
    } finally {
      setIsFormSubmitting(false)
      hideModal()
      refresh()
    }
  }

  // Determine options based on currentUser.isParent
  const statusOptions = !currentUser?.isParent
    ? [
        { value: 'mastered', label: 'Mastered', className: (unit.status === 'mastered' || unit.status === 'generalized') && 'disabled' },
        { value: 'generalized', label: 'Generalized', className: unit.status === 'generalized' && 'disabled' },
        { value: 'on_hold', label: 'On Hold', className: unit.state === 'on_hold' && 'disabled' },
        { value: 'active', label: 'Active', className: unit.state === 'active' && 'disabled' },
      ]
    : [
        { value: 'mastered', label: 'Mastered', className: (unit.status === 'mastered' || unit.status === 'generalized') && 'disabled' },
        { value: 'on_hold', label: 'On Hold', className: unit.state === 'on_hold' && 'disabled' },
        { value: 'active', label: 'Active', className: unit.state === 'active' && 'disabled' },
      ]

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <div tabIndex={-1} ref={modalRef} role='dialog'>
      <div className={modalStyles.modalMd}>
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div>
        )}

        <div className={modalStyles.dialogHeader}>
          <h3>Manage Status of {unit.unitTitle}</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={modalStyles.dialogContent}>
          <Controller
            name='state'
            control={control}
            render={({ field, fieldState }) => (
              <>
                <LxSelect
                  value={field.value}
                  hasError={!!fieldState.error}
                  placeholder={'Select Learner State'}
                  onChange={field.onChange}
                  options={statusOptions}
                />
              </>
            )}
          />
        </form>
        <div className={modalStyles.dialogActions}>
          <LxCancelButton label='Close' onCancel={onClose} classNames='lxActionButton,lxActionButtonDefaultSize' />
          <LxSaveButton
            label='Confirm'
            classNames='lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize'
            onSubmit={handleSubmit(onSubmit)}
            isFormSubmitting={isFormSubmitting}
          />
        </div>
      </div>
    </div>
  )
}
