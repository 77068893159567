import React, { FC, useEffect, useMemo, useState } from 'react'
import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { useNavigate } from 'react-router-dom'
import styles from './Activities.module.scss'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { SkillAreaHeader } from '@src/pages/Activities/components/SkillAreaHeader.tsx'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { cn } from '@src/utils/cn.ts'
import { ArrowDownIcon, ArrowUpIcon, EditIcon, InfoOutlineIcon } from '@icons/utils'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ActivityPurpleIcon, GraphIcon } from '@icons/index.ts'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import sharedStyles from '../common.module.scss'
import { mapStatusToReadableStatus, TaskStatus } from '@src/utils/mapStatusToReadableStatus.ts'
import { formatDate } from '@src/utils/dateFormatter.ts'
import { parsePriority } from '@src/utils/mapPriorityToReadablePriority.ts'
import { ActivityArea, ActivityGoal } from '@src/pages/Learner/Show/Activities/Activities.factory.ts'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LearnerEditActivityStatus } from '@src/pages/Learner/Show/Activities/LearnerEditActivityStatus.tsx'
import ActivitiesShowModal from '@src/pages/GroupCurriculums/ActivitiesShowModal'

type GoalProps = {
  goal: ActivityGoal
  expandAll: boolean;
  index: number
}

const statusOrder = ['emerging', 'in_progress', 'not_introduced', 'mastered', 'generalized']

const sortUnitsByStatus = (units: UnitF[]) => {
  return units.sort((a, b) => {
    const statusA = a.status as TaskStatus
    const statusB = b.status as TaskStatus
    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
  })
}


const LearnerActivitiesGoal: FC<GoalProps> = ({ goal, expandAll, index }) => {
  const navigate = useNavigate()

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()
  const { showModal } = useModal()

  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [currentUnitId, setCurrentUnitId] = useState<string | null>(null)

  const [ isOpen, setIsOpen ] = useState(false)

  const can_edit_activity = canPerformAction(
    currentUser,
    { type: 'Learner' },
    'edit_activity'
  )

  useEffect(() => {
    setIsOpen(expandAll)
  }, [ expandAll ])

  const sortedUnits = useMemo(() => sortUnitsByStatus(goal.units), [goal.units])


  const openActivityModal = (unitId: string) => {
    setCurrentUnitId(unitId)
    setIsActivityModalOpen(true)
  }

  const closeActivityModal = () => {
    setCurrentUnitId(null)
    setIsActivityModalOpen(false)
  }


  return (
    <>
      <div className={styles.goalTable}>
        <h5 onClick={() => setIsOpen(!isOpen)} className={styles.goalHeader}>
          Goal: {goal.name}
        </h5>
        <div
          className={cn(styles.goalHeader)}> {mapStatusToReadableStatus(goal.state === 'on_hold' ? 'on_hold' : goal.status as TaskStatus)} </div>
        <div className={styles.goalHeader}> {parsePriority(goal.priority)} </div>
        <div className={styles.goalHeader}>
        </div>
        <div className={styles.goalHeader}>
        </div>
        <div className={styles.goalHeader}>
          <LxTooltip tooltipText={`${isOpen ? 'Collapse' : 'Expand'} Goal`}>
            <LxClickAbleIcon
              className={cn(styles.arrowDownUp)}
              icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
              customViewBox={'0 0 960 560'}
              onClick={() => setIsOpen((prev) => !prev)}
              role='button'
              ariaLabel='Expand - Collapse Goal'
            ></LxClickAbleIcon>
          </LxTooltip>
        </div>
        {isOpen &&
          !isNilOrEmpty(sortedUnits) && sortedUnits.map((unit: UnitF, index) => (
            <React.Fragment key={`${unit.unitTitle}-${index}`}>
              <div>
                <LxIcon className={sharedStyles.svgIcon} icon={ActivityPurpleIcon}></LxIcon>
              </div>
              <div>{unit.unitTitle}</div>
              <div> {mapStatusToReadableStatus(unit.state === 'on_hold' ? 'on_hold' : unit.status as TaskStatus)}</div>
              {unit.lastScore && unit.lastScore !== '0' || unit.lastPracticed ?
                <div> {isNaN(parseFloat(unit.lastScore)) ? (
                  <div></div> // Display an empty value if not parseable
                ) : (
                  <div>
                    {parseFloat(unit.lastScore).toFixed(2)}% on {formatDate(unit.lastPracticed, false)}
                  </div>
                )}</div> : <div></div>}
              <LxTooltip tooltipText={`Activity Report`}>
                <LxClickAbleIcon
                  className={sharedStyles.svgIcon}
                  icon={GraphIcon} customViewBox={'0 0 38 39'}
                  onClick={() => navigate(`${unit.id}/report?goal=${goal.goalId}&unitTitle=${unit.unitTitle}`)}
                  role='link'
                  ariaLabel='Activity Report'
                ></LxClickAbleIcon>
              </LxTooltip>
              <LxTooltip tooltipText={`Read more`}>
                <LxClickAbleIcon
                  className={sharedStyles.svgIcon}
                  icon={InfoOutlineIcon}
                  onClick={() => openActivityModal(unit.id)}
                  role='button'
                  ariaLabel='Activity Read more'
                ></LxClickAbleIcon>
              </LxTooltip>
              {can_edit_activity ? (
                <LxTooltip tooltipText={`Edit status`}>
                  <LxClickAbleIcon
                    onClick={() => showModal(<LearnerEditActivityStatus unit={unit} goalId={goal.goalId.toString()}/>)}
                    icon={EditIcon}
                    role='button'
                    ariaLabel='Edit Activity Status'
                  />

                </LxTooltip>
              ) : <div></div>}
            </React.Fragment>
          ))}
      </div>

      <ActivitiesShowModal isOpen={isActivityModalOpen} onClose={closeActivityModal} unitId={currentUnitId ?? ''} />
    </>
  )
}


type Props = {
  data: ActivityArea
  expandAll: boolean
  index: number
}

export const LearnerActivitiesSkillArea: FC<Props> = ({ data, expandAll, index }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  useEffect(() => {
    setIsOpen(expandAll)
  }, [ expandAll ])

  return (
    <>
      <SkillAreaHeader
        categoryName={data.name}
        setIsOpen={setIsOpen}
        className={styles.skillAreaHeader}
      />
      {data.goals.map((goal: ActivityGoal, index: number) => <React.Fragment
        key={`${index}-learner-activities-goal-${goal.name}`}>
        <LearnerActivitiesGoal goal={goal} expandAll={isOpen} index={index}/>
      </React.Fragment>)}
    </>
  )
}
