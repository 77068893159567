import styles from './ProgressReport.module.scss'
import '@styles/common.scss'
import 'chart.js/auto'

import {
  ProgressReportProvider,
  useProgressReport,
} from '@logic/contexts/Learners/ProgressReport/ProgressReportContext.tsx'
import { cn } from '@src/utils/cn.ts'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { SkillArea } from '@src/pages/Learner/Show/Report/SkillArea.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { GeneratePdfReport } from '@src/pages/Learner/Show/Report/GeneratePdfReport.tsx'
import ReportTopicSelector from '@src/pages/Learner/Show/Report/ProgressReportFilterBar.tsx'
import { LxSelect } from '@components/select/select.tsx'
import { sanitizeDate } from '@logic/contexts/Analytics/Report/AnalyticsReport.factory.tsx'
import usePrevious from '@src/hooks/usePrevious.hook.ts'
import { UsageReport } from './UsageReport'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

const Report = () => {
  useDocumentTitle('Learner Report')
  const {
    displayData,
    usageReportData,
    isReportFetching,
    duration,
    handleDurationChange,
    handleGenerateReportClick,
    isPristine,
    resetFilters,
  } = useProgressReport()

  const contentRef = useRef<HTMLDivElement>(null)
  const prevDuration = usePrevious(duration)

  const currentDate = new Date()
  const startDate = new Date()
  startDate.setDate(currentDate.getDate() - duration)

  useEffect(() => {
    if (prevDuration !== duration) {
      handleGenerateReportClick()
      resetFilters()
    }
  }, [prevDuration, duration])

  return (
    <>
      {/* <div className={styles.reportConfigTitle}>
        <h3>
          <center>Usage and Success Report</center>
        </h3>
      </div> */}

      <div className={styles.reportActions}>
        <div className={cn('ignoreOnPrint')}>Show report for:</div>
        <div className={cn('ignoreOnPrint')} >
          <LxSelect
            className={styles.customSelect}
            value={duration}
            onChange={handleDurationChange}
            options={[
              { value: '30', label: 'Last Month' },
              { value: '90', label: 'Last 3 Months' },
              { value: '365', label: 'Last Year' },
            ]}
            role="combobox"
            ariaLabel="Select duration"
          />
        </div>
        <div className={styles.datePeriod}>
          From <b> {sanitizeDate(startDate)} </b>
          to <b> {sanitizeDate(currentDate)} </b>
        </div>

        <div>
          <GeneratePdfReport scraperRef={contentRef}></GeneratePdfReport>
        </div>
      </div>

      <div className={styles.reportContent} ref={contentRef}>
        {isReportFetching ? (
          <LxLoadingSpinner className={styles.placeholderContainer} />
        ) : (
          <>
            {isNilOrEmpty(usageReportData) && (
              <div className={cn(styles.placeholderContainer,'ignoreOnPrint')}>
                <h5>
                  No data found for Usage Metrics, please try to change filter
                  parameters
                </h5>
              </div>
            )}
            {!isNilOrEmpty(usageReportData) && (
              <UsageReport reportData={usageReportData} duration={duration} />
            )}
            {!isPristine && (
              <>
                <div className={styles.progressReportTitle}>
                  <h3>Progress Metrics</h3>
                </div>
                {!isNilOrEmpty(displayData?.report) ? (
                  <>
                    <ReportTopicSelector />
                    {displayData.report.map((item, index) => (
                      <React.Fragment key={`skill-area-report-${index}`}>
                        <SkillArea reportItem={item} />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <div className={cn(styles.placeholderContainer,'ignoreOnPrint')}>
                    <h5>
                      No data found for Progress Metrics, please try to change
                      filter parameters
                    </h5>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export const ProgressReport = () => {
  return (
    <ProgressReportProvider>
      <Report />
    </ProgressReportProvider>
  )
}

export default ProgressReport
