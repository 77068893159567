import * as React from 'react'
import styles from './Layout.module.scss'
import LxMenu from './Menu.tsx'
import LxNavbar from './Navbar.tsx'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { useLayout } from '@logic/contexts/LayoutContext.tsx'
import { cn } from '@src/utils/cn.ts'
import { ModalProvider } from '@logic/contexts/Modal/ModalContext.tsx'

const LxLayout = ({ children }) => {
  const { currentUser, isUserLoading } = useUserContext() as any
  const { shouldPreventResponsiveness } = useLayout()

  return (
    <ModalProvider>
      <div className={styles.LayoutContainer}>
        <LxMenu mobileVersion={false} />
        <div className={styles.LayoutContentContainer}>
          <LxNavbar></LxNavbar>
          <div className={cn(styles.LayoutContent, { [styles.isMediaQueryHook]: !shouldPreventResponsiveness })}>
            {isUserLoading || isNilOrEmpty(currentUser)
              ? <LxLoadingSpinner></LxLoadingSpinner>
              : <div>{children}</div>}
          </div>
        </div>
      </div>
    </ModalProvider>
  )
}

export default LxLayout
