import { ReactNode, useRef, useState, useEffect } from 'react'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { cn } from '@src/utils/cn.ts'
import styles from './select.module.scss'
import { useCheckVisibility } from '@src/hooks/useCheckVisibility.ts'

interface DropdownContainerProps<T> {
  options: FilterObject<T>[]
  onOptionClick: (option: FilterObject<T>) => void
  className?: string
  isLastChild?: boolean
  optionRenderer?: (option: FilterObject<T>) => ReactNode // Optional custom renderer
}

export const LxDropdown = <T,>({ options, onOptionClick, isLastChild, className, optionRenderer }: DropdownContainerProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  if (isLastChild) {
    useCheckVisibility(dropdownRef, styles.dropdownTopOriented)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        } else if (selectedIndex >= 0) {
          onOptionClick(options[selectedIndex])
        }
        break
      case 'ArrowDown':
        event.preventDefault()
        setIsOpen(true)
        setSelectedIndex((prev) => (prev === options.length - 1 ? 0 : prev + 1))
        break
      case 'ArrowUp':
        event.preventDefault()
        setIsOpen(true)
        setSelectedIndex((prev) => (prev <= 0 ? options.length - 1 : prev - 1))
        break
      case 'Escape':
        setIsOpen(false)
        setSelectedIndex(-1)
        break
    }
  }

  useEffect(() => {
    // Focus the modal when opens
    dropdownRef.current?.focus()
  }, [])

  return (
    <div
      ref={dropdownRef}
      className={cn(styles.dropdownContainer, className)}
      role='listbox'
      aria-expanded={isOpen}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {options.map((option: FilterObject<T>, index) => (
        <div
          key={option.label || index}
          role='listitem'
          aria-selected={selectedIndex === index}
          className={cn(styles.dropdownItem, option.className, {
            [styles.KeyBoardSelected]: selectedIndex === index,
          })}
          onClick={() => onOptionClick(option)}
        >
          {optionRenderer ? optionRenderer(option) : option.label}
        </div>
      ))}
    </div>
  )
}
