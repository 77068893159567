import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { useUserShow } from '@logic/contexts/Users/UserShowContext.tsx'
import { parseUserFormToBackend, UserForm, UserFormType } from '@src/pages/User/Show/CreateOrEdit/UserForm.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

export const UserEdit = () => {
  const { user, isLoading } = useUserShow()
  useDocumentTitle('Edit ' + user?.fullName)
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();


  const onSubmit = async (formData: UserFormType) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      await dataProvider.update('User', {
        id: formData.id,
        data: parseUserFormToBackend(formData),
        previousData: null,
      })

      // // Handle success case
      notify('User updated successfully', { type: 'success' })
      localStorage.removeItem(`${user?.id}-userEditForm`)
      backToLearners()
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' }) 
    } finally {
      setIsFormSubmitting(false);
    }
  }

  const backToLearners = () => {
    localStorage.removeItem(`${user?.id}-userEditForm`)
    navigate('/User');  // this will navigate to the previous page
  };


  return (
    <div>
      {isLoading
        ? <LxLoadingSpinner/>
        :
        <>
          <div>
            <h3>Edit {user?.fullName}</h3>
          </div>
          <UserForm
            user={user}
            isEdit={true}
            onSubmit={onSubmit}
            onCancel={backToLearners}
            isSubmitting={isFormSubmitting}
          ></UserForm>
        </>
      }
    </div>
  )
}
