import React, { useState } from 'react'
import { UnitF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import styles from './common.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ActivityPurpleIcon } from '@icons/index.ts'
import { InfoOutlineIcon } from '@icons/utils'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import ActivitiesShowModal from '@src/pages/GroupCurriculums/ActivitiesShowModal'
import { LxClickAbleIcon } from '@src/components/icon/clickAbleIcon'

interface Props {
  unit: UnitF
}

export const ActivitiesUnit: React.FC<Props> = ({ unit }) => {
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [currentUnitId, setCurrentUnitId] = useState<string | null>(null)

  const openActivityModal = (unitId: string) => {
    setCurrentUnitId(unitId)
    setIsActivityModalOpen(true)
  }

  const closeActivityModal = () => {
    setCurrentUnitId(null)
    setIsActivityModalOpen(false)
  }

  return (
    <>
      <span className={styles.unitArea}>
        <span>
          <LxIcon icon={ActivityPurpleIcon}></LxIcon>
          {unit.title}
        </span>
        <LxTooltip tooltipText={`Read more`}>
          <LxClickAbleIcon
            icon={InfoOutlineIcon}
            onClick={() => openActivityModal(unit.unitId)}
            role='button'
            ariaLabel='Activity Read more'
          ></LxClickAbleIcon>
        </LxTooltip>
      </span>
      <ActivitiesShowModal isOpen={isActivityModalOpen} onClose={closeActivityModal} unitId={currentUnitId ?? ''} />
    </>
  )
}
