import { Controller, useForm } from 'react-hook-form'
import React, { FC, useEffect, useMemo } from 'react'
import styles from './SubscriptionForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelect } from '@components/select/select.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import * as yup from 'yup'
import { SubscriptionF } from '@src/logic/contexts/Subscriptions/SubscriptionsFrontend.type'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { LxSelectInput } from '@src/components/select/selectInput'
dayjs.extend(utc)

// Updated SubscriptionFormType with specified field names
export type SubscriptionFormType = {
  typeId: string
  startDate: string
  endDate: string
  accountId: string
  noOfPurchasedLicenses: number
}

// Default values for the form
export const defaultSubscriptionFormValues = (): SubscriptionFormType => {
  const today = dayjs().format('YYYY-MM-DD')
  const oneYearLater = dayjs().add(1, 'year').format('YYYY-MM-DD')

  return {
    typeId: '',
    startDate: today,
    endDate: oneYearLater,
    accountId: '',
    noOfPurchasedLicenses: 1,
  }
}

// Validation schema for the form
export const SubscriptionFormValidation = yupResolver(
  yup
    .object()
    .shape({
      typeId: yup.string().required('Subscription Type is required'),
      startDate: yup.date().required('Start date is required'),
      endDate: yup.date().required('End date is required').min(yup.ref('startDate'), 'End date must be after start date'),
      accountId: yup.string().required('Account is required'),
      noOfPurchasedLicenses: yup.number().required('No of License is required'),
    })
    .required()
)

// Function to parse form data to backend format, This will be called by SubscriptionCreate 
export const parseSubscriptionFormToBackend = (formData: SubscriptionFormType) => ({
  type_id: formData.typeId,
  customer_acc_id: formData.accountId,
  start: formData.startDate,
  end: formData.endDate,
  no_of_purchased_licenses: formData.noOfPurchasedLicenses,
})

// Component props
type Props = {
  onSubmit: (formData: SubscriptionFormType) => void
  isSubmitting: boolean
  onCancel: () => void
  subscription?: SubscriptionF | null
  isEdit?: boolean
  accountList?: AccountF
  isAccountsLoading?: boolean
}

const generateCustomOptions = (accounts?: AccountF[]) => {
  if (!accounts) return []
  return accounts.map((account) => ({
    label: `${account.accountName} (${account.customerId})`,
    value: account.id,
  }))
}

export const SubscriptionForm: FC<Props> = ({ onSubmit, subscription, isEdit, onCancel, accountList, isAccountsLoading, isSubmitting = false }) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: defaultSubscriptionFormValues(),
    resolver: SubscriptionFormValidation,
  })

  const startDateWatch = watch('startDate')

  const accountOptions = useMemo(() => generateCustomOptions(accountList), [accountList, isAccountsLoading])

  useFormNotify(errors, submitCount, isValid)

  useEffect(() => {
    // Reset the form when defaultValues change (for Edit scenario)
    if (subscription) {
      reset({
        ...subscription,
        // Ensure local date format
        startDate: dayjs(subscription.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(subscription.endDate).format('YYYY-MM-DD'),
      })
    } else {
      reset(defaultSubscriptionFormValues())
    }
  }, [subscription, reset])

  const formatSelectedDate = (date: dayjs.Dayjs | null) => {
    if (!date) return null
    // Create a new UTC date at midnight for the selected date
    return dayjs(date.format('YYYY-MM-DD')).format('YYYY-MM-DD')
  }

  if (isAccountsLoading) {
    return <LxLoadingSpinner />
  }

  return (
    <form className={styles.subscriptionForm} onSubmit={handleSubmit(onSubmit)}>
      {isSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <Controller
        name='typeId'
        control={control}
        render={({ field, fieldState }) => (
          <LxSelect
            value={field.value}
            hasError={!!fieldState.error}
            shrinked
            placeholder={'Select Subscription Type'}
            onChange={field.onChange}
            options={[
              { value: 'QTASE1', label: 'QTrobot Autism Enterprise (QTASE1)' },
              { value: 'QTAH1', label: 'QTrobot Autism Home (QTAH1)' },
              { value: 'QTASP1', label: 'QTrobot Autism Professional (QTASP1)' },
            ]}
          />
        )}
      />

      <Controller
        name='accountId'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LxSelectInput
            className={styles.timezoneSelect}
            value={value}
            hasError={!!error}
            onChange={onChange}
            options={accountOptions}
            placeholder={'Select Account'}
            onReset={null}
          />
        )}
      />

      {/* <Controller
        name='accountId'
        control={control}
        render={({ field, fieldState }) => (
          <LxSelect
            value={field.value}
            hasError={!!fieldState.error}
            shrinked
            placeholder={'Select Account'}
            onChange={field.onChange}
            options={accountOptions}
          />
        )}
      /> */}

      <Controller
        name='startDate'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format='MMM DD, YYYY'
              className={cn(styles.datePicker, { [styles.formFieldRequired]: !!error })}
              onChange={(newValue) => onChange(formatSelectedDate(newValue))}
              value={value ? dayjs(value) : null}
              timezone='UTC'
            />
          </LocalizationProvider>
        )}
      />

      <Controller
        name='endDate'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format='MMM DD, YYYY'
              minDate={dayjs(startDateWatch).add(1, 'day')}
              className={cn(styles.datePicker, { [styles.formFieldRequired]: !!error })}
              onChange={(newValue) => onChange(formatSelectedDate(newValue))}
              value={value ? dayjs(value) : null}
              timezone='UTC'
            />
          </LocalizationProvider>
        )}
      />

      <Controller
        name='noOfPurchasedLicenses'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type='number'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Number of Licenses'
            />
          </div>
        )}
      />

      <div className={styles.formActions}>
        <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onCancel}>
          <span>Cancel</span>
        </div>
        <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')} onClick={handleSubmit(onSubmit)}>
          <span>{isEdit ? 'Save Changes' : 'Create Subscription'}</span>
        </div>
      </div>
    </form>
  )
}
