import React, { FC } from 'react'
import styles from './toggleButton.module.scss'
import { cn } from '@src/utils/cn.ts' // Path to your cn function
import { LxHandleKeyDown } from '@src/utils/Accessbility'

type Props<T> = {
  value: T
  onChange: (value: T) => void
  ariaLabel: string
}
export const ToggleButton: FC = <T,>({ value, onChange, ariaLabel }: Props<T>) => {
  
  return (
    <div
      className={styles.toggleContainer}
      onClick={() => onChange(!value)}
      onKeyDown={(event) => LxHandleKeyDown(event, () => onChange(!value))}
      tabIndex={0}
      role='checkbox'
      aria-checked={!!value}
      aria-label={ariaLabel}
      aria-live='polite'
    >
      <div className={cn(styles.toggleSwitch, { [styles.checked]: !!value })} ></div>
    </div>
  )
}
