import React, { useState, useEffect } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material'
import styles from './CombinedForm.module.scss'
import { LxIcon } from '@src/components/icon/Icon'
import { ActivitiesIcon } from '@src/assets/icons'
import { AccountForm, AccountFormType, parseAccountFormToBackend } from './AccountCreate'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext'
import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import { SubscriptionForm, SubscriptionFormType, parseSubscriptionFormToBackend } from './SubscriptionCreate'
import AssignRobot from './AssignRobot'
import { useConfirmNavigation } from '@src/hooks/useConfirmNavigation'

const steps = ['Create Account', 'Create Subscription', 'Assign Robot to Account']

const CombinedAccCreation: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()
  const [accountId, setAccountId] = useState<string | null>(null)
  const [subType, setSubType] = useState<string | null>(null)

  const handleAccFormSubmit = async (formData: AccountFormType) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)
    try {
      const response = await dataProvider.create('Account', {
        data: parseAccountFormToBackend(formData),
      })

      setAccountId(response.data.id)

      notify('Account created successfully', { type: 'success' })
      setActiveStep((prevStep) => prevStep + 1)
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false)
    }
  }

  const handleSubscFormSubmit = async (formData: SubscriptionFormType) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.create('Subscription', {
        data: parseSubscriptionFormToBackend(formData),
      })

      notify('Subscription created successfully', { type: 'success' })
      setSubType(formData.typeId)
      setActiveStep((prevStep) => prevStep + 1)
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false)
    }
  }

  const handleAssignRobotSubmit = async (formData) => {
    if (isFormSubmitting) {
      return
    }
    setIsFormSubmitting(true)

    try {
      const { accountId, robotId } = formData

      if (!accountId) {
        notify('Robot or Account not selected', { type: 'error' })
        return
      }

      const robotData = {
        id: robotId,
        accountId: accountId,
        method: 'assignRobot',
      }
      // for adminApp, we will put method as create and customize the call in DataProvider.tsx
      await dataProvider.create('Robot', { data: robotData })

      notify('Robot assigned successfully', { type: 'success' })

      // Assign License to Robot
      const licenseData = {
        id: robotId,
        customer_acc_id: accountId,
        subscription_type_id: subType,
        method: 'assignLicenseToRobot',
      }

      await dataProvider.create('Subscription', { data: licenseData })
      notify('License assignt to robot successfully', { type: 'success' })
      setActiveStep((prevStep) => prevStep + 1)
      notify('All steps are completed successfully', { type: 'success' })
      navigate('/Accounts')
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong. Please try again later.'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false)
    }
  }

  useEffect(() => {
    // Prevent page navigation while not on the last step and progress has been made
    const preventNavigation = (e: BeforeUnloadEvent) => {
      if ( activeStep < steps.length - 1) {
        e.preventDefault()
        // Modern browsers require setting returnValue to prevent navigation
        // @ts-ignore: Deprecated but required for compatibility
        e.returnValue = ''
      }
    }

    // Add the event listener
    window.addEventListener('beforeunload', preventNavigation)
    return () => window.removeEventListener('beforeunload', preventNavigation)
  }, [activeStep])

  const { confirmNavigation, confirm, cancel, message } = useConfirmNavigation(
    activeStep < steps.length - 1,
    'You have unsaved changes. Are you sure you want to leave this page?, \n\n You may continue rest of the functions from Accounts, Subscriptions, Robots pages'
  )

  const backToAccounts = () => {
    setActiveStep(0)
    setAccountId(null)
    navigate('/Accounts')
  }

  const renderStepContent = (step: number) => {
    const stepComponents = [
      <AccountForm onSubmit={handleAccFormSubmit} onCancel={backToAccounts} />,
      <SubscriptionForm onSubmit={handleSubscFormSubmit} accountId={accountId} />,
      <AssignRobot onSubmit={handleAssignRobotSubmit} accountId={accountId} onCancel={backToAccounts} />,
    ]

    return stepComponents[step] || <div>Unknown step</div>
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Dialog open={confirmNavigation} role='dialog'>
        <DialogTitle>Confirmation Required</DialogTitle>
        <DialogContent>
          <DialogContentText >{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={confirm} color='secondary'>
            Leave
          </Button>
        </DialogActions>
      </Dialog>

      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <div>
        <div className={styles.ProfileHeader}>
          <LxIcon icon={ActivitiesIcon} sxStyles={{ height: '50px', width: '50px' }} />
          <h3>Combined Account Creation</h3>
        </div>
      </div>

      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {activeStep === steps.length ? (
          <Typography variant='h6' align='center'>
            All steps completed - process finished!
          </Typography>
        ) : (
          renderStepContent(activeStep)
        )}
      </Box>
    </Box>
  )
}

export default CombinedAccCreation
