import '@styles/common.scss'
import { cn } from '@src/utils/cn.ts'
import { LxHandleKeyDown } from './Accessbility';

export const PrintToPdf = () => {
  const handleExportToPdf = () => {
    window.print();
  };

  return (
    <>
      <div className={cn('lxActionButton ignoreOnPrint ')} onClick={handleExportToPdf} tabIndex={0} onKeyDown={(event) => LxHandleKeyDown(event, handleExportToPdf)} role='button'>Export to PDF</div>
    </>
  )
}
