import { buildQuery } from 'ra-data-graphql-simple'
import omitDeep from 'omit-deep'
import { gql } from 'graphql-tag'

export const myBuildQuery = (introspection: any) => (fetchType: string, resource: string, params: any) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params)

  // react admin not allowing us to send custom queries, So we are modifying the query here
  
  if (fetchType === 'UPDATE') {
    if (resource === 'User' && params.data.method === 'resendEmail') {
      const { id } = params

      return {
        ...builtQuery,
        query: gql`
          query resendUserEmail($id: ID!) {
            resendUserEmail(id: $id) {
              id
            }
          }
        `,
        variables: { id },
        parseResponse: (response: any) => ({
          data: response.data?.resendUserEmail,
        }),
      }

    } else if (resource === 'AuditLog' && params.data.method === 'exportLogs') {
      const { id } = params

      return {
        ...builtQuery,
        query: gql`
          query exportAuditLog($id: ID!) {
            exportAuditLog(id: $id) {
              id, 
              url,
              status
            }
          }
        `,
        variables: { id },
        parseResponse: (response: any) => ({
          data: response.data?.exportAuditLog,
        }),
      }

    } else {

      return {
        ...builtQuery,
        // avoid errors due to __typename being included in embedded schema
        // see https://github.com/marmelab/react-admin/issues/2840
        variables: omitDeep(builtQuery.variables, '__typename'),
      }

    }
  }

  if (resource === 'Goal' && fetchType === 'GET_ONE' && params.method == 'createCurriculumGoals') {
    const { id, curriculumId } = params
    return {
      ...builtQuery,
      query: gql`
        mutation createCurriculumGoals($learner_id: String!, $curriculum_id: String!) {
          data: createCurriculumGoals(learner_id: $learner_id, curriculum_id: $curriculum_id)
        }
      `,
      variables: { learner_id: id, curriculum_id: curriculumId },
      parseResponse: (response: any) => ({
        data: { id: response.data.data },
      }),
    }
  }

  if (resource === 'Robot' && fetchType === 'CREATE' && params.data.method === 'assignRobot') {
    const { id, accountId } = params.data
    return {
      ...builtQuery,
      query: gql`
        mutation AssignRobot($robot_id: String!, $customer_acc_id: String!) {
          data: assignRobot(robot_id: $robot_id, customer_acc_id: $customer_acc_id) {
            id
          }
        }
      `,
      variables: { robot_id: id, customer_acc_id: accountId },
      parseResponse: (response: any) => ({
        data: { id: response.data.data },
      }),
    }
  }

  if (resource === 'Learner' && fetchType === 'GET_ONE' && params.method == 'getSkillMap') {
    const { id } = params

    return {
      ...builtQuery,
      query: gql`
        query getSkillMap($id: ID!) {
          learnerSkillMap(id: $id) {
            id
            skill_map
          }
        }
      `,
      variables: { id },
      parseResponse: (response: any) => ({
        data: response.data?.learnerSkillMap,
      }),
    }
  }

  if (resource === 'Subscription' && fetchType === 'CREATE' && params.data.method === 'assignLicenseToRobot') {
    const { id, customer_acc_id, subscription_type_id } = params.data
    return {
      ...builtQuery,
      query: gql`
        mutation assignLicenseToRobot($subscription_type_id: String!, $robot_id: String, $customer_acc_id: String) {
          data: assignLicenseToRobot(subscription_type_id: $subscription_type_id, robot_id: $robot_id, customer_acc_id: $customer_acc_id) {
            id
          }
        }
      `,
      variables: { robot_id: id, customer_acc_id: customer_acc_id, subscription_type_id: subscription_type_id },
      parseResponse: (response: any) => ({
        data: { id: response.data.data },
      }),
    }
  }

  if (resource === 'Robot' && fetchType === 'GET_LIST' && params.meta?.method === 'getOnlineRobots') {
    return {
      ...builtQuery,
      query: gql`
        query qtOnlineRobots {
          data: qtOnlineRobots {
            id
          }
        }
      `,
      variables: {},
      parseResponse: (response) => ({
        data: response.data.data.map((robot) => ({ id: robot.id })),
        total: response.data.data.length,
      }),
    }
  }

  return builtQuery
}
