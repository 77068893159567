

export const SITE_TITLE = 'LuxAI ADMIN App'

export const USER_STATE = {
  PERMANENT_DELETED: 'permanent_deleted',
  ARCHIVE_DELETED: 'deleted',
  ARCHIVED: 'archived',
  ACTIVE: 'active',
  ON_HOLD: 'on_hold',
  EXCLUDED: 'excluded',
  INVITED: 'invited',
  INVITE_FAILED: 'invite_failed',
  INVITE_DELIVERED: 'invite_delivered',
}

export const USER_ROLE = {
  ACCOUNT_ADMIN: 'account_admin',
  ADMIN: 'admin',
  USER: 'user',
  ASSISTANT: 'assistant',
  PARENT: 'parent',
  OPERATION: 'operation',
}


export const ENTITY_TYPE = {
  USER: 'User',
  ACCOUNT: 'Account',
  LEARNER: 'Learner',
  GOAL: 'Goal',
  SUBSCRIPTION: 'Subscription'
}

export const ENTITY_ACTION = {
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
  LOGIN: 'Login',
  ASSIGN: 'Assign',
  UNASSIGN: 'Unassign',
  INVITE: 'Invite',
  EXPORT: 'Export'
}

