import { useEffect, useState } from 'react'

import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError, type SilentRequest } from '@azure/msal-browser'

// graphql -react admin middleware
import buildGraphQLProvider from 'ra-data-graphql-simple'
import { myBuildQuery } from '@logic/contexts/AppStore/DataProvider.tsx'
import { DataProvider } from 'react-admin'
import axios from 'axios'

export const useTokenManagement = () => {
  const clientId = localStorage.getItem('msalClientId')

  const tokenRequest: SilentRequest = {
    scopes: [clientId, 'openid', 'offline_access'],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  }

  const isAuthenticated = useIsAuthenticated()
  const [dataProvider, setDataProvider] = useState<null | DataProvider>(() => null)
  const [accessTokenExpiration, setAccessTokenExpiration] = useState<Date | null>(null)
  const [userId, setUserId] = useState<null | string>(null)
  const [accountId, setAccountId] = useState<null | string>(null)
  const { instance } = useMsal()

  const [selectAccountDialogOpen, setSelectAccountDialogOpen] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<any[]>([])
  const [accessToken, setAccessToken] = useState<string | null>(null)

  const handleLogout = () => {
    // userId, AccoutnId - will set during useTokenManagement context - Home component
    // msalClientId, msalClientUrl, apiUrl- will be set in login page
    // msalB2CId - will be set after login - App component
    ['userEmail', 'msalB2CId', 'UserId', 'AccountId', 'msalClientId', 'msalClientUrl', 'apiUrl'].forEach((key) => localStorage.removeItem(key))

    // issue in msal, sometimes this below status is not cleared so manually clearing the msal.interaction.status
    sessionStorage.removeItem('msal.interaction.status')

    setUserId(null)
    setAccountId(null)
    setAccessToken(null)
    setAccessTokenExpiration(null)
    setDataProvider(null)

    instance.logoutRedirect()
  }

  const apiUrl = localStorage.getItem('apiUrl')

  useEffect(() => {
    const fetchADB2C = async () => {
      const localUserId = localStorage.getItem('UserId')
      const localAccountId = localStorage.getItem('AccountId')

      // get user id from ADb2C call
      const account = instance.getActiveAccount()
      const b2cId = account?.localAccountId

      let newAccessToken
      let authResult
      
      try {
        authResult = await instance.acquireTokenSilent(tokenRequest)
        newAccessToken = authResult.accessToken
        setAccessToken(newAccessToken)
        setAccessTokenExpiration(authResult.expiresOn)
      } catch (tokenError) {
        console.error("Failed to acquire token silently:", tokenError)
        // Handle specific token errors
        if (tokenError instanceof InteractionRequiredAuthError) {
          // User interaction required, redirect to login
          try {
            await instance.acquireTokenRedirect(tokenRequest)
            return // Exit function as redirect will happen
          } catch (redirectError) {
            console.error("Failed to redirect for authentication:", redirectError)
            handleLogout()
            return
          }
        } else {
          // For other token errors, log out the user
          console.error("Authentication error:", tokenError)
          handleLogout()
          return
        }
      }

      if (localUserId && localAccountId) {
        setUserId(localUserId)
        setAccountId(localAccountId)
        createDataProvider(newAccessToken, localUserId, localAccountId)
      } else {
        const graphqlQuery = `
          query GetAdb2cUser($id: ID!) {
            Adb2c(id: $id) {
              id
              accounts {
                account_name
                user_id
                account_id
              }
            }
          }
        `

        const variables = {
          id: b2cId,
        }

        const requestBody = {
          query: graphqlQuery,
          variables,
        }

        try {
          const response = await axios.post(apiUrl, requestBody, {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
              'Content-Type': 'application/json',
              authMethod: 'B2CWEB',
            },
          })

          const accounts = response.data.data.Adb2c?.accounts

          if (accounts && accounts.length > 1) {
            setAccounts(accounts)
            setSelectAccountDialogOpen(true)
          } else if (accounts?.length === 1) {
            const userId = accounts[0].user_id
            const accountId = accounts[0].account_id

            localStorage.setItem('UserId', userId)
            localStorage.setItem('AccountId', accountId)

            setUserId(userId)
            setAccountId(accountId)

            createDataProvider(newAccessToken, userId, accountId)
          } else {
            handleLogout()
          }
        } catch (error) {
          handleLogout()
        }
      }
    }

    fetchADB2C()
  }, [instance])

  const renewAccessToken = async () => {
    try {
      const authResult = await instance.acquireTokenSilent({
        ...tokenRequest,
        forceRefresh: true,
      })
      const newAccessToken = authResult.accessToken
      setAccessToken(newAccessToken)
      setAccessTokenExpiration(authResult.expiresOn)

      if (userId && accountId) {
        createDataProvider(newAccessToken, userId, accountId)
      }
    } catch (error) {
      handleLogout()
    }
  }

  useEffect(() => {
    if (!isAuthenticated || !userId) return // Exit if user is not authenticated

    const checkAndRenewToken = async () => {
      const now = Date.now()
      if (!accessTokenExpiration || accessTokenExpiration.getTime() < now + 600000) {
        await renewAccessToken()
      }
    }

    // Run once immediately
    checkAndRenewToken()

    // Run every 10 minutes (600,000ms)
    const tokenCheckInterval = setInterval(checkAndRenewToken, 600000)

    // Cleanup on unmount or dependency change
    return () => clearInterval(tokenCheckInterval)
  }, [isAuthenticated, userId, accessTokenExpiration, renewAccessToken])

  const createDataProvider = async (newAccessToken, userId, accountId) => {
    try {
      // Create a new data provider with the updated access token
      const graphQlDataProvider = await buildGraphQLProvider({
        buildQuery: myBuildQuery,
        clientOptions: {
          uri: apiUrl,
          headers: {
            authMethod: 'B2CWEB',
            UserId: userId,
            AccountId: accountId,
            Authorization: `Bearer ${newAccessToken}`,
          },
        },
      })

      setDataProvider(() => graphQlDataProvider)
    } catch (error) {
      console.error('Error in creating data provider:', error)
    }
  }

  const handleAccountSelection = (event, value) => {
    if (value) {
      const selectedUserId = value.user_id
      const selectedAccountId = value.account_id

      setUserId(selectedUserId)
      setAccountId(selectedAccountId)

      localStorage.setItem('UserId', selectedUserId)
      localStorage.setItem('AccountId', selectedAccountId)
      createDataProvider(accessToken, selectedUserId, selectedAccountId)
      setSelectAccountDialogOpen(false)
    }
  }

  return {
    dataProvider,
    selectAccountDialogOpen,
    setSelectAccountDialogOpen,
    userId,
    accounts,
    handleAccountSelection,
  }
}
