import React, { FC, useMemo, useState } from 'react'
import styles from './Activities.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxAutocomplete } from '@components/autocomplete/autocomplete.tsx'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { LearnerActivitiesSkillArea } from '@src/pages/Learner/Show/Activities/LearnerActivitiesSkillArea.tsx'
import { ActivityArea, ActivityGoal } from '@src/pages/Learner/Show/Activities/Activities.factory.ts'
import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

type Props = {
  data: ActivityArea[]
}


export const LearnerActivitiesTable: FC<Props> = ({ data }) => {

  const [ priorityFilter, setPriorityFilter ] = useState<FilterObject<string>[]>([])
  const [ statusFilter, setStatusFilter ] = useState<FilterObject<string>[]>([])

  const [isOpen, setIsOpen] = useState(true);

  const goalStatusOrder = ['emerging', 'in_progress', 'not_introduced', 'achieved']

  const sortGoalsByStatus = (goals: ActivityGoal[]) => {
    return goals.sort((a, b) => {
      const statusA = a.status;
      const statusB = b.status;
      return goalStatusOrder.indexOf(statusA) - goalStatusOrder.indexOf(statusB)
    })
  }

  //status has to be filtered based on unit
  // priority has to be filtered based on goal
  const filteredOuData = useMemo(() => {
    return data.map((activityArea: ActivityArea) => {
      const sortedGoals = sortGoalsByStatus(activityArea.goals)
      return {
        ...activityArea,
        goals: sortedGoals.reduce((filteredGoals: ActivityGoal[], goal: ActivityGoal) => {
          const filteredUnits = goal.units.filter((unit: UnitF) => {
            return (
              (priorityFilter.length === 0 || priorityFilter.some(obj => obj.value === goal.priority)) &&
              (statusFilter.length === 0 || statusFilter.some(obj => obj.value === unit.status))
            );
          });
  
          if (filteredUnits.length > 0) {
            filteredGoals.push({
              ...goal,
              units: filteredUnits,
            });
          }
  
          return filteredGoals;
        }, []),
      };
    });
  }, [priorityFilter, statusFilter, data]);
  


  const resetFilters = () => {
    setPriorityFilter([])
    setStatusFilter([])
  }


  return (
    <div className={styles.learnerActivityTable}>
      <div className={styles.filterBar}>
        <div className={styles.actionMenu}>
          <div className={cn('lxActionButton lxActionButtonDefaultSize', {'disabled': priorityFilter.length === 0 && statusFilter.length === 0})}
               onClick={resetFilters} tabIndex={0} onKeyDown={(event) => LxHandleKeyDown(event, resetFilters)} role='button'>
            <span>Clear Filters</span>
          </div>
          <div className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize', {'disabled': filteredOuData.length === 0})}
               onClick={() => setIsOpen(prev => !prev)} tabIndex={0} onKeyDown={(event) => LxHandleKeyDown(event, () => setIsOpen(prev => !prev))} role='button'  >
            <span>{isOpen ? 'Collapse All' : 'Expand All'}</span>
          </div>
        </div>
        <LxAutocomplete
          className={styles.autocomplete}
          multiple
          simple
          value={statusFilter}
          onChange={setStatusFilter}
          options={[
            {value: 'in_progress', label: 'In Progress', isEnabled: true},
            {value: 'not_introduced', label: 'Not Introduced', isEnabled: true},
            {value: 'emerging', label: 'Emerging', isEnabled: true},
            {value: 'mastered', label: 'Mastered', isEnabled: true},
            {value: 'generalized', label: 'Generalized', isEnabled: true},
          ]}
          placeholder={'Filter Activities by Progress Status'}
        />
        <LxAutocomplete
          className={styles.autocomplete}
          multiple
          simple
          value={priorityFilter}
          onChange={setPriorityFilter}
          options={[
            {value: 'low', label: 'Low Priority', isEnabled: true},
            {value: 'medium', label: 'Medium Priority', isEnabled: true},
            {value: 'high', label: 'High Priority', isEnabled: true},
          ]}
          placeholder={'Filter Activities by Priority'}
        />
      </div>
      {
        filteredOuData.map((activityArea: ActivityArea, index: number) => activityArea.goals.length > 0 &&
          <React.Fragment key={`${index}-${activityArea.name}-skill-area-learner`}>
             <LearnerActivitiesSkillArea data={activityArea} expandAll={isOpen} index={index}/>
          </React.Fragment>
        )
      }
    </div>
  )
}
