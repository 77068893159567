import React, { useContext, useMemo } from 'react'
import { useGetList } from 'react-admin'
import { UserB } from '@logic/contexts/Users/UsersBackend.type.ts'
import {  UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'
import { USER_STATE } from '@src/utils/constants'

interface UsersContextType {
  userList: UserF[] | null
  isLoading: boolean
  filterValues: FilterValues
  dispatch: DispatchType
}

const UserListContextDefault = {
  userList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    state: USER_STATE.ACTIVE,
  },
  dispatch: () => {},
}

const UsersListContext = createContextFactory<UsersContextType>(UserListContextDefault)

const mapServerUsersListToClient = (data: UserB[] | undefined): UserF[] | null => {
  if (isNilOrEmpty(data)) {
    return null
  }

  return data!.map((user: UserB) => ({
    id: user.id,
    firstName: user.first_name,
    fullName: `${user.first_name} ${user.last_name}`,
    lastName: user.last_name,
    emails: user.emails ?? [],
    primaryEmail: user.primary_email ?? [],
    b2cIds: user.b2c_ids ?? [],
    creationInfo: user.creation_info ?? null,
    userRole: user.user_role ?? null,
    state: user.state ?? null,
    jobTitle: user.job_title ?? null,
    lastLogin: user.last_login ?? null,
    assignedLearners: user.assigned_learners ?? [],
    assignedGoals: user.assigned_goals ?? [],
    assignedUsers: user.assigned_users ?? [],
  }))
}

export const UserListProvider: React.FC<{ children: React.ReactNode; filter?: any; applyStatesFilter?: boolean }> = ({
  children,
  filter,
  applyStatesFilter = false, // Default to false
}) => {
  const initialFilterValues = { Q: '', state: USER_STATE.ACTIVE }
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues)

  //applyStatesFilter is used to get active and pending users in same screen
  const adjustedFilter = useMemo(() => {
    const { state, ...otherFilters } = filterValues

    // Apply the special condition only if applySpecialFilter is true
    const updatedStatesFilter = applyStatesFilter && state === USER_STATE.ACTIVE ? [USER_STATE.ACTIVE,  USER_STATE.INVITED,  USER_STATE.INVITE_DELIVERED, USER_STATE.INVITE_FAILED] : [state]

    return {
      ...otherFilters,
      states: updatedStatesFilter,
    }
  }, [filterValues, applyStatesFilter])

  const { data, isLoading } = useGetList<UserB>('User', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'last_update', order: 'DESC' },
    filter: filter ? filter : adjustedFilter,
  })

  const sanitizedUserList = useMemo(() => mapServerUsersListToClient(data), [data])

  return <UsersListContext.Provider value={{ userList: sanitizedUserList, filterValues, isLoading, dispatch }}>{children}</UsersListContext.Provider>
}

export const useUsersList = (): UsersContextType => {
  return useContext(UsersListContext)
}
