import React, { FC } from 'react'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

type LxCancelButtonProps = {
  onCancel: () => void
  label?: string
  classNames?: string
  tabIndex?: number
  ariaLabel?: string
  role?: string
}

const LxCancelButton: FC<LxCancelButtonProps> = ({ onCancel, label = 'Cancel', classNames = '', tabIndex = 0, ariaLabel = '', role = 'button' }) => {
  return (
    <div
      className={classNames.replace(/,/g, ' ')} // Convert comma-separated to space-separated
      onClick={onCancel}
      tabIndex={tabIndex}
      onKeyDown={(event) => LxHandleKeyDown(event, onCancel)}
      aria-label={ariaLabel}
      role={role}
    >
      <span>{label}</span>
    </div>
  )
}

export default LxCancelButton
