import React, { useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'
import { RobotB } from '@src/logic/contexts/Robots/RobotsBackend.type'
import { useDataProvider } from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { formatDate } from '@src/utils/dateFormatter'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { RobotF } from '@src/logic/contexts/Robots/RobotsFrontend.type'
import { LxTooltip } from '@src/components/tooltip/tooltip'
import RobotVersionInfo from '../Robots/RobotVersionInfo'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface RobotInfoProps {
  open: boolean
  onClose: () => void
  account: AccountF | null
}

const RobotInfo: React.FC<RobotInfoProps> = ({ open, onClose, account }) => {
  const [robotData, setRobotData] = useState<RobotF[] | null>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [versionInfoModalOpen, setVersionInfoModalOpen] = useState(false)
  const [selectedVersionInfo, setSelectedVersionInfo] = useState(null)
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (open) {
      setIsDataLoading(true)

      dataProvider
        .getList('Robot', {
          pagination: { page: 0, perPage: 100 }, // Fixed 'perPage' typo
          sort: { field: 'id', order: 'ASC' },
          filter: { account_id: account?.id },
        })
        .then((response) => {
          const dataSubset = response.data.map((robot: RobotB) => ({
            id: robot.id,
            serial: robot.serial,
            accountId: robot.account_id,
            accountName: robot.account_name,
            versionInfo: {
              model: robot.version_info.model,
              softwareVersion: robot.version_info.software_version,
              versionNumber: robot.version_info.version_number,
              lastUpdate: robot.version_info.last_update,
            },
            usageInfo: {
              lastActivityDate: robot.usage_info.last_activity_date,
            },
            creationInfo: {
              date: robot.creation_info.date,
              by: robot.creation_info.by,
              userName: robot.creation_info.user_name,
            },
            assignedLicenses: robot.assigned_licenses,
          }))

          setRobotData(dataSubset)
        })
        .catch(() => setRobotData(null))
        .finally(() => setIsDataLoading(false))
    }
  }, [open, account, dataProvider])

  const openVersionInfoModal = (versionInfo) => {
    setSelectedVersionInfo(versionInfo)
    setVersionInfoModalOpen(true)
  }

  const closeVersionInfoModal = () => {
    setVersionInfoModalOpen(false)
    setSelectedVersionInfo(null)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth role='dialog'>
        <div className={styles.dialogHeader}>
          <h3>Robots of {account?.accountName}</h3>
        </div>

        <div className={styles.dialogContent}>
          <div className={cn(styles.headerRow, styles.headeRobot)}>
            <strong>Serial</strong>
            <strong>Last Activity</strong>
            <strong>Created By</strong>
            <strong>Version Info</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(robotData) ? (
            <div className={sharedStyles.notFound}>No robot assigned to the given account.</div>
          ) : (
            robotData?.map((robot, idx) => (
              <div className={cn(styles.infoRow, styles.infoRobot)} key={idx}>
                <span>{robot.serial}</span>
                <span>{formatDate(robot.usageInfo?.lastActivityDate)}</span>
                <LxTooltip tooltipText={formatDate(robot.creationInfo.date)}>
                  <span>{robot.creationInfo?.userName}</span>
                </LxTooltip>
                <span onClick={() => openVersionInfoModal(robot.versionInfo)} className={cn(styles.cursorPointer)}>
                  {robot.versionInfo?.softwareVersion}
                  {robot.versionInfo?.softwareVersion && <OpenInNewIcon className={styles.iconAligned} />}
                </span>
              </div>
            ))
          )}
        </div>

        <div className={styles.dialogActions}>
          <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onClose}>
            <span>Close</span>
          </div>
        </div>
      </Dialog>

      <RobotVersionInfo versionInfo={selectedVersionInfo} open={versionInfoModalOpen} onClose={closeVersionInfoModal} />
    </>
  )
}

export default RobotInfo
