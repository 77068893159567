import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  ActivitiesIcon,
  ActivitiesOutlineIcon,
  QtIcon,
  SessionPlanOutlineIcon,
  StudentOutlineIcon,
  TeamMemberIcon,
  TeamMembersIcon,
  UserInfoIcon,
} from '@icons/index'
import { SearchIcon, PlusIcon, InfoIcon } from '@icons/utils'
import { Link as LinkIcon } from '@mui/icons-material' // Import LinkIcon here
import { LxIcon } from '@components/icon/Icon'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import { useAccountsList } from '@src/logic/contexts/Accounts/AccountsListsContext'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { cn } from '@src/utils/cn'
import RobotInfo from './RobotInfo'
import AddressInfo from './AddressInfo'
import LinkedAccountInfo from './LinkedAccountInfo'
import ContactInfo from './ContactInfo'
import SubscriptionInfo from './SubscriptionInfo'
import styles from './AccountList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { Badge, Tooltip } from '@mui/material'
import UserInfo from './UserInfo'
import LearnerInfo from './Learner/LearnerInfo'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNotify } from 'react-admin'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

export const AccountList = () => {
  useDocumentTitle('Admin Accounts')
  
  const { accountList, isLoading } = useAccountsList()
  const [filteredData, setFilteredData] = useState(accountList)
  const [sortConfig, setSortConfig] = useState(null)
  const [dialogInfo, setDialogInfo] = useState({ isOpen: false, type: '', account: null })
  const notify = useNotify()

  useEffect(() => {
    if (!isLoading) setFilteredData(accountList)
  }, [isLoading, accountList])

  const handleSearch = (inputValue) => {
    const normalizedInput = inputValue.toLowerCase()
    const filteredAccounts = accountList?.filter(
      ({ accountName, customerId, accountCountry, accountType }) =>
        accountName.toLowerCase().includes(normalizedInput) ||
        customerId.toLowerCase().includes(normalizedInput) ||
        accountCountry.toLowerCase().includes(normalizedInput) ||
        accountType.toLowerCase().includes(normalizedInput)
    )
    setFilteredData(filteredAccounts)
  }

  const handleSort = (key) => {
    const direction = sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    setSortConfig({ key, direction })
    const sortedData = [...filteredData].sort((a, b) =>
      a[key] < b[key] ? (direction === 'asc' ? -1 : 1) : a[key] > b[key] ? (direction === 'asc' ? 1 : -1) : 0
    )
    setFilteredData(sortedData)
  }

  const openDialog = (type, account) => setDialogInfo({ isOpen: true, type, account })
  const closeDialog = () => setDialogInfo({ isOpen: false, type: '', account: null })

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify('Copied ID clipboard - ' + text)
      })
      .catch((err) => {
        notify('Failed to Copy ID clipboard - ' + err)
      })
  }

  return (
    <>
      <div className={styles.accountTableNavigation}>
        <div className={styles.accountsListHeading}>
          <LxIcon sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }} icon={ActivitiesOutlineIcon} />
          <h3>Accounts {filteredData?.length && ` (${filteredData?.length})`}</h3>
        </div>

        <div className={sharedStyles.tableActions}>
          <Link to='create' className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
            <LxIcon icon={PlusIcon} />
            Add New Account
          </Link>
          <Link to='combined' className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
            <LxIcon icon={PlusIcon} />
            Combined Account Creation
          </Link>
          <div className={cn('lxActionButton','lxActionButtonDefaultSize','inputHighLight')}>
            <LxIcon icon={SearchIcon} />
            <input
              className='pristineInput'
              type='text'
              onChange={(e) => handleSearch(e.target.value)}
              placeholder='Search by Account Name, Id, Country, Type'
            />
          </div>
        </div>
      </div>

      <div className={styles.accountsListContent}>
        <div className={cn(styles.accountsTableListHeader)} onClick={() => handleSort('accountName')}>
          Name
        </div>
        <div className={cn(styles.accountsTableListHeader)} onClick={() => handleSort('customerId')}>
          Customer ID
        </div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide2column)} onClick={() => handleSort('accountType')}>
          Type
        </div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide3column)} onClick={() => handleSort('accountCountry')}>
          Location
        </div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide4column, styles.notSortable)}>Contacts</div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide5column, styles.notSortable)}>Users</div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide5column, styles.notSortable)}>Learners</div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide5column, styles.notSortable)}>Robots</div>
        <div className={cn(styles.accountsTableListHeader, sharedStyles.hide6column, styles.notSortable)}>Subscriptions</div>
        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(filteredData) ? (
          <div className={sharedStyles.notFound}>No account matches your current search.</div>
        ) : (
          filteredData?.map((account) => (
            <React.Fragment key={account.id}>
              <div className={cn(styles.cursorPointer)}>
                <Tooltip
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', maxWidth: '300px' }}>
                      <span style={{ wordWrap: 'break-word', flex: 1 }}>{account.id}</span>
                      <ContentCopyIcon
                        onClick={() => handleCopy(account.id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '18px',
                          color: '#4a90e2',
                        }}
                      />
                    </div>
                  }
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <span>{account.accountName}</span>
                </Tooltip>
                {account.linkedAccounts?.length > 0 && (
                  <LinkIcon
                    onClick={() => openDialog('LinkedAccountInfo', account)} // Open AddressInfo dialog on icon click
                    sx={{ fontSize: '16px', marginLeft: '4px', cursor: 'pointer' }}
                  />
                )}
              </div>

              <div className={styles.noCursor}>{account.customerId}</div>
              <div className={cn(sharedStyles.hide2column, styles.noCursor)}>{account.accountType}</div>
              <div className={cn(sharedStyles.hide3column)} onClick={() => openDialog('AddressInfo', account)} style={{ cursor: 'pointer' }}>
                {account.accountCountry}
              </div>
              <div className={cn(sharedStyles.hide4column)} onClick={() => openDialog('ContactInfo', account)} style={{ cursor: 'pointer' }}>
                <LxIcon icon={UserInfoIcon} />
              </div>
              <div className={sharedStyles.hide5column} onClick={() => openDialog('UserInfo', account)}>
                <LxIcon icon={TeamMemberIcon} />
              </div>
              <div className={sharedStyles.hide5column} onClick={() => openDialog('LearnerInfo', account)}>
                <LxIcon icon={StudentOutlineIcon} />
              </div>
              <div className={sharedStyles.hide5column} onClick={() => openDialog('RobotInfo', account)}>
                <Badge badgeContent={account.robots?.length} overlap='circular' sx={{ '& .MuiBadge-badge': { backgroundColor: '#DAE3F3' } }}>
                  <LxIcon icon={QtIcon} />
                </Badge>
              </div>

              {/* mui badge will show only if value is greater than zero , so we have to convert to string if value is zero*/}
              <div className={sharedStyles.hide5column} onClick={() => openDialog('SubscriptionInfo', account)}>
                {(() => {
                  const subscriptionCount = Object.keys(account.subscriptions).length
                  return (
                    <Badge badgeContent={subscriptionCount} overlap='circular' sx={{ '& .MuiBadge-badge': { backgroundColor: '#DAE3F3' } }}>
                      <LxIcon icon={SessionPlanOutlineIcon} />
                    </Badge>
                  )
                })()}
              </div>
            </React.Fragment>
          ))
        )}
      </div>

      {dialogInfo.isOpen &&
        {
          RobotInfo: <RobotInfo open onClose={closeDialog} account={dialogInfo.account} />,
          UserInfo: <UserInfo open onClose={closeDialog} account={dialogInfo.account} />,
          LearnerInfo: <LearnerInfo open onClose={closeDialog} account={dialogInfo.account} />,
          SubscriptionInfo: <SubscriptionInfo open onClose={closeDialog} account={dialogInfo.account} />,
          AddressInfo: <AddressInfo open onClose={closeDialog} account={dialogInfo.account} />,
          ContactInfo: <ContactInfo open onClose={closeDialog} account={dialogInfo.account} />,
          LinkedAccountInfo: <LinkedAccountInfo open onClose={closeDialog} account={dialogInfo.account} />,
        }[dialogInfo.type]}
    </>
  )
}

export default AccountList
