import React, { FC, useEffect } from 'react'
import { Dialog } from '@mui/material'
import styles from './UserAddNewLearner.module.scss'
import { cn } from '@src/utils/cn.ts'
import { ToggleButton } from '@components/toggleButton/toggleButton.tsx'
import { useForm, Controller } from 'react-hook-form'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { InfoOutlineIcon } from '@icons/utils'
import { LxIcon } from '@components/icon/Icon.tsx'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import { useDataProvider, useNotify, useRefresh } from 'react-admin'
import {
  CAN_EDIT_DELETE_ALL_USERS,
  CAN_RUN_ALL_ACTIVITIES,
  CAN_VIEW_ALL_USERS,
} from '@logic/contexts/AppStore/UserPermissions.constant.ts'
import LxCancelButton from '@src/components/cancelButton/LxCancelButton'
import LxSaveButton from '@src/components/saveButton/LxSaveButton'

interface Props {
  isModalOpen: boolean
  onClose: () => void
  learner: any
  userRole: string
  learnerFullName: string
  userFullName: string
  userId: string
  superVisorId: string
  isFormSubmitting: boolean
  setIsFormSubmitting: (isSubmitting: boolean) => void
}

export const EditAccess: FC<Props> = ({
  isModalOpen,
  onClose,
  learner,
  userRole,
  userFullName,
  learnerFullName,
  userId,
  superVisorId,
  isFormSubmitting,
  setIsFormSubmitting,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  // canRunActivities - "AC-2310-UUL-004"
  // canViewAllGoals - "AC-2310-UL-002"
  // canEditDeleteAllGoals - "AC-2310-UL-003"

  const defaultFormProps = {
    canRunActivities: learner.access?.[CAN_RUN_ALL_ACTIVITIES] || false,
    canViewAllGoals: learner.access?.[CAN_VIEW_ALL_USERS] || false,
    canEditDeleteAllGoals: learner.access?.[CAN_EDIT_DELETE_ALL_USERS] || false,
  }

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormProps,
  })

  useEffect(() => {
    if (isModalOpen) {
      reset(defaultFormProps)
    }
  }, [isModalOpen])

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      const { canRunActivities, canViewAllGoals, canEditDeleteAllGoals } =
        formData

      const accesses = []

      if (userRole === 'User') {
        if (canViewAllGoals) {
          accesses.push(CAN_VIEW_ALL_USERS)
        }

        if (canEditDeleteAllGoals) {
          accesses.push(CAN_EDIT_DELETE_ALL_USERS)
        }
      }

      if (userRole === 'Assistant') {
        if (canRunActivities) {
          accesses.push(CAN_RUN_ALL_ACTIVITIES)
        }
      }

      const payload = {
        assign_learner: {
          learner_id: learner.id,
          accesses: accesses,
          supervisor_id: superVisorId,
          action: 'add_learner',
        },
      }

      await dataProvider.update('User', {
        id: userId,
        data: payload,
        previousData: null,
      })

      // Handle success case
      notify('Access updated successfully', { type: 'success' })
    } catch (error) {
      // Handle error case
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      onClose()
      setIsFormSubmitting(false)
      refresh()
    }
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      role='dialog'
    >
      <div className={styles.materialUiContainerWrapper}>
        <div className={styles.modalContainer}>
          {isFormSubmitting && (
            <div className={styles.LoadingOverlay}>
              <LxLoadingSpinner />
            </div>
          )}
          <div className={styles.modalHeader}>
            <h3>
            Access of {userFullName} to  {learnerFullName}
            </h3>
          </div>
          <div className={styles.modalContent}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {userRole === 'Assistant' && (
                <>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                    Can run un-planned activities for {learnerFullName}{' '}
                    </span>
                    <LxTooltip
                      tooltipText={
                        'Assistant can also run activities that are not pre-planned by the supervisor'
                      }
                    >
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name='canRunActivities'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ToggleButton value={value} onChange={onChange} ariaLabel={`Can run un-planned activities for ${learnerFullName}`} />
                      )}
                    />
                  </div>
                </>
              )}
              {userRole === 'User' && (
                <>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                      Can view all goals of {learnerFullName}
                    </span>
                    <LxTooltip
                      tooltipText={
                        'User can view all goals of the learner including goals created by other users'
                      }
                    >
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name='canViewAllGoals'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ToggleButton value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                      Can edit and delete all goals of {learnerFullName}
                    </span>
                    <LxTooltip
                      tooltipText={
                        'User can edit and delete all goals of the learner including goals created by other users'
                      }
                    >
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name='canEditDeleteAllGoals'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ToggleButton value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
          <div className={styles.modalFooter}>
            <LxCancelButton label="Cancel" classNames="lxActionButton,lxActionButtonDefaultSize" onCancel={onClose} />
            <LxSaveButton label="Update Access" classNames="lxActionButton,lxActionButtonFilled,lxActionButtonDefaultSize"onSubmit={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}
