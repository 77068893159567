import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNotify, useRefresh, useUpdate } from 'react-admin'
import { clientUnitToServerUnit, GoalForm, GoalFormType } from '@components/goalForm/GoalForm.tsx'
import modalStyles from '@components/modal/modal.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import { generateSimpleOptions } from '@logic/useFiltering.hook.ts'
import { GOALF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

export const LearnerGoalEdit = ({ goal }: {goal: GOALF}) => {
  const { hideModal, toggleOverlay } = useModal()
  const refresh = useRefresh()
  const { allData: activitiesData, isLoading: isCurriculumLoading } =
    useActivitiesList()
  const [ update, { isLoading } ] = useUpdate()
  const isSubmitting = useRef(false)
  const notify = useNotify()

  const goalUnits = useMemo(() => {
    if (isNilOrEmpty(activitiesData?.skillAreas)) {
      return []
    }

    const matchingUnits = activitiesData!.skillAreas.reduce((acc, skillArea) => {
      if (acc) return acc
      if (skillArea.id === goal.skillAreaId) {
        return skillArea.goals.reduce((acc, goalStorage) => {
          if (acc) return acc
          if (goalStorage.id === goal.goalId) {
            return goalStorage.units
          }
        }, null)
      }
    }, null)

    return matchingUnits || []
  }, [ goal, activitiesData ])

  const options = useMemo(() => ( {
    units: generateSimpleOptions(goalUnits, 'title'),
  } ), [ goalUnits ])

  const resetAndNotify = useCallback((message, type) => {
    notify(message, { type })
    hideModal()
    refresh()
    isSubmitting.current = false // Reset the isSubmitting flag
    toggleOverlay(isSubmitting.current)
  }, [ notify, hideModal, refresh ])

  const onSubmit = useCallback(async (formData: GoalFormType) => {
    if (isSubmitting.current) {
      return
    }
    
    isSubmitting.current = true
    toggleOverlay(isSubmitting.current)

    update('Goal', {
      id: goal.id,
      data: {
        description: formData.description,
        priority: formData.priority,
        units: clientUnitToServerUnit(formData.units),
        action: 'update_goal',
      },
      previousData: goal.rawGoal,
    }, {
      onSuccess: ({ data }) => resetAndNotify('Goal Updated', 'success'),
      onFailure: (error) => resetAndNotify(error.message || 'Something went wrong', 'warning'),
    })
  }, [ update, goal.id, resetAndNotify ])

  const defaultValues = useMemo(() => ( {
    units: generateSimpleOptions(goal.units, 'unitTitle'),
    description: goal.description,
    priority: goal.priority,
  } ), [ goal ])
 
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Focus the modal when opens
    modalRef.current?.focus()
  }, [])

  return (
    <div className={modalStyles.modalLg} tabIndex={-1} ref={modalRef}>
      <div className={modalStyles.dialogHeader}>
        <h3>Edit Goal - {goal.name}</h3>
      </div>
      <GoalForm onSubmit={onSubmit} onCancel={hideModal} dataSet={options} defaultValues={defaultValues}
                isEdit={true}></GoalForm>
    </div>
  )
}
