import styles from './AnalyticsReport.module.scss'
import '@styles/common.scss'
import 'chart.js/auto'

import {
  AnalyticsReportProvider,
  useAnalyticsReport,
} from '@logic/contexts/Analytics/Report/AnalyticsReportContext.tsx'
import { sanitizeDate } from '@logic/contexts/Analytics/Report/AnalyticsReport.factory.tsx'

import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { useEffect, useRef } from 'react'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
// import { GeneratePdfReport } from '@src/pages/Learner/Show/Report/GeneratePdfReport.tsx'
import { PrintToPdf } from '@src/utils/PrintToPdf'
import usePrevious from '@src/hooks/usePrevious.hook.ts'
import { SchoolIcon } from '@icons/index.ts'
import { useSearchParams } from 'react-router-dom'
import { ReportArea } from './ReportArea'
import { LxSelect } from '@components/select/select.tsx'

const Report = () => {
  const {
    reportData,
    isReportFetching,
    duration,
    handleDurationChange,
    handleGenerateReportClick,
  } = useAnalyticsReport()
  const contentRef = useRef<HTMLDivElement>(null)
  const prevDuration = usePrevious(duration)
  const [searchParams] = useSearchParams()
  const accountName = searchParams.get('name') ?? ''
  const tau = searchParams.get('tau') ?? ''
  const tal = searchParams.get('tal') ?? ''
  const lad = searchParams.get('lad') ?? ''

  const currentDate = new Date()
  const startDate = new Date()
  startDate.setDate(currentDate.getDate() - duration)

  useEffect(() => {
    // Fetch report data when component mounts and whenever duration changes
    handleGenerateReportClick()
  }, []) // Empty dependency array to run only on mount

  useEffect(() => {
    if (prevDuration !== duration) {
      handleGenerateReportClick()
    }
  }, [prevDuration, duration])

  return (
    <>
      <div className={styles.reportsHeader}>
        <div className={styles.accountDetails}>
          <div className={styles.iconWrapper}>
            <LxIcon
              icon={SchoolIcon}
              sxStyles={{ height: '80px', width: '80px' }}
            />
          </div>

          <div className={styles.details}>
            <div>
              Facility: <span className={styles.textBold}>{accountName}</span>
            </div>
            <div>Total Active Users: {tau}</div>
            <div>Total Active Learners: {tal}</div>
          </div>
        </div>

        <div className={cn(styles.reportActions, 'ignoreOnPrint')}>
          {/* <div className={cn('lxActionButton lxActionButtonDefaultSize')} > Edit learner info </div> */}
          Last activity Date: <span className={styles.textBold}> {lad}</span>
        </div>
      </div>

      <div className={styles.reportConfigHeader} >
        <h3>
          <center>{accountName} Usage and Success Report</center>
        </h3>
      </div>

      <div className={styles.reportContent} ref={contentRef}>
        <div className={styles.reportActions}>
          <div className={cn('ignoreOnPrint')}>Show report for:</div>
          <div className={cn('ignoreOnPrint')}>
            <LxSelect
              className={styles.customSelect} 
              value={duration}
              onChange={handleDurationChange}
              options={[
                { value: '30', label: 'Last Month' },
                { value: '90', label: 'Last 3 Months' },
                { value: '365', label: 'Last Year' },
              ]}
              role="combobox"
              ariaLabel="Select duration"
            />
          </div>
          <div className={styles.datePeriod}>
            from <b>{sanitizeDate(startDate)}</b>
            to <b>{sanitizeDate(currentDate)}</b>
          </div>
          <div className={cn('ignoreOnPrint')} style={{ marginLeft: 'auto' }}>
            <PrintToPdf />
          </div>
        </div>

        {isReportFetching ? (
          <LxLoadingSpinner className={styles.placeholderContainer} />
        ) : isNilOrEmpty(reportData) ? (
          <div className={styles.placeholderContainer}>
            <h5>No data found, please try to change filter parameters</h5>
          </div>
        ) : (
          <ReportArea reportData={reportData} duration={duration} />
        )}
      </div>
    </>
  )
}

export const AnalyticsReport = () => {
  return (
    <AnalyticsReportProvider>
      <Report />
    </AnalyticsReportProvider>
  )
}

export default AnalyticsReport
