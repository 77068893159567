import React from 'react'
import {
  Dialog
} from '@mui/material'
import { AccountF } from '@logic/contexts/Accounts/AccountsFrontend.type.ts'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'

interface ContactInfoProps {
  open: boolean
  onClose: () => void
  account: AccountF | null
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  open,
  onClose,
  account,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" role='dialog'>
      <div className={styles.dialogHeader}>
        <h3>Contact Details - {account?.accountName} </h3>
      </div>

      <div className={styles.dialogContent}>
        <div className={cn(styles.headerRow, styles.contactsHeader)}>
          <strong>Name:</strong>
          <strong>Email:</strong>
          <strong>Phone:</strong>
        </div>

        {account &&
          account.contacts?.map((contact, idx) => (
            <div className={cn(styles.infoRow, styles.contactsRow)} key={idx}>
              <span className={styles.textOverflow}>
                {contact.name} {contact.surname}
              </span>
              <span className={styles.textOverflow}>
                {contact.email}
              </span>
              <span>
               {contact.phone}
              </span>
            </div>
          ))}
      </div>

      <div className={styles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Close</span>
        </div>
      </div>
    </Dialog>
  )
}

export default ContactInfo
