import { useEffect, useRef, useState } from 'react'
import styles from './Navbar.module.scss'
import { LogoutIcon, MenuIcon } from '@icons/utils'
import { useMsal } from '@azure/msal-react'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { CircularProgress } from '@mui/material'
import { cn } from '@src/utils/cn.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LxIcon } from '@components/icon/Icon.tsx'
import LxMenu from '@src/pages/layout/Menu.tsx'
import { useLayout } from '@logic/contexts/LayoutContext.tsx'
import { LxHandleKeyDown } from '@src/utils/Accessbility'

const APP_BASE_URL = import.meta.env.VITE_APP_DIST_BASE_URL || 'https://strg.app.luxai.com/apps-distribution'

export const LxNavbar = () => {
  const { instance } = useMsal()
  const { currentUser, isUserLoading } = useUserContext() as any
  const { setShouldOpenMobileNav, shouldOpenMobileNav } = useLayout()

  const [educatorAppUrl, setEducatorAppUrl] = useState('')
  const [learnerAppUrl, setLearnerAppUrl] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const menuItemsRef = useRef<(HTMLAnchorElement | null)[]>([])

  useEffect(() => {
    const fetchAppDetails = async (appType: string, setUrl: Function) => {
      try {
        const response = await fetch(`${APP_BASE_URL}/${appType}/app.json`)
        const data = await response.json()
        setUrl(`${APP_BASE_URL}/${appType}/${data.filename}`)
      } catch (error) {
        console.error(`Failed to fetch ${appType} app details`, error)
      }
    }

    fetchAppDetails('EducatorApp', setEducatorAppUrl)
    fetchAppDetails('LearnerApp', setLearnerAppUrl)
  }, [])

  const handleLogout = async () => {
    // userId, AccoutnId - will set during useTokenManagement context - Home component
    // msalClientId, msalClientUrl, apiUrl- will be set in login page
    // msalB2CId - will be set after login - App component
    ['userEmail', 'msalB2CId', 'UserId', 'AccountId', 'msalClientId', 'msalClientUrl', 'apiUrl'].forEach((key) => localStorage.removeItem(key))
    // issue in msal, sometimes this below status is not cleared so manually clearing the msal.interaction.status
    sessionStorage.removeItem('msal.interaction.status')
    await instance.logoutRedirect()
  }

  let accountName = ''
  if (!isUserLoading) {
    accountName = currentUser.accountName
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false)
        setActiveIndex(-1)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleDropdownKeyDown = (event: KeyboardEvent) => {
    const menuItems = [educatorAppUrl, learnerAppUrl].filter(Boolean)

    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        if (!isDropdownOpen) {
          setIsDropdownOpen(true)
          setActiveIndex(0)
        } else if (activeIndex >= 0) {
          menuItemsRef.current[activeIndex]?.click()
        }
        break
      case 'Escape':
        setIsDropdownOpen(false)
        setActiveIndex(-1)
        break
      case 'ArrowDown':
        event.preventDefault()
        if (!isDropdownOpen) {
          setIsDropdownOpen(true)
          setActiveIndex(0)
        } else {
          setActiveIndex((prev) => (prev + 1) % menuItems.length)
        }
        break
      case 'ArrowUp':
        event.preventDefault()
        if (isDropdownOpen) {
          setActiveIndex((prev) => (prev - 1 + menuItems.length) % menuItems.length)
        }
        break
      case 'Tab':
        if (isDropdownOpen) {
          setIsDropdownOpen(false)
          setActiveIndex(-1)
        }
        break
    }
  }

  return (
    <div className={cn(styles.layoutNavbar, 'ignoreOnPrint')}>
      <div className={styles.layoutNavbarleftText}>{isUserLoading ? <CircularProgress /> : <h5 role='heading'> {accountName}</h5>}</div>
      <div className={styles.navbarNavigation} role='navigation' aria-label='Top'>
        <div
          ref={dropdownRef}
          className={styles.appDownloaddropdown}
          onKeyDown={handleDropdownKeyDown}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          role='button'
          aria-haspopup='true'
          aria-expanded={isDropdownOpen}
          tabIndex={0}
        >
          <h5 id='downloads-label'>Downloads</h5>
          <div className={styles.appDownloaddropdownContent} style={{ display: isDropdownOpen ? 'block' : 'none' }} role='menu' aria-labelledby='downloads-label'>
            {educatorAppUrl && (
              <a
                href={educatorAppUrl}
                ref={(el) => (menuItemsRef.current[0] = el)}
                target='_blank'
                rel='noopener noreferrer'
                role='menuitem'
                tabIndex={activeIndex === 0 ? 0 : -1}
                aria-label='Download Educator App'
              >
                Educator App
              </a>
            )}
            {learnerAppUrl && (
              <a
                href={learnerAppUrl}
                ref={(el) => (menuItemsRef.current[1] = el)}
                target='_blank'
                rel='noopener noreferrer'
                role='menuitem'
                tabIndex={activeIndex === 1 ? 0 : -1}
                aria-label='Download Learner App'
              >
                Learner App
              </a>
            )}
          </div>
        </div>
        <a href='https://luxai.com/qtrobot-training-resources-for-special-needs-education-schools/' target='_blank' className={styles.support}>
          <h5 role='button' aria-label='Tutorials'>
            Tutorials
          </h5>
        </a>
        <a href='https://luxai.com/contact/' target='_blank' className={styles.support}>
          <h5 role='button' aria-label='Support'>
            Support
          </h5>
        </a>
        <div className={styles.logoutButton} onClick={handleLogout} tabIndex={0} onKeyDown={(event) => LxHandleKeyDown(event, handleLogout)}>
          <h5 role='button' aria-label='Logout'>
            Logout
          </h5>
          <LxIcon icon={LogoutIcon}></LxIcon>
        </div>
      </div>
      <div className={styles.mobileNavbarNavigation}>
        <LxClickAbleIcon icon={MenuIcon} customViewBox={'0 0 50 50'} onClick={() => setShouldOpenMobileNav(true)}></LxClickAbleIcon>
        {shouldOpenMobileNav && (
          <div className={styles.menuNav}>
            <LxMenu mobileVersion={true} />
          </div>
        )}
      </div>
    </div>
  )
}

export default LxNavbar
