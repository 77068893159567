import React, { useState, useRef, useEffect, ReactNode, FC } from 'react'
import styles from './select.module.scss'
import { cn } from '@src/utils/cn'
import { LxIcon } from '@components/icon/Icon'
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'
import { FilterObject } from '@logic/useFiltering.hook'

interface CustomSelectProps<T> {
  value?: T
  onChange: (value: T) => void
  icon?: ReactNode
  options: FilterObject<T>[]
  shrinked?: boolean
  hasError?: boolean
  className?: string
  placeholder?: string
  role?: string
  ariaLabel?: string
}

export const LxSelect: FC = <T,>({
  placeholder,
  value,
  onChange,
  options,
  icon,
  className,
  shrinked,
  hasError,
  role = '',
  ariaLabel = '' 
}: CustomSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const selectRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = (optionValue: FilterObject<T>) => {
    onChange(optionValue.value)
    setIsOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        } else if (selectedIndex >= 0) {
          handleOptionClick(options[selectedIndex])
        }
        break
      case 'ArrowDown':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        }
        setSelectedIndex(prev => 
          prev === options.length - 1 ? 0 : prev + 1
        )
        break
      case 'ArrowUp':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        }
        setSelectedIndex(prev => 
          prev <= 0 ? options.length - 1 : prev - 1
        )
        break
      case 'Escape':
        setIsOpen(false)
        setSelectedIndex(-1)
        break
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        setSelectedIndex(-1)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const selected = options.find(option => {
    if (!value) return null
    return typeof value === 'string' 
      ? option.value === value 
      : option.id === value?.id
  })

  return (
    <div 
      ref={selectRef}
      className={cn(styles.customSelect, className)}
      aria-expanded={isOpen}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role={role}
      aria-label={ariaLabel}
    >
      <div
        className={cn(
          styles.selectSelected,
          { [styles.shrinked]: !!shrinked },
          { ['formFieldRequired']: hasError }
        )}
        onClick={toggleDropdown}
      >
        {icon && <>{icon}</>}
        <span>{selected?.label || placeholder || 'Select an option'}</span>
        <LxIcon
          icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
          customViewBox={'0 0 960 560'}
        />
      </div>
      {isOpen && (
        <div 
          id="select-dropdown"
          role="listbox"
          className={cn(styles.dropdownContainer)}
        >
          {options.map((option, index) => (
            <div
              key={option.label || index}
              role="option"
              aria-selected={selectedIndex === index}
              className={cn(
                styles.dropdownItem,
                option.className,
                { [styles.KeyBoardSelected]: selectedIndex === index }
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}