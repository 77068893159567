
// Not used now, I moved actiivties show as popup, So this component is not used now.
// Later we will review and remove this component if not needed.

import React from 'react'
import styles from './ActicityShow.module.scss'
import { useActivitiesShow } from '@logic/contexts/Activities/ActivitiesShowContext.tsx'
import { useNavigate } from 'react-router-dom'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

export const ActivitiesShow = () => {
  const { activity, isLoading } = useActivitiesShow()
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);  // this will navigate to the previous page
  };

  return (
    <>
      {isLoading
        ? <LxLoadingSpinner />
        : <div className={styles.activityWrapper}>
          <div className={styles.activityActions}>
            <div className={'lxActionButton lxActionButtonFilled'} onClick={handleGoBack}>Back</div>
          </div>
          <h2>Activity Name: {activity?.extras?.title}</h2>
          {activity!.extras.appNotes.map((note, index) => (
            <React.Fragment key={`${note.title}-${index}`}>
              {note.desc
                ? <div className={styles.activityNote} key={index}>
                  <h5>{note.title}</h5>
                  <p>{note.desc}</p>
                </div>
                : null
              }
            </React.Fragment>
          ))}
        </div>
      }
    </>
  )
}

export default ActivitiesShow
