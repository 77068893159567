import React, { useEffect, useRef, useState } from 'react'
import styles from './autocompleteTmp.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'

interface Option {
  value: string
  label: string
}

interface Props {
  value: string
  onChange: (value: string) => void
  options: Option[]
  ariaLabel?: string
}

export const LxAutocompleteTmp: React.FC<Props> = ({ onChange, options, value,  ariaLabel }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        setSelectedIndex(-1)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [selectRef])

  const selected = options.find((option) => option.value === value)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        if (!isOpen) {
          setIsOpen(true)
        } else if (selectedIndex >= 0) {
          handleOptionClick(options[selectedIndex].value)
        }
        break
      case 'ArrowDown':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        }
        setSelectedIndex((prev) => (prev === options.length - 1 ? 0 : prev + 1))
        break
      case 'ArrowUp':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
        }
        setSelectedIndex((prev) => (prev <= 0 ? options.length - 1 : prev - 1))
        break
      case 'Escape':
        setIsOpen(false)
        setSelectedIndex(-1)
        break
    }
  }

  return (
    <div className={cn(styles.autocompleteContainer)} ref={selectRef}   onKeyDown={handleKeyDown} >
      <div onClick={toggleDropdown} tabIndex={0}  onKeyDown={handleKeyDown} role='combobox' aria-expanded={isOpen} aria-haspopup='listbox' aria-controls='select-dropdown' aria-label={ariaLabel}>
        <h3 role='option'>
          {selected.label}
        </h3>
        <LxIcon icon={isOpen ? ArrowUpIcon : ArrowDownIcon} customViewBox={'0 0 960 560'}></LxIcon>
      </div>
      {isOpen && (
        <div className={styles.selectItems}  role='option'>
          {options.map((option, index) => (
            <div
              key={option.value}
              className={cn(styles.selectItem, { [styles.KeyBoardSelected]: selectedIndex === index })}
              onClick={() => handleOptionClick(option.value)}
              role='option'
              aria-selected={selectedIndex === index}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
