export const LxHandleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, onEnter?: (a?: any) => void, onEscape?: (a?: any) => void) => {
  if (event.key === 'Enter' || event.key === ' ') {
    if (onEnter) {
      event.preventDefault()
      onEnter()
    }
  } else if (event.key === 'Escape') {
    if (onEscape) {
      event.preventDefault()
      onEscape()
    }
  }
}
