import React, { FC } from 'react'
import { LxHandleKeyDown } from '@src/utils/Accessbility'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

type LxSaveButtonProps = {
  onSubmit: () => void
  label?: string
  classNames?: string
  tabIndex?: number
  isSubmitting?: boolean
  ariaLabel?: string
  role?: string
}

const LxSaveButton: FC<LxSaveButtonProps> = ({ onSubmit, label = 'Save', classNames = '', tabIndex = 0, isSubmitting = false, ariaLabel = '', role = 'button' }) => {
  return (
    <div
      className={`${classNames.replace(/,/g, ' ')} ${isSubmitting ? 'disabled' : ''}`}
      onClick={!isSubmitting ? onSubmit : undefined}
      tabIndex={tabIndex}
      onKeyDown={(event) => LxHandleKeyDown(event, onSubmit)}
      aria-label={ariaLabel}
      role={role}
    >
      {isSubmitting ? <LxLoadingSpinner /> : <span>{label}</span>}
    </div>
  )
}

export default LxSaveButton
