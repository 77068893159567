import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Logo from '@src/pages/layout/Logo'
import axios from 'axios'
import { updateMsalConfig } from '@src/logic/MsalConfig'
import TextField from '@mui/material/TextField'
import LxSnackbar from '@src/utils/LxSnackbar'

import { PublicClientApplication } from '@azure/msal-browser'
import { useDocumentTitle } from '@src/hooks/useDocumentTitle'

const Login = () => {
  useDocumentTitle('Login')
  const [email, setEmail] = useState('')
  const [openNotify, setOpenNotify] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEmailValidState, setIsEmailValidState] = useState(false)

  const handleNotifyClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenNotify(false)
  }

  const isEmailValid = (email) => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsEmailValidState(isEmailValid(email));
    }, 100); // Delay validation by 100ms to avoid unnecessary validation on every key stroke
  
    return () => clearTimeout(timer);
  }, [email]);
  

  const handleLogin = async () => {
    const userEmail = email.toLocaleLowerCase()
    // Check if the email is valid before proceeding
    if (!isEmailValid(userEmail)) {
      setOpenNotify(true)
      return
    }

    setIsSubmitting(true)

    const graphqlQuery = `
    query GetResourceGroup($identifier: String!) {
      ResourceGroup(identifier: $identifier) {
        name
        api {
          url
        }
        storage {
          url
        }
        identity_providers {
          target
          client_id
          url
        }
      }
    }
  `

    const variables = {
      identifier: userEmail,
    }

    const requestBody = {
      query: graphqlQuery,
      variables,
    }

    try {
      const response = await axios.post(import.meta.env.VITE_RESOLVER_URL, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const resourceGroupData = response.data.data.ResourceGroup

      // Check if ResourceGroup is null
      if (!resourceGroupData) {
        setOpenNotify(true)
        setIsSubmitting(false)
        return
      }

      // store data in local storage

      const webIdentityProvider = resourceGroupData.identity_providers.find((provider) => provider.target === 'web')

      const clientId = webIdentityProvider.client_id
      const b2cUrl = webIdentityProvider.url
      const apiUrl = resourceGroupData.api.url

      localStorage.setItem('msalClientId', clientId)
      localStorage.setItem('msalClientUrl', b2cUrl)
      localStorage.setItem('apiUrl', apiUrl)

      // // Update MSAL instance configuration based on API response

      const modifiedMsalConfig = updateMsalConfig(clientId, b2cUrl)

      const instance = new PublicClientApplication(modifiedMsalConfig)
      await instance.initialize()

      // Check if there's an active account and logout if so
      const activeAccount = instance.getActiveAccount()
      if (activeAccount) {
        await instance.logoutRedirect({
          account: activeAccount,
        })
      }

      await instance.loginRedirect({
        extraQueryParameters: {
          login_hint: email,
        },
      })
    } catch (error) {
      // Handle GraphQL call error
      setIsSubmitting(false) 
    }
  }

  return (
    <Grid container component='main' sx={{ height: '100vh', position: 'relative' }}>
      <CssBaseline />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: 'url(/static/images/illustration.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        
        <Paper
          elevation={6}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 4,
            boxShadow: '#8fb3dc 0px 3px 5px 2px',
          }}
        >
          <Logo style={{ maxWidth: '100%' }} height='80' />
          <Box sx={{ maxWidth: 400, textAlign: 'center', mt: 2 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Enter your email'
              name='email'
              autoComplete='email'
              autoFocus
              type='email'
              onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ borderRadius: 4 }}
            />
            <Button
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#78b4e2',
                color: '#ffffff',
               
                borderRadius: 4,
              }}
              onClick={handleLogin}
              disabled={isSubmitting || !isEmailValidState}
            >
              Continue
            </Button>
          </Box>
        </Paper>
      </Box>
      <LxSnackbar open={openNotify} onClose={handleNotifyClose} message='Invalid Email: Please enter valid email' />
    </Grid>
  )
}

export default Login
