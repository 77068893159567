import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import { useCreate, useNotify } from 'react-admin'
import { clientUnitToServerUnit, GoalForm, GoalFormDataSet, GoalFormType } from '@components/goalForm/GoalForm.tsx'
import { cn } from '@src/utils/cn.ts'
import styles from './common.module.scss'
import modalStyles from '@components/modal/modal.module.scss'
import { PlusOutlineIcon } from '@icons/utils'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { generateSimpleOptions } from '@logic/useFiltering.hook.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { GoalF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'

type Props = {
  skillAreaName: string
  skillAreaId: string
  goal: GoalF
}

export const ActivitiesGoalCreate: FC<Props> = ({ skillAreaName, skillAreaId, goal }) => {
  const { showModal, hideModal, toggleOverlay } = useModal()
  const { learnerList, isLoading } = useLearnersList()
  const [create, { isLoading: apiIsLoading }] = useCreate()
  const isSubmitting = useRef(false)

  const notify = useNotify()

  const options: GoalFormDataSet = useMemo(
    () => ({
      learners: generateSimpleOptions(learnerList, 'fullName'),
      units: generateSimpleOptions(goal.units, 'title'),
    }),
    [learnerList, goal, isLoading]
  )

  const resetAndNotify = useCallback(
    (message, type) => {
      notify(message, { type })
      hideModal()
      isSubmitting.current = false // Reset the isSubmitting flag
    },
    [notify, hideModal]
  )

  const onSubmit = useCallback(
    async (formData: GoalFormType) => {
      if (isSubmitting.current) {
        return
      }
      isSubmitting.current = true

      await create(
        'Goal',
        {
          data: {
            learner_id: formData.learner!.id,
            category_id: skillAreaId,
            sub_category_id: goal.id,
            category_name: skillAreaName,
            sub_category_name: goal.name,
            name: goal.name,
            description: formData.description,
            priority: formData.priority,
            units: clientUnitToServerUnit(formData.units as any),
          },
        },
        {
          onSuccess: () => resetAndNotify('Goal created successfully', 'success'),
          onFailure: (error) => resetAndNotify(error.message || 'Something went wrong, please try again later', 'error'),
        }
      )
    },
    [create, resetAndNotify]
  )

  useEffect(() => {
    toggleOverlay(isLoading || apiIsLoading)
  }, [isLoading, apiIsLoading])

  const modalRef = useRef<HTMLDivElement>(null)

  const renderModal = useCallback(() => {
    showModal(
      <div className={modalStyles.modalLg} tabIndex={-1} ref={modalRef}>
        <div className={modalStyles.dialogHeader}>
          <h3>Assign {goal.name} </h3>
          <p>Please select a learner for this goal and configure any additional settings below.</p>
        </div>

        <GoalForm onSubmit={onSubmit} onCancel={hideModal} dataSet={options}></GoalForm>
      </div>
    )

    setTimeout(() => {
      modalRef.current?.focus()
    }, 0) // Ensures model is focused after it is rendered
  }, [showModal, goal, onSubmit, hideModal, options])

  return (
    <LxTooltip tooltipText={'Add Goal'}>
      <LxClickAbleIcon className={cn(styles.activityAction, styles.createNewGoalAction)} icon={PlusOutlineIcon} onClick={renderModal}  role='button' ariaLabel='Add Goal'/>
    </LxTooltip>
  )
}
