import React, {useState, useEffect} from "react"
import {SearchIcon, PlusIcon, MinusIcon,SettingsIcon} from "@icons/utils"

import styles from "./LearnerTeamMembers.module.scss"
import sharedStyles from "../../../MultiPage.module.scss"
import "@styles/common.scss"
import {LxIcon} from "@components/icon/Icon.tsx"
import {cn} from "@src/utils/cn.ts"
import {useLearnerShow} from "@logic/contexts/Learners/LearnerShowContext.tsx"
import {LxLoadingSpinner} from "@components/loader/loadingSpinner.tsx"
import {LxClickAbleIcon} from "@components/icon/clickAbleIcon.tsx"
import {AddNewLearnerAssistant} from "@src/pages/Learner/Show/TeamMembers/LearnerAddNewAssistant.tsx"
import {UserF} from "@logic/contexts/Users/UsersFrontend.type.ts"
import {ConfirmationModal} from "@src/utils/confirmationModel"
import {isNilOrEmpty} from "@src/utils/isNilOrEmpty.ts"
import {LearnerMemberEditAccess} from "@src/pages/Learner/Show/TeamMembers/LearnerMemberEditAccess.tsx"

import {useDataProvider, useNotify} from "react-admin"
import { CAN_RUN_ALL_ACTIVITIES } from '@logic/contexts/AppStore/UserPermissions.constant.ts'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'

export const UserView = ({currentUserId}) => {
  const {learner, isLoading} = useLearnerShow()
  const learnerId = learner?.id
  const [openAccessModal, setOpenAccessModal] = useState(false)

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { showModal, hideModal } = useModal()

  const [assignedUserData, setAssignedUserData] = useState<User[]>([])
  const [isAssignedUserDataLoading, setIsAssignedUserDataLoading] =
    useState(true)
  const [userData, setUserData] = useState<User[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [filteredAssignedUserData, setFilteredAssignedUserData] = useState()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  const [refreshKey, setRefreshKey] = useState(0)
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const fullName = (user) => {
    return `${user.first_name} ${user.last_name}`
  }

  // get all users and details

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("User", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {list: "all_assistants"},  //From the backend we are filtering only assistants with active status
        })

        const userDataSubset: User[] = []

        response.data.forEach((user: any) => {
          const userDataItem: User = {
            id: user.id,
            fullName: fullName(user)
          }

          userDataSubset.push(userDataItem)
        })

        setUserData(userDataSubset)
        setIsUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsUserDataLoading(false)
      }
    }
    fetchData()
  }, [dataProvider])

  // get assigned users and details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("User", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {list: "learnerUsers", learner_id: learnerId},
        })

        const assignedUsersArray = []

        const userDataMap = userData.reduce((acc, user) => {
          acc[user.id] = {
            fullName: user.fullName,
            userRole: 'Assistant',
          }
          return acc
        }, {})

        Object.entries(response.data || {}).map(([_, userData]) => {
          Object.entries(userData.assigned_users || {}).map(
            ([key, assistantData]) => {
              if (learnerId in assistantData.learners) {
                assignedUsersArray.push({
                  [CAN_RUN_ALL_ACTIVITIES]:
                    assistantData.learners[learnerId].includes(
                      CAN_RUN_ALL_ACTIVITIES
                    ),
                  id: key,
                  fullName: userDataMap[key]?.fullName,
                  userRole: userDataMap[key]?.userRole,
                  supervisorId: currentUserId,
                })
              }
            }
          )
        })

        setAssignedUserData(assignedUsersArray)
        setFilteredAssignedUserData(assignedUsersArray)
        setIsAssignedUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsAssignedUserDataLoading(false)
      }
    }
    if (learnerId) {
      fetchData()
    }
  }, [userData, refreshKey])

  const handleUnAssign = async (userId: string) => {
    setIsConfirmationOpen(true)
    setSelectedUser(userId)
  }

  const handleAccessEdit = async (user) => {
    setSelectedUser(user)
    setOpenAccessModal(true)
  }

  const handleConfirmUnassign = async () => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider
      .update("Learner", {
        id: learnerId,
        data: {
          assign_user: {
            user_id: selectedUser,
            supervisor_id: currentUserId,
            action: "remove_user",
          },
        },
        previousData: null,
      })
      notify("Assistant removed successfully", {type: "success"})
    } catch (error) {
      const errorMessage =
      error.message ||
      "Something went wrong, Please try again after sometime"
      notify(errorMessage, {type: "error"})
    } finally {
      setRefreshKey((prevKey) => prevKey + 1)
      setIsFormSubmitting(false)
      setIsConfirmationOpen(false)
    }
  }

  const handleSearchInputChange = (inputValue) => {
    const filteredUsers = assignedUserData.filter((user) =>
      user.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredAssignedUserData(filteredUsers)
  }

  // getting unassigned users
  const assignedUserIds = new Set(assignedUserData.map(user => user.id));
 

  return (
    <>
      <div className={styles.studentTeamMembersNavigation}>
        {isLoading || isAssignedUserDataLoading || isUserDataLoading ? (
          <LxLoadingSpinner />
        ) : (
          <>
            <div className={cn(styles.membersInfo)}>
              <h3>Your Assistants Assigned to {learner?.fullName} </h3>
            </div>
            <div className={cn(sharedStyles.tableActions)}>
              <div
                className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize')}
                onClick={() => showModal(<AddNewLearnerAssistant
                  onClose={hideModal}
                  users={userData}
                  learnerId={learnerId}
                  superVisorId={currentUserId}
                  learnerFullName={learner?.fullName}
                  setRefreshKey={setRefreshKey}
                  assignedUserIds={assignedUserIds}
                />)}
                role='button'
                aria-label='Assign New Team Member'
              >
                <LxIcon icon={PlusIcon}/>
                Assign New Team Member
              </div>
              <div className={'lxActionButton lxActionButtonDefaultSize'}>
                <LxIcon icon={SearchIcon}/>
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={(e) => {
                    handleSearchInputChange(e.target.value)
                  }}
                  placeholder="Search"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.studentTeamMembersUsersList}>
        <div className={cn(sharedStyles.tableListHeader)}>
      Team Member Name
    </div>
    <div
      className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}
    >
      Access Control
    </div>
    <div
      className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}
    >
      Unassign
    </div>
    <>
      {isAssignedUserDataLoading ? (
        <LxLoadingSpinner className={sharedStyles.loader}/>
      ) : isNilOrEmpty(filteredAssignedUserData) ? (
        <div className={sharedStyles.notFound}>
          No assistant match your current search or filters.
        </div>
      ) : (
        filteredAssignedUserData.map((user: UserF) => (
          <React.Fragment key={`User-row-${user.id}`}>
                <div>{user.fullName}</div>

                <LxClickAbleIcon
                  className={sharedStyles.hide4column}
                  onClick={() => {
                    handleAccessEdit(user)
                  }}
                  icon={SettingsIcon}
                />
                <LxClickAbleIcon
                  className={sharedStyles.hide5column}
                  onClick={() => {
                    handleUnAssign(user.id)
                  }}
                  icon={MinusIcon}
                />
              </React.Fragment>
            ))
          )}
        </>
      </div>

      <LearnerMemberEditAccess
        isModalOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        user={selectedUser}
        learnerId={learnerId}
        learnerFullName={learner?.fullName}
        setRefreshKey={setRefreshKey}
        isFormSubmitting={isFormSubmitting}
        setIsFormSubmitting={setIsFormSubmitting}
      />

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmUnassign}
        message="Are you sure you want to unassign this assistant?"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  )
}
